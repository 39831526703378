import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import actionTypes from "../actionTypes";

const createProviderThunk = (providerId, providerInput) => async dispatch => {
  dispatch({ type: actionTypes.UPDATE_PROVIDER_REQUEST });
  try {
    const provider = await graphqlApi.updateProvider(providerId, providerInput);
    dispatch({
      type: actionTypes.UPDATE_PROVIDER_SUCCESS,
      provider,
    });
    return provider;
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.UPDATE_PROVIDER_FAILURE,
      error: error.message,
    });
    throw error;
  }
};

export default createProviderThunk;
