import { Icon, Tooltip } from "antd";
import * as React from "react";
import "./InfoTooltip.less";

const InfoTooltip = ({ text, ...rest }) => {
  return (
    <Tooltip title={text} placement="right" {...rest}>
      <span className="InfoTooltip">
        <Icon type="info-circle-o" />
      </span>
    </Tooltip>
  );
};

export default InfoTooltip;
