import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import actionTypes from "../actionTypes";

const deleteProvidersThunk = (providerIds: string[]) => async dispatch => {
  dispatch({ type: actionTypes.DELETE_PROVIDERS_REQUEST });
  try {
    const deletedProviderIds = await graphqlApi.deleteProviders(providerIds);
    dispatch({
      type: actionTypes.DELETE_PROVIDERS_SUCCESS,
      deletedProviderIds,
    });
    return deletedProviderIds;
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.DELETE_PROVIDERS_FAILURE,
      error: error.message ? error.message : error,
    });
    throw new Error(`Failed to delete providers: ${error.message}`);
  }
};

export default deleteProvidersThunk;
