import gql from "graphql-tag";
import * as _ from "lodash";
import { IGqlUser } from "../../../types/interfaces/IGqlUser";
import apolloClient from "../../apolloClient";

const GET_USER = gql`
  query User($userId: ID!) {
    user(id: $userId) {
      name
      email
      id
      role
      status
      isTermsOfServiceAccepted
    }
  }
`;

const getUser = async (userId): Promise<IGqlUser> => {
  console.log("GraphQL getUser", userId); // tslint:disable-line no-console

  const ret: any = await apolloClient
    .query({
      query: GET_USER,
      variables: { userId },
      fetchPolicy: "no-cache",
    })
    .catch(error => {
      throw new Error(error.graphQLErrors.join(",") || `Failed to get user ${userId}.`);
    });

  if (!_.isEmpty(ret.errors) || _.isEmpty(_.get(ret, "data.user"))) {
    throw new Error(`Failed to get user ${userId}: ${_.map(ret.errors, "message").join(", ")}`);
  }
  return ret.data.user;
};

export default getUser;
