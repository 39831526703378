import { isApolloError } from "apollo-client/errors/ApolloError";
import { History } from "history";
import * as _ from "lodash";
import * as React from "react";
import { SubmissionError } from "redux-form";
import { ISignInData } from "../../../../types/interfaces/ISignInData";
import { isDataPortalServiceError, isNetworkError } from "../../../../utils/errorUtils";
import { getQueryString } from "../../../../utils/utils";
import SignInPage from "./SignInPage";

export interface IStateProps {
  history: History;
}

export interface IDispatchProps {
  onInit: () => Promise<void>;
  onSubmit: (data: ISignInData) => Promise<void>;
}

interface IProps extends IStateProps, IDispatchProps {}

interface IState {
  isInitializing: boolean;
  initializationError: string | null;
}

export default class SignInPageContainer extends React.Component<IProps, IState> {
  public state: IState = {
    isInitializing: true,
    initializationError: null,
  };

  public componentDidMount() {
    this.initialize();
  }

  public render() {
    const { history } = this.props;
    const redirectTo = getQueryString(history, "redirect-to");
    return (
      <SignInPage
        isInitializing={this.state.isInitializing}
        initializationError={this.state.initializationError}
        onSubmit={async (values: ISignInData) => {
          try {
            await this.props.onSubmit(values);
            if (redirectTo) {
              _.startsWith(redirectTo, "/")
                ? history.push(redirectTo)
                : (window.location.href = redirectTo);
            } else {
              history.push("/datasets");
            }
          } catch (error) {
            if (isNetworkError(error) || isDataPortalServiceError(error)) {
              history.push("/under_maintenance");
            }

            if (error.code === "UserNotConfirmedException") {
              history.push(`/sign_up/verify?user=${encodeURIComponent(values.email)}`);
            }

            if (isApolloError(error)) {
              throw new SubmissionError({
                _error: "Failed to sign in. Please try again.",
              });
            }

            throw new SubmissionError({
              _error: "Failed to sign in. Incorrect username or password.",
            });
          }
        }}
      />
    );
  }

  private initialize = async () => {
    this.setState({ isInitializing: true });
    try {
      await this.props.onInit();
    } catch (error) {
      this.setState({ initializationError: error.message });
    }
    this.setState({ isInitializing: false });
  };
}
