import { selectors } from "../../reducers/root";
import { IEmployerInput } from "../../types/interfaces/IEmployerInput";
import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import actionTypes from "../actionTypes";

const updateEmployerThunk = (employerInput: IEmployerInput) => async (dispatch, getState) => {
  const state = getState();
  const employerId = selectors.getEmployerId(state);
  if (employerId === null) {
    throw new Error("Cannot update employer, missing employer ID");
  }
  dispatch({ type: actionTypes.UPDATE_EMPLOYER_REQUEST });
  try {
    const { employer: updated } = await graphqlApi.updateEmployer(employerId, employerInput);
    dispatch({
      type: actionTypes.UPDATE_EMPLOYER_SUCCESS,
    });
    return updated;
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.UPDATE_EMPLOYER_FAILURE,
      error: error.message,
    });
    throw error;
  }
};

export default updateEmployerThunk;
