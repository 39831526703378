import { connect } from "react-redux";
import { initialize, submit } from "redux-form";
import actionCreators from "../../../../actions/actionCreators";
import { IState as IStoreState, selectors } from "../../../../reducers/root";
import { form } from "./ContactInfoEditModal";
import ContactInfoEditModalContainer, {
  IDispatchProps,
  IStateProps,
} from "./ContactInfoEditModalContainer";

interface IOwnProps {
  isVisible: boolean;
  onDone: () => void;
}

const mapStateToProps = (state: IStoreState, ownProps: IOwnProps) => {
  const { isVisible } = ownProps;
  const user = selectors.getUser(state);
  return {
    user,
    isVisible,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: IOwnProps) => {
  return {
    onInit: async () => {
      const user = await dispatch(actionCreators.fetchUser());
      const values = { name: user.name };
      await dispatch(initialize(form, values));
    },
    onClickSubmitButton: async () => {
      dispatch(submit(form));
    },
    onSubmit: async (userName: string) => {
      await dispatch(actionCreators.updateUser(userName));
      ownProps.onDone();
    },
    onCancel: () => {
      ownProps.onDone();
    },
  };
};

const ConnectedContactInfoEditModal = connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(
  mapStateToProps,
  mapDispatchToProps,
)(ContactInfoEditModalContainer);

export default ConnectedContactInfoEditModal;
