import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import actionCreators from "../../../../../../actions/actionCreators";
import { IState as IStoreState } from "../../../../../../reducers/root";
import VerifyStepContainer, { IDispatchProps, IStateProps } from "./VerifyStepContainer";

interface IOwnProps extends RouteComponentProps<any> {}

const ConnectedVerifyStep = connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(
  (state: IStoreState, ownProps: IOwnProps) => ({
    history: ownProps.history,
  }),
  (dispatch: any) => ({
    onSubmit: async (username: string, verificationCode: string) => {
      await dispatch(actionCreators.confirmSignUp(username, verificationCode));
    },
    onResendCode: async (username: string) => {
      await dispatch(actionCreators.resendVerificationCode(username));
    },
  }),
)(VerifyStepContainer);

export default withRouter(ConnectedVerifyStep);
