import * as React from "react";
import { IDatasetsLoadingParams } from "../../../../../types/interfaces/IDatasetsLoadingParams";
import { IGqlDatasetRow } from "../../../../../types/interfaces/IGqlDatasetRow";

export interface IStateProps {
  datasets: IGqlDatasetRow[];
  datasetsTargetVersion: number;
  datasetsLoadingVersion: number | null;
  datasetsCurrentVersion: number;
  isDatasetsLoading: boolean;
  datasetsLoadingError: string | null;
  queryParams: IDatasetsLoadingParams;
}

export interface IDispatchProps {
  onRequestLoadDatasets: (params: IDatasetsLoadingParams) => Promise<void>;
}

interface IChildrenProps {
  children: (
    args: {
      datasets: IGqlDatasetRow[];
      isDatasetsLoading: boolean;
      datasetsLoadingError: string | null;
      onRequestLoadDatasets: (params: IDatasetsLoadingParams) => Promise<void>;
    },
  ) => JSX.Element;
}

interface IProps extends IStateProps, IDispatchProps, IChildrenProps {}

class DatasetsProvider extends React.Component<IProps, {}> {
  public componentDidMount() {
    this.props.onRequestLoadDatasets(this.props.queryParams);
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<{}>,
    snapshot?: any,
  ): void {
    const {
      datasetsTargetVersion: targetVersion,
      datasetsLoadingVersion: loadingVersion,
      datasetsCurrentVersion: currentVersion,
      queryParams,
    } = this.props;
    const isReloadNeeded = loadingVersion
      ? targetVersion !== loadingVersion
      : targetVersion !== currentVersion;
    if (isReloadNeeded || queryParams !== prevProps.queryParams) {
      this.props.onRequestLoadDatasets(queryParams);
    }
  }

  public render() {
    const { datasets, isDatasetsLoading, datasetsLoadingError, onRequestLoadDatasets } = this.props;
    return this.props.children({
      datasets,
      isDatasetsLoading,
      datasetsLoadingError,
      onRequestLoadDatasets,
    });
  }
}

export default DatasetsProvider;
