import gql from "graphql-tag";
import * as _ from "lodash";
import { IGqlProvider } from "../../../types/interfaces/IGqlProvider";
import { IProviderInput } from "../../../types/interfaces/IProviderInput";
import apolloClient from "../../apolloClient";

const CREATE_PROVIDER = gql`
  mutation CreateProvider(
    $name: String!
    $website: String!
    $address: String!
    $contactName: String!
    $contactEmail: String!
    $contactPhone: String!
    $description: String!
  ) {
    createProvider(
      input: {
        name: $name
        website: $website
        address: $address
        contactName: $contactName
        contactEmail: $contactEmail
        contactPhone: $contactPhone
        description: $description
      }
    ) {
      id
      name
      website
      address
      contactName
      contactEmail
      contactPhone
      description
    }
  }
`;

const createProvider = async (providerInput: IProviderInput): Promise<IGqlProvider> => {
  const variables = {
    name: providerInput.name,
    website: providerInput.website,
    address: providerInput.address,
    contactName: providerInput.contactName,
    contactEmail: providerInput.contactEmail,
    contactPhone: providerInput.contactPhone,
    description: providerInput.description,
  };

  const ret: any = await apolloClient
    .mutate({
      mutation: CREATE_PROVIDER,
      variables,
      fetchPolicy: "no-cache",
    })
    .catch(error => {
      throw new Error(error.graphQLErrors.join(",") || `Failed to get providers.`);
    });

  if (!_.isEmpty(ret.errors) || _.isEmpty(_.get(ret, "data.createProvider"))) {
    throw new Error(`Failed to create provider: ${_.map(ret.errors, "message").join(", ")}`);
  }

  return ret.data.createProvider;
};

export default createProvider;
