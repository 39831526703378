import { Alert, Button, Checkbox, Modal, Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { spinnerDelay } from "../../../../../../../constants/constants";
import "../../../../../../../styles/alert-modal.less";
import { IGqlProviderWithDatasets } from "../../../../../../../types/interfaces/IGqlProviderWithDatasets";
import graphqlApi from "../../../../../../../utils/graphqlApi/graphqlApi";
import "./ProviderDeleteConfirmModal.less";

interface IProps {
  providerId: string;
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

interface IState {
  isInitializing: boolean;
  initializationError: string | null;
  provider: IGqlProviderWithDatasets | null;
  isAllDatasetsChecked: boolean;
  isProcessing: boolean;
  processingError: string | null;
}

class ProviderDeleteConfirmModal extends React.Component<IProps, IState> {
  public state: IState = {
    isInitializing: false,
    initializationError: null,
    provider: null,
    isAllDatasetsChecked: false,
    isProcessing: false,
    processingError: null,
  };

  public componentDidMount() {
    this.reset(this.props.providerId);
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    if (this.props.providerId !== prevProps.providerId) {
      this.reset(this.props.providerId).then(_.noop);
    }
  }

  public render() {
    const { isVisible, onCancel, onConfirm } = this.props;
    const {
      isInitializing,
      initializationError,
      provider,
      isProcessing,
      processingError,
    } = this.state;
    return (
      <Modal
        width={710}
        className="ProviderDeleteConfirmModal alert-modal"
        title="This provider is associated with multiple datasets"
        visible={isVisible}
        onOk={onConfirm}
        onCancel={onCancel}
        footer={[
          <Button
            key="submit"
            className="primary-button"
            type="primary"
            loading={isProcessing}
            onClick={onConfirm}
            disabled={!this.state.isAllDatasetsChecked || isProcessing}
          >
            Delete provider
          </Button>,
          <Button
            key="cancel"
            className="secondary-button"
            onClick={onCancel}
            disabled={isProcessing}
          >
            Cancel
          </Button>,
        ]}
      >
        {initializationError && (
          <Alert
            className="initialization-error-banner"
            message={initializationError}
            type="error"
          />
        )}
        <Spin
          spinning={isInitializing || !_.isEmpty(initializationError)}
          indicator={initializationError ? <div /> : undefined}
          tip={initializationError ? "" : "Initializing..."}
          delay={spinnerDelay}
        >
          {provider && (
            <div className="ProviderDeleteConfirmModal-modal-content">
              <div className="ProviderDeleteConfirmModal-modal-content-description">
                This provider "{provider.name}" is associated with multiple datasets.{" "}
                <b>Check to acknowledge that the following datasets will also be removed:</b>
              </div>
              <Checkbox.Group
                onChange={checkedValues => {
                  if (checkedValues.length === provider.datasets.length) {
                    this.setState({ isAllDatasetsChecked: true });
                  } else {
                    this.setState({ isAllDatasetsChecked: false });
                  }
                }}
              >
                {_.map(provider.datasets, dataset => (
                  <div>
                    <Checkbox key={dataset.id} value={dataset.id}>
                      {dataset.name}
                    </Checkbox>
                  </div>
                ))}
              </Checkbox.Group>
              <div className="ProviderDeleteConfirmModal-modal-content-description">
                This action cannot be undone.
              </div>
              {processingError && (
                <Alert type="error" className="processing-error-banner" message={processingError} />
              )}
            </div>
          )}
        </Spin>
      </Modal>
    );
  }

  private reset = async (providerId: string | null) => {
    try {
      this.setState({ isInitializing: true });
      if (providerId) {
        const provider = await graphqlApi.getProvider(providerId);
        this.setState({ provider });
      } else {
        this.setState({ provider: null });
      }
    } catch (error) {
      this.setState({ initializationError: error.message });
    } finally {
      this.setState({ isInitializing: false });
    }
  };
}

export default ProviderDeleteConfirmModal;
