import gql from "graphql-tag";
import * as _ from "lodash";
import { IGqlProviderWithDatasets } from "../../../types/interfaces/IGqlProviderWithDatasets";
import apolloClient from "../../apolloClient";

const GET_PROVIDER = gql`
  query Provider($providerId: ID!) {
    provider(id: $providerId) {
      id
      name
      website
      address
      contactName
      contactEmail
      contactPhone
      description
      datasets {
        id
        name
        decision {
          id
          sanitizedStatus
        }
      }
    }
  }
`;

const getProvider = async (providerId: string): Promise<IGqlProviderWithDatasets> => {
  const variables = { providerId };
  console.log("GraphQL getProvider", variables); // tslint:disable-line no-console

  const ret: any = await apolloClient
    .query({
      query: GET_PROVIDER,
      variables,
      fetchPolicy: "no-cache",
    })
    .catch(error => {
      throw new Error(error.graphQLErrors.join(",") || `Failed to get provider.`);
    });
  if (!_.isEmpty(ret.errors) || _.isEmpty(_.get(ret, "data.provider"))) {
    throw new Error(`Failed to get provider: ${_.map(ret.errors, "message").join(", ")}`);
  }
  return ret.data.provider;
};

export default getProvider;
