export interface IGqlUser {
  id: string;
  name: string;
  email: string;
  user: string;
  role: IGqlUserRole;
  status: string;
  isTermsOfServiceAccepted: boolean;
}

export enum IGqlUserRole {
  acAdmin = "ALPHA_CAPTURE_ADMIN",
  ddAdmin = "DATA_DISCOVERY_ADMIN",
  dataScout = "DATA_SCOUT",
  dataVendor = "DATA_VENDOR",
  tsEmployee = "TWO_SIGMA_EMPLOYEE",
}
