import Auth from "@aws-amplify/auth";
import { message, Spin } from "antd";
import { History } from "history";
import * as _ from "lodash";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { SubmissionError } from "redux-form";
import { spinnerDelay } from "../../../../../../constants/constants";
import sessionStore from "../../../../../../utils/sessionStore";
import { getQueryString } from "../../../../../../utils/utils";
import VerifyStep from "./VerifyStep";

export interface IStateProps {
  history: History;
}

export interface IDispatchProps {
  onSubmit: (username: string, verificationCode: string) => Promise<void>;
  onResendCode: (username: string) => Promise<void>;
}

interface IProps extends IStateProps, IDispatchProps {}

interface IState {
  isInitializing: boolean;
  shouldRedirectToHomePage: boolean;
  isResendingVerificationCode: boolean;
  resendingVerificationCodeError: string | null;
}

export const initialValues = {
  code: "",
};

export default class VerifyStepContainer extends React.Component<IProps, IState> {
  public state: IState = {
    isInitializing: false,
    shouldRedirectToHomePage: false,
    isResendingVerificationCode: false,
    resendingVerificationCodeError: null,
  };

  public componentDidMount() {
    this.initialize();
  }

  public render() {
    const { history } = this.props;
    if (this.state.shouldRedirectToHomePage) {
      return <Redirect to={{ pathname: "/" }} />;
    }
    const username = getQueryString(history, "user");
    if (username === null) {
      return <Redirect to={{ pathname: "/sign_up" }} />;
    }
    return (
      <Spin spinning={this.state.isInitializing} tip="Initializing..." delay={spinnerDelay}>
        <VerifyStep
          isResendingVerificationCode={this.state.isResendingVerificationCode}
          resendingVerificationCodeError={this.state.resendingVerificationCodeError}
          onSubmit={async code => {
            try {
              await this.props.onSubmit(username, code);
              message.success("Account confirmed");
              history.push(`/datasets`);
            } catch (error) {
              throw new SubmissionError({
                code: error.code === "CodeMismatchException" ? error.code : undefined,
                _error: `Failed to verify. ${error.message}`,
              });
            }
          }}
          onResendCode={async () => {
            this.setState({ isResendingVerificationCode: true });
            try {
              await this.props.onResendCode(username);
              message.info("Verification code email was resent");
            } catch (error) {
              this.setState({ resendingVerificationCodeError: error.message });
            }
            this.setState({ isResendingVerificationCode: false });
          }}
        />
      </Spin>
    );
  }

  private initialize = async () => {
    this.setState({ isInitializing: true });

    // If the user is already signed in, we want to redirect them to the home page
    // this is because, the link to the verify page is included in the verification code email
    // that users get when they sign up, can they might continue to use the link to come back to
    // the data portal. See AC-306
    if (await sessionStore.hasSession()) {
      this.setState({ shouldRedirectToHomePage: true });
    } else {
      // check if the user is already verified(CONFIRMED)
      const username = getQueryString(this.props.history, "user");
      if (username) {
        try {
          await Auth.confirmSignUp(_.trim(username.toLowerCase()), "ALTOIDS");
        } catch (error) {
          // if the user is already verified(CONFIRMED), should also redirect to home page
          if (error.message === "User cannot be confirm. Current status is CONFIRMED") {
            console.info(`User ${username} is already verified.`); // tslint:disable-line no-console
            this.setState({ shouldRedirectToHomePage: true });
          }
          // note we don't rethrow and silently drop the error as this check should not block the
          // main workflow of the page.
        }
      }
    }
    this.setState({ isInitializing: false });
  };
}
