import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import actionTypes from "../actionTypes";

const deleteProviderAndDatasetsThunk = (providerId: string) => async dispatch => {
  dispatch({ type: actionTypes.DELETE_PROVIDER_AND_ALL_ITS_DATASETS_REQUEST, providerId });
  try {
    const deletedProviderId = await graphqlApi.deleteProviderAndDatasets(providerId);
    dispatch({
      type: actionTypes.DELETE_PROVIDER_AND_ALL_ITS_DATASETS_SUCCESS,
      deletedProviderId,
    });
    return deletedProviderId;
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.DELETE_PROVIDER_AND_ALL_ITS_DATASETS_FAILURE,
      providerId,
      error: error.message ? error.message : error,
    });
    throw new Error(`Failed to delete providers: ${error.message}`);
  }
};

export default deleteProviderAndDatasetsThunk;
