import * as React from "react";
import { IEmployerInput } from "../../../../types/interfaces/IEmployerInput";
import EmployerInfoEditModal from "./EmployerInfoEditModal";

export interface IStateProps {
  employer: any | null;
  isVisible: boolean;
}

export interface IDispatchProps {
  onCancel: () => void;
  onInit: () => Promise<void>;
  onClickSubmitButton: () => Promise<void>;
  onSubmit: (employerInput: IEmployerInput) => Promise<void>;
}

interface IProps extends IStateProps, IDispatchProps {}

interface IState {
  isInitializing: boolean;
  initializationError: string | null;
  isSaving: boolean;
}

interface IFormData {
  name: string;
  website: string | null;
  address: string | null;
}

export const initialValues: IFormData = {
  name: "",
  website: "",
  address: "",
};

export default class EmployerInfoEditModalContainer extends React.Component<IProps, IState> {
  public state: IState = {
    isInitializing: true,
    initializationError: null,
    isSaving: false,
  };

  public componentDidMount() {
    this.initialize();
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    if (!this.props.isVisible && prevProps.isVisible) {
      this.initialize();
    }
  }

  public render() {
    return (
      <EmployerInfoEditModal
        employer={this.props.employer}
        isVisible={this.props.isVisible}
        onCancel={this.props.onCancel}
        onClickSubmitButton={this.props.onClickSubmitButton}
        onSubmit={this.props.onSubmit}
        isInitializing={this.state.isInitializing}
        initializationError={this.state.initializationError}
        isSaving={this.state.isSaving}
      />
    );
  }

  private initialize = async () => {
    this.setState({ isInitializing: true });
    try {
      await this.props.onInit();
    } catch (error) {
      this.setState({ initializationError: error.message });
    }
    this.setState({ isInitializing: false });
  };
}
