import * as H from "history";
import * as React from "react";
import { Redirect } from "react-router";
import { SubmissionError } from "redux-form";
import { getQueryString } from "../../../../../../utils/utils";
import ResetPasswordStep from "./ResetPasswordStep";

export interface IStateProps {
  history: H.History;
}

export interface IDispatchProps {
  onSubmit: (username: string, code: string, newPassword: string) => Promise<void>;
}

interface IProps extends IStateProps, IDispatchProps {}

interface IState {
  isProcessing: boolean;
}

export default class ResetPasswordStepContainer extends React.Component<IProps, IState> {
  public state: IState = {
    isProcessing: false,
  };
  public render() {
    const { history } = this.props;
    const username = getQueryString(history, "user");
    if (username === null) {
      return <Redirect to={{ pathname: "/forgot_password" }} />;
    }
    return (
      <ResetPasswordStep
        isSubmitting={this.state.isProcessing}
        onSubmit={async (code: string, newPassword: string) => {
          this.setState({ isProcessing: true });
          try {
            await this.props.onSubmit(username, code, newPassword);
            this.setState({ isProcessing: false }, () => {
              history.push("/sign_in");
            });
          } catch (error) {
            this.setState({ isProcessing: false });
            throw new SubmissionError({
              _error: `Failed to reset password. ${error.message}`,
              code:
                error.code === "CodeMismatchException" ? "Invalid verification code" : undefined,
            });
          }
        }}
      />
    );
  }
}
