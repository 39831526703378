import { ApolloClient, HttpLink, InMemoryCache } from "apollo-boost";
import { setContext } from "apollo-link-context";
import * as _ from "lodash";
import { GRAPHQL_ENDPOINT, SERVICE_BASE_URL } from "./config";
import sessionStore from "./sessionStore";

const authLink = setContext(async (x, { headers }) => {
  const idToken = await sessionStore.getIdToken();
  return {
    headers: {
      ...headers,
      authorization: !_.isEmpty(idToken) ? `Bearer ${idToken}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(
    new HttpLink({
      uri: `${SERVICE_BASE_URL}${GRAPHQL_ENDPOINT}`,
    }),
  ),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

export default client;
