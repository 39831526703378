import { Modal } from "antd";
import * as _ from "lodash";
import * as React from "react";

import "../../../../styles/edit-modal.less";
import TermsOfServiceBody from "../TermsOfServiceBody/TermsOfServiceBody";
import TermsOfServiceProvider from "../TermsOfServiceProvider/TermsOfServiceProvider";
import "./TermsOfServiceModal.less";

const TermsOfServiceModal = ({
  isVisible,
  onAccept,
}: {
  isVisible: boolean;
  onAccept: (version: number) => Promise<void>;
}) => (
  <Modal
    width={710}
    className="TermsOfServiceModal"
    title="Data Portal Terms of Service"
    visible={isVisible}
    closable={false}
    onCancel={_.noop}
    footer={[]}
  >
    <div className="modal-body">
      <TermsOfServiceProvider>
        {props => <TermsOfServiceBody isVisible={isVisible} onAccept={onAccept} {...props} />}
      </TermsOfServiceProvider>
    </div>
  </Modal>
);

export default TermsOfServiceModal;
