import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import actionCreators from "../../../../actions/actionCreators";
import { IState as IStoreState } from "../../../../reducers/root";
import { IDatasetInput } from "../../../../types/interfaces/IDatasetInput";
import DatasetAddPageContainer, { IDispatchProps, IStateProps } from "./DatasetAddPageContainer";

interface IOwnProps extends RouteComponentProps<any> {}

const ConnectedDatasetAddPage = connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(
  (state: IStoreState, ownProps: IOwnProps) => ({
    history: ownProps.history,
  }),
  (dispatch: any) => ({
    onSubmit: async (datasetInput: IDatasetInput) => {
      await dispatch(actionCreators.createDataset(datasetInput));
    },
  }),
)(DatasetAddPageContainer);

export default withRouter(ConnectedDatasetAddPage);
