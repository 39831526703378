import { connect } from "react-redux";
import actionCreators from "../../actions/actionCreators";
import App from "./App";

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  onInit: () => {
    return dispatch(actionCreators.initialize());
  },
});

const ConnectedApp = connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

export default ConnectedApp;
