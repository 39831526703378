import gql from "graphql-tag";
import * as _ from "lodash";
import apolloClient from "../../apolloClient";
import { isNetworkError } from "../../errorUtils";

const GET_USER_STATUS = gql`
  query User($userId: ID!) {
    user(id: $userId) {
      id
      email
      status
      isTermsOfServiceAccepted
    }
  }
`;
const getUserStatus = async (userId): Promise<any> => {
  const ret: any = await apolloClient
    .query({
      query: GET_USER_STATUS,
      variables: { userId },
    })
    .catch(error => {
      if (isNetworkError(error)) {
        throw error;
      }
      throw new Error(error.graphQLErrors.join(",") || `Failed to get user(id=${userId}) status.`);
    });

  if (!_.isEmpty(ret.errors)) {
    if (_.some(ret.errors, error => _.get(error, "extensions.code") === 503)) {
      throw new Error("Data Portal Service not available");
    }
    throw new Error(
      `Failed to get user(id=${userId}) status: ${_.map(ret.errors, "message").join(", ")}`,
    );
  }

  return ret.data.user;
};

export default getUserStatus;
