import * as React from "react";
import "../../../../styles/cookie-policy.less";

const CookiePolicyPage = () => {
  return (
    <div style={{ width: "1074px", margin: "0 auto" }} className="cookie-policy">
      <p>Effective Date: September 25th, 2018</p>

      <div className="title page-title">
        THIS COOKIE POLICY APPLIES TO THE DATA PORTAL PLATFORM.
      </div>
      <div className="title">Cookies</div>
      <p className="content">
        This Policy describes how and why Two Sigma Investments, LP (“we”, “us” or “our”) uses
        cookies on {window.location.host} (our “Site”). To make our Site work properly, we sometimes
        place small data files (called cookies) on your computer or mobile device.
      </p>

      <div className="title">What are cookies?</div>
      <p className="content">
        A cookie is a small text file that a website saves on your computer or mobile device when
        you visit a website. It enables the website to remember your actions and preferences (such
        as login, language, font size and other display preferences) over a period of time, so you
        don’t have to keep re-entering them whenever you come back to the website or browse from one
        page to another. To learn more about cookies, please visit aboutcookies.org.
      </p>

      <div className="title">What are cookies used for?</div>
      <p className="content">
        We use cookies on our Site to personalize your experience on our Site and to make your
        future activities and experience on our Site more efficient. We also use cookies to track,
        compile and analyze usage, navigational and other statistical information that allow us to
        understand how people use our Site, and to help us improve the structure and content of our
        Site.
      </p>

      <div className="title">What types of cookies do we use?</div>
      <p className="content">
        We use two types of cookies on our Site: session cookies and persistent cookies. A session
        cookie is a temporary cookie that remains on your device until you leave our Site. Session
        cookies are essential to make our Site work correctly, as they enable you to move around our
        Site and use our features. A persistent cookie remains on your device for much longer, or
        until you manually delete it (how long the cookie remains on your device will depend on the
        duration or "lifetime" of the specific cookie and your browser settings). Persistent cookies
        help us recognize you as a return user of our Site so it’s easier and convenient to return
        to and interact with our Site. In addition, persistent cookies also help us recognize and
        record information about your web browsing habits during the lifetime of the persistent
        cookie.
      </p>

      <p className="content">
        We use different types of analytical cookies that allow us to provide you with more
        efficient and contextual support and that also provide us with data around your usage of the
        Site that we may use to improve the Site but also to benefit our business and the businesses
        of our affiliates, including with respect to business purposes unrelated to the Site.
      </p>

      <div className="title">What third-party cookies do we use?</div>
      <p className="content">
        We may also work with third-party service providers who may set cookies on our Site. These
        third-party service providers help us track and analyze your use of our Site and to optimize
        our Site and improve your experience. These third-party service providers are responsible
        for the cookies they set on our Site. We do not control the dissemination of these cookies.
        We are not responsible for these third party sites or the content of such third party sites.
        Once you have left our Site, we cannot be responsible for the protection and privacy of any
        information of which you provide. If you want further information please go to the website
        for the relevant third-party service provider.
      </p>

      <div className="title">How to control cookies?</div>
      <p className="content">
        If you continue to use our Site without changing your settings, we will assume that you are
        happy to receive all cookies on our Site. You can control and/or delete cookies as you wish.
        For details see aboutcookies.org.
      </p>
      <p className="content">
        You can delete all cookies that are already on your computer and you can set most browsers
        to prevent them from being placed. If you do this, however, you may have to manually adjust
        some preferences every time you visit our Site and functionalities may not work.
      </p>
      <div className="title">Contact us</div>
      <p className="content">
        If you have any concerns about the way we use cookies, or if you have any questions, please
        email us at legal@twosigma.com.
      </p>
    </div>
  );
};

export default CookiePolicyPage;
