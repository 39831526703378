import gql from "graphql-tag";
import * as _ from "lodash";

import apolloClient from "../../apolloClient";

const DELETE_PROVIDERS = gql`
  mutation deleteProviders($ids: [ID]!) {
    deleteProviders(ids: $ids)
  }
`;

const deleteProviders = async (ids: string[]): Promise<{ ids: string[] }> => {
  const variables = {
    ids,
  };

  const ret = await apolloClient.mutate({
    mutation: DELETE_PROVIDERS,
    variables,
  });

  if (ret.errors !== undefined && !_.isEmpty(ret.errors)) {
    throw new Error(`${_.map(ret.errors, "message").join("; ")}`);
  }

  if (ret.data === undefined) {
    throw new Error("Failed to delete providers.");
  }

  return ret.data.deleteProviders;
};

export default deleteProviders;
