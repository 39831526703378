import { Alert, Button, Col, Form, Row, Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Field, reduxForm } from "redux-form";
import validatePassword from "../../../../../../../utils/validatePassword";
import ErrorBoundary from "../../../../../common/ErrorBoundary/ErrorBoundary";
import FormInput from "../../../../../common/form/FormInput/FormInput";
import FormPassword from "../../../../../common/form/FormPassword/FormPassword";

interface IProps {
  handleSubmit: any;
  error: any;
  submitting: boolean;
}

class SignUpForm extends React.Component<IProps, {}> {
  public render() {
    const { handleSubmit, error, submitting } = this.props;
    return (
      <div className="SignUpForm">
        <ErrorBoundary messagePrefix="Page Error: ">
          <Form onSubmit={handleSubmit}>
            <Spin spinning={submitting} tip={"Saving..."}>
              <Row className="form-item-row">
                <Col lg={24} md={24} sm={24}>
                  <Field
                    label={"Name"}
                    name="name"
                    component={FormInput}
                    required={true}
                    placeholder=""
                  />
                </Col>
              </Row>
              <Row className="form-item-row">
                <Col lg={24} md={24} sm={24}>
                  <Field
                    label={"Email"}
                    name="email"
                    component={FormInput}
                    required={true}
                    placeholder=""
                  />
                </Col>
              </Row>
              <Row className="form-item-row">
                <Col lg={24} md={24} sm={24}>
                  <Field
                    label={"Password"}
                    name="password"
                    component={FormPassword}
                    required={true}
                    placeholder=""
                  />
                </Col>
              </Row>
            </Spin>
            {error && <Alert className="submission-error-banner" message={error} type="error" />}
            <Button
              className="primary-button"
              htmlType="submit"
              type="primary"
              disabled={submitting}
              loading={submitting}
            >
              Submit
            </Button>
          </Form>
        </ErrorBoundary>
      </div>
    );
  }
}

const validate = values => {
  const errors = {} as any;
  if (_.isEmpty(_.trim(values.name))) {
    errors.name = "Name is required";
  }
  if (_.isEmpty(_.trim(values.email))) {
    errors.email = "Email is required";
  }
  if (!_.isEmpty(validatePassword(values.password))) {
    errors.password = "Password is not valid";
  }
  return errors;
};

export const formName = "SignUpForm";

export default reduxForm({
  form: formName,
  validate,
})(SignUpForm);
