import { Layout } from "antd";
import * as React from "react";
import { Route } from "react-router";
import "../../../../styles/sign-in-page.less";
import LegalFooter from "../../common/LegalFooter/LegalFooter";
import ResetPasswordStep from "./steps/ResetPasswordStep/ConnectedResetPasswordStep";
import SubmitEmailStep from "./steps/SubmitEmailStep/ConnectedSubmitEmailStep";

const { Footer, Content } = Layout;

const ForgotPasswordPage = ({ match }) => {
  return (
    <main className="ForgotPasswordPage sign-in-page">
      <Layout>
        <Content>
          <Route exact path={match.url} component={SubmitEmailStep} />
          <Route exact path={match.url + "/verify"} component={ResetPasswordStep} />
        </Content>
        <Footer style={{ padding: 0 }}>
          <LegalFooter />
        </Footer>
      </Layout>
    </main>
  );
};

export default ForgotPasswordPage;
