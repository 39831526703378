import { Form } from "antd";
import * as React from "react";
import ReactJson from "react-json-view";
import FilesUpload from "./FilesUpload/FilesUpload";

const FormFilesUpload = ({
  input,
  meta,
  children,
  hasFeedback,
  label,
  required,
  isDebugging,
  layout,
  description,
  ...rest
}) => {
  const hasError = meta.touched && meta.invalid;
  return (
    <Form.Item label={label} hasFeedback={hasFeedback && hasError} {...layout}>
      {description && <div className="form-field-description">{description}</div>}
      <FilesUpload {...input} {...rest} children={children} meta={meta} />
      {isDebugging && <ReactJson name="meta" src={meta} />}
    </Form.Item>
  );
};

export default FormFilesUpload;
