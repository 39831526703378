import { Alert, Button, Col, Form, Row, Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Field, reduxForm } from "redux-form";
import FormInput from "../../../../../common/form/FormInput/FormInput";

interface IProps {
  handleSubmit: any;
  error: any;
  submitting: boolean;
  invalid: boolean;
}

class VerificationForm extends React.Component<IProps, {}> {
  public render() {
    const { handleSubmit, error, submitting, invalid } = this.props;
    return (
      <div className="VerificationForm">
        <Form onSubmit={handleSubmit}>
          <Spin spinning={submitting} tip={"Saving..."}>
            <Row className="form-item-row">
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={"Code"}
                  name="code"
                  component={FormInput}
                  required={true}
                  placeholder=""
                />
              </Col>
            </Row>
          </Spin>
          {error && <Alert className="submission-error-banner" message={error} type="error" />}
          <Button
            className="primary-button"
            htmlType="submit"
            type="primary"
            disabled={submitting || invalid}
            loading={submitting}
          >
            Confirm account
          </Button>
        </Form>
      </div>
    );
  }
}

const validate = values => {
  const errors = {} as any;
  if (_.isEmpty(values.code)) {
    errors.code = "Code cannot be empty";
  }
  return errors;
};

export const formName = "VerificationForm";

export default reduxForm({
  form: formName,
  validate,
})(VerificationForm);
