import * as H from "history";
import * as React from "react";
import { SubmissionError } from "redux-form";
import SubmitEmailStep from "./SubmitEmailStep";

export interface IStateProps {
  history: H.History;
}

export interface IDispatchProps {
  onSubmit: (email: string) => Promise<void>;
}

interface IProps extends IStateProps, IDispatchProps {}

interface IState {
  isProcessing: boolean;
}

class SubmitEmailStepContainer extends React.Component<IProps, IState> {
  public state: IState = {
    isProcessing: false,
  };

  public render() {
    const { history } = this.props;
    return (
      <SubmitEmailStep
        isSubmitting={this.state.isProcessing}
        onSubmit={async email => {
          this.setState({ isProcessing: true });
          try {
            await this.props.onSubmit(email);
            this.setState({ isProcessing: false });
            history.push(`/forgot_password/verify?user=${encodeURIComponent(email)}`);
          } catch (error) {
            this.setState({ isProcessing: false });
            throw new SubmissionError({
              _error: `${error.message}`,
            });
          }
        }}
      />
    );
  }
}

export default SubmitEmailStepContainer;
