import { message } from "antd";
import * as H from "history";
import * as React from "react";
import { SubmissionError } from "redux-form";
import { IDatasetInput } from "../../../../types/interfaces/IDatasetInput";
import DatasetAddPage from "./DatasetAddPage";

export interface IStateProps {
  history: H.History;
}

export interface IDispatchProps {
  onSubmit: (datasetInput: IDatasetInput) => Promise<void>;
}

interface IProps extends IStateProps, IDispatchProps {}

const DatasetAddPageContainer = ({ history, onSubmit }: IProps) => (
  <DatasetAddPage
    onCancel={() => {
      history.push(`/datasets`);
    }}
    onSubmit={async (data: IDatasetInput) => {
      try {
        await onSubmit(data);
        message.success("Dataset saved.");
        history.push(`/datasets`);
      } catch (error) {
        throw new SubmissionError({
          email: error.code === "UsernameExistsException" ? error.message : undefined,
          _error: `Failed to submit dataset. ${error.message}`,
        });
      }
    }}
  />
);

export default DatasetAddPageContainer;
