import { TreeSelect } from "antd";
import * as _ from "lodash";
import * as React from "react";
import makeFormField from "../makeFormField";

const MyTreeSelect = ({ onChange, labelMap, onBlur, ...rest }) => {
  const overrides = {
    onBlur: event => {
      if (event.target.getAttribute("type") === "text") {
        onBlur();
      }
    },
  };
  return (
    <TreeSelect
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      treeCheckable
      allowClear
      multiple
      {...rest}
      {...overrides}
      onChange={(selectedKeys: string[]) => {
        onChange(_.filter(selectedKeys, key => labelMap.has(key)));
      }}
      filterTreeNode={(text, node) => {
        if (node.props.value.search(_.toLower(text)) !== -1) {
          return true;
        }
        const label = labelMap.get(node.key);
        if (_.toLower(label).search(_.toLower(text)) !== -1) {
          return true;
        }
        return false;
      }}
    />
  );
};

const FormTreeSelect = makeFormField(MyTreeSelect);

export default FormTreeSelect;

function addToMap(map, data) {
  map.set(data.id, data.label);
  if (!_.isEmpty(data.children)) {
    data.children.map(child => addToMap(map, child));
  }
}

export const generateLabelMap = (treeData): Map<string, string> => {
  const map = new Map();
  treeData.forEach(data => addToMap(map, data));
  return map;
};
