import * as _ from "lodash";

const AWS_REGION = _.get(process.env, "REACT_APP_AWS_REGION");
const COGNITO_USER_POOL_ID = _.get(process.env, "REACT_APP_COGNITO_USER_POOL_ID");
const COGNITO_USER_POOL_CLIENT_ID = _.get(process.env, "REACT_APP_COGNITO_USER_POOL_CLIENT_ID");

export const amplifyConfig = {
  Auth: {
    region: AWS_REGION,
    userPoolId: COGNITO_USER_POOL_ID,
    userPoolWebClientId: COGNITO_USER_POOL_CLIENT_ID,
    mandatorySignIn: false,
  },
};

export const SERVICE_BASE_URL = _.get(process.env, "REACT_APP_SERVICE_BASE_URL") as string;

export const GRAPHQL_ENDPOINT = _.get(
  process.env,
  "REACT_APP_GRAPHQL_ENDPOINT",
  "/graphql",
) as string;

export const TOS_ENDPOINT = _.get(process.env, "REACT_APP_TOS_ENDPOINT", "/tos") as string;

export const TAXONOMY_ENDPOINT = _.get(
  process.env,
  "ENV REACT_APP_TAXONOMY_ENDPOINT",
  "/taxonomy",
) as string;

export const SERVICE_STATUS_ENDPOINT = _.get(
  process.env,
  "REACT_APP_SERVICE_STATUS_ENDPOINT",
  "/service-status",
) as string;

export const FILE_ATTACHMENTS_ENDPOINT = _.get(
  process.env,
  "REACT_APP_FILE_ATTACHMENTS_ENDPOINT",
  "/file-attachments",
) as string;

export const REACT_APP_IS_TOS_ALWAYS_HIDDEN =
  _.get(process.env, "REACT_APP_IS_TOS_ALWAYS_HIDDEN", "true") === "true";
