import { Checkbox } from "antd";
import * as React from "react";

interface IProps {
  label: string;
  isChecked: boolean;
  onChange: any;
}

const IAgreeCheckbox = React.forwardRef<HTMLSpanElement, IProps>(
  ({ label, isChecked, onChange }, ref) => {
    return (
      <span className="agree-checkbox" ref={ref}>
        <Checkbox checked={isChecked} onChange={onChange}>
          {label}
        </Checkbox>
      </span>
    );
  },
);

export default IAgreeCheckbox;
