import * as _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import actionCreators from "../../../../../../actions/actionCreators";
import { IState, selectors } from "../../../../../../reducers/root";
import SortDirection from "../../../../../../types/enums/SortDirection";
import ProviderSelect from "./ProviderSelect";

const mapStateToProps = (state: IState, ownProps: any) => ({
  ...ownProps,
  providers: selectors.getProviders(state),
  isDisabled: ownProps.isDisabled,
  selectedProviderId: _.isEmpty(ownProps.value) ? undefined : ownProps.value,
  employer: selectors.getEmployer(state),
  sorting: { field: "name", direction: SortDirection.asc },
});

const mapDispatchToProps = (dispatch: any, ownProps) => {
  return {
    onInit: async () => {
      return await Promise.all([dispatch(actionCreators.fetchProviders())]);
    },
    onChange: ownProps.onChange,
  };
};

const ConnectedProviderSelect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderSelect as any);

export default withRouter(ConnectedProviderSelect);
