import { Alert, Col, Form, Row, Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Field, reduxForm } from "redux-form";
import FormInput from "../../form/FormInput/FormInput";
import FormTextArea from "../../form/FormTextArea/FormTextArea";
import "./EmployerInfoEditForm.less";

interface IProps {
  handleSubmit: any;
  submitting: boolean;
  error: any;
}

class EmployerInfoEditForm extends React.Component<IProps, {}> {
  public render() {
    const { handleSubmit, submitting, error } = this.props;
    return (
      <div className="EditContactInfoForm">
        <Form onSubmit={handleSubmit}>
          <Spin spinning={submitting} tip={"Saving..."}>
            <Row className="top-level-row">
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={"Employer Name"}
                  name="name"
                  component={FormInput}
                  required={true}
                  placeholder=""
                />
              </Col>
            </Row>
            <Row className="top-level-row">
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={"Employer Website"}
                  name="website"
                  component={FormInput}
                  required={false}
                  placeholder=""
                />
              </Col>
            </Row>
            <Row className="top-level-row">
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={"Employer Address"}
                  name="address"
                  component={FormInput}
                  required={false}
                  placeholder=""
                />
              </Col>
            </Row>
            <Row className="top-level-row">
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={"Contact name"}
                  name="contactName"
                  component={FormInput}
                  required={false}
                  placeholder=""
                />
              </Col>
            </Row>
            <Row gutter={20} className="top-level-row">
              <Col lg={15} md={15} sm={24}>
                <Field
                  label={"Contact email"}
                  name="contactEmail"
                  component={FormInput}
                  required={true}
                  placeholder=""
                  className="form-item-contact-email"
                />
              </Col>
              <Col lg={9} md={9} sm={24}>
                <Field
                  label={"Contact phone"}
                  name="contactPhone"
                  component={FormInput}
                  required={true}
                  placeholder=""
                  className="form-item-contact-phone"
                />
              </Col>
            </Row>
            <Row className="top-level-row">
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={"Description"}
                  name="description"
                  component={FormTextArea}
                  required={true}
                  rows={6}
                />
              </Col>
            </Row>
          </Spin>
          {error && <Alert message={error} type="error" />}
        </Form>
      </div>
    );
  }
}

const validate = values => {
  const errors = {} as any;
  if (_.isEmpty(_.trim(values.name))) {
    errors.name = "Employer name is required";
  }
  return errors;
};

export const formName = "EmployerInfoEditForm";

export default reduxForm({
  form: formName,
  validate,
})(EmployerInfoEditForm);
