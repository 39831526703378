import { Alert, Layout, Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { spinnerDelay } from "../../../../constants/constants";
import "../../../../styles/dataset-submit.less";
import DatasetUpdateFrequency from "../../../../types/enums/DatasetUpdateFrequency";
import { IDatasetInput } from "../../../../types/interfaces/IDatasetInput";
import sessionStore from "../../../../utils/sessionStore";
import DatasetSubmitForm, {
  defaultValues,
  IDatasetSubmitFormData,
} from "../../common/DatasetSubmitForm/DatasetSubmitForm";
import LegalFooter from "../../common/LegalFooter/LegalFooter";

const { Footer, Content } = Layout;

class DatasetEditPage extends React.Component<{
  isLoading: boolean;
  loadingError: string | null;
  onCancel: () => void;
  onSubmit: (data: IDatasetInput) => Promise<void>;
}> {
  public render() {
    const { isLoading, loadingError, onCancel, onSubmit } = this.props;
    return (
      <section className="DatasetEditPage dataset-submit">
        <Layout>
          <Content>
            <div className="title-container">
              <div className="title">Tell us about this dataset</div>
              {loadingError && (
                <Alert className="loading-error-banner" message={loadingError} type="error" />
              )}
            </div>
            <Spin
              spinning={isLoading || !_.isEmpty(loadingError)}
              indicator={loadingError ? <div /> : undefined}
              tip={loadingError ? "" : "Loading..."}
              delay={spinnerDelay}
            >
              <DatasetSubmitForm
                initialValues={defaultValues}
                onCancel={onCancel}
                onSubmit={async (values: IDatasetSubmitFormData) => {
                  await onSubmit({
                    userId: await sessionStore.getUserId(),
                    ...values,
                    providerId: values.providerId === "your_employer" ? null : values.providerId,
                    updateFrequency: values.updateFrequency as DatasetUpdateFrequency,
                    historyLength:
                      values.historyLength === -1 ? "Unknown" : `${values.historyLength}`,
                    categories: values.categories,
                    fileAttachments: _.map(
                      _.filter(values.fileAttachments, { status: "done" }),
                      attachment => attachment.id,
                    ),
                  } as any);
                }}
              />
            </Spin>
          </Content>
          <Footer style={{ padding: 0 }}>
            <LegalFooter />
          </Footer>
        </Layout>
      </section>
    );
  }
}

export default DatasetEditPage;
