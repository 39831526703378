import actionTypes from "../../actions/actionTypes";

export interface IState {
  isEmployerEditModalVisible: boolean;
}

const initialState: IState = {
  isEmployerEditModalVisible: false,
};

export default function(state: IState = initialState, action): IState {
  switch (action.type) {
    case actionTypes.SHOW_EMPLOYER_EDIT_MODAL: {
      return {
        ...state,
        isEmployerEditModalVisible: true,
      };
    }
    case actionTypes.HIDE_EMPLOYER_EDIT_MODAL: {
      return {
        ...state,
        isEmployerEditModalVisible: false,
      };
    }
    default:
      return state;
  }
}

export const getIsEmployerEditModalVisible = (state: IState) => state.isEmployerEditModalVisible;
