import { Button, Modal } from "antd";
import prettyBytes from "pretty-bytes";
import * as React from "react";

import "../../../../../../styles/alert-modal.less";

interface IProps {
  limit: number;
  isVisible: boolean;
  onClose: () => void;
}

class FilesUploadTotalSizeLimitAlert extends React.Component<IProps, {}> {
  public render() {
    const { limit, isVisible, onClose } = this.props;
    return (
      <Modal
        width={710}
        className="FormUploadTotalSizeLimitAlert alert-modal"
        title={`There is a total size limit of ${prettyBytes(
          limit,
        )} for all file attachments per dataset
            submission`}
        visible={isVisible}
        onOk={this.handleConfirmRequest}
        onCancel={onClose}
        footer={[
          <Button key="submit" className="secondary-button" onClick={this.handleConfirmRequest}>
            Close
          </Button>,
        ]}
      >
        <div className="modal-content">
          <span>Select smaller files or remove some files and try again.</span>
        </div>
      </Modal>
    );
  }

  private handleConfirmRequest = async () => {
    this.props.onClose();
  };
}

export default FilesUploadTotalSizeLimitAlert;
