import axios from "axios";
import * as _ from "lodash";

const debugAxios = (axiosError: {
  response?: {
    data: any;
    status: number;
    headers: string[];
  };
  request?: any;
  message?: string;
  config?: any;
}) => {
  if (axiosError.response) {
    console.log("AXIOS error.response"); // tslint:disable-line no-console
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log("DATA", axiosError.response.data); // tslint:disable-line no-console
    console.log("STATUS", axiosError.response.status); // tslint:disable-line no-console
    console.log("HEADERS", axiosError.response.headers); // tslint:disable-line no-console
  } else if (axiosError.request) {
    console.log("AXIOS error.request"); // tslint:disable-line no-console
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log("Error", axiosError.message); // tslint:disable-line no-console
    console.log(axiosError.request); // tslint:disable-line no-console
  } else {
    console.log("AXIOS error.message"); // tslint:disable-line no-console
    // Something happened in setting up the request that triggered an Error
    console.log("Error", axiosError.message); // tslint:disable-line no-console
  }
  console.log(axiosError.config); // tslint:disable-line no-console
};

export const httpGet = async (url: string, authHeader?: string) => {
  if (process.env.NODE_ENV === "development") {
    console.log(`HTTP GET REQUEST ${url}`); // tslint:disable-line no-console
  }
  try {
    const ret = await axios(
      _.omitBy(
        {
          method: "GET",
          url: `${url}`,
          headers: authHeader ? { Authorization: authHeader } : undefined,
          validateStatus: (status: number) => status >= 200 && status < 300,
        },
        _.isUndefined,
      ),
    );
    if (process.env.NODE_ENV === "development") {
      console.log(`HTTP GET SUCCESS ${url}`); // tslint:disable-line no-console
    }
    return ret;
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(`HTTP GET FAILURE ${url}`); // tslint:disable-line no-console
      debugAxios(error);
    }
    throw error;
  }
};

export const httpDelete = async (url: string, authHeader: string | null = null, data: any = {}) => {
  if (process.env.NODE_ENV === "development") {
    console.log(`HTTP DELETE REQUEST ${url} body=${JSON.stringify(data, null, 2)}`); // tslint:disable-line no-console
  }
  try {
    const ret = await axios({
      method: "DELETE",
      url,
      headers: authHeader ? { Authorization: authHeader } : undefined,
      data,
      validateStatus: status => status >= 200 && status < 300,
    });
    if (process.env.NODE_ENV === "development") {
      console.log(`HTTP DELETE SUCCESS ${url}`, ret.data); // tslint:disable-line no-console
    }
    return ret;
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.log(`HTTP DELETE FAILURE ${url}`); // tslint:disable-line no-console
      debugAxios(error);
    }
    throw error;
  }
};
