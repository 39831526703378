import gql from "graphql-tag";
import * as _ from "lodash";
import { IGqlDatasetsStats } from "../../../types/interfaces/IGqlDatasetsStats";
import apolloClient from "../../apolloClient";

const GET_DATASETS_STATS = gql`
  query DatasetsStats {
    datasetsStats {
      totalRewardPoints
      totalBasePaymentEligibleLeads
    }
  }
`;

const getDatasetsStats = async (): Promise<IGqlDatasetsStats> => {
  const ret: any = await apolloClient
    .query({
      query: GET_DATASETS_STATS,
      variables: {},
      fetchPolicy: "no-cache",
    })
    .catch(error => {
      throw new Error(error.graphQLErrors.join(",") || `Failed to get datasets stats.`);
    });

  if (!_.isEmpty(ret.errors) || !_.has(ret, "data.datasetsStats")) {
    throw new Error((ret.errors as any[]).map(it => `${it.message}`).join("\n"));
  }
  return ret.data.datasetsStats;
};

export default getDatasetsStats;
