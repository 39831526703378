import { Button, Icon, Table, Tooltip } from "antd";
import { History } from "history";
import * as _ from "lodash";
import * as moment from "moment-timezone";
import * as React from "react";

import { spinnerDelay } from "../../../../../../constants/constants";
import SanitizedStatus from "../../../../../../types/enums/SanitizedStatus";
import SortDirection from "../../../../../../types/enums/SortDirection";
import { IGqlDatasetDecision } from "../../../../../../types/interfaces/IGqlDatasetDecision";
import { IGqlDatasetRow } from "../../../../../../types/interfaces/IGqlDatasetRow";
import { IGqlUser, IGqlUserRole } from "../../../../../../types/interfaces/IGqlUser";
import ISorting from "../../../../../../types/interfaces/ISorting";
import { displayStatusTag } from "../../../../common/DatasetStatusTag/DatasetStatusTag";
import YourEmployerTag from "../../../../common/YourEmployerTag/YourEmployerTag";

interface IProps {
  history: History;
  user: IGqlUser | null;
  datasets: IGqlDatasetRow[];
  employer: any | null;
  isLoading: boolean;
  loadingError: string | null;
  pageSize: number;
  currentPage: number;
  sorting: ISorting;
  totalItemCount: number;
  onChangePageSize: (pageSize: number) => void;
  onChangeCurrentPage: (currentPage: number) => void;
  onChangeSorting: (field: string, direction: SortDirection) => void;
  onClickProvider: (providerId: string) => void;
  onClickEmployer: () => void;
  onClickDeleteDataset: (datasetId: string) => void;
  onClickRow: (row: any) => void;
}

class DatasetsTable extends React.Component<IProps, {}> {
  public render() {
    const formatDate = (dateTimeString: string): string => {
      return moment(dateTimeString).format("M/DD/YYYY");
    };

    const columns = _.filter(
      [
        {
          title: "Name",
          dataIndex: "name",
          display: true,
          className: "dataset-name-col",
          render: (name, record) => (
            <a
              onClick={e => {
                e.stopPropagation();
                this.props.onClickRow(record);
              }}
            >
              {_.truncate(name, {
                length: 80,
              })}
            </a>
          ),
        },
        {
          title: "Provider",
          dataIndex: "provider.name",
          display: true,
          className: "dataset-provider-col",
          render: (providerName, record) => {
            if (providerName) {
              return (
                <a
                  className="DatasetsTable-provider"
                  onClick={e => {
                    e.stopPropagation();
                    this.props.onClickProvider(record.provider.id);
                  }}
                >
                  {providerName}
                </a>
              );
            }
            if (this.props.employer) {
              return (
                <a
                  className="DatasetsTable-employer"
                  onClick={e => {
                    e.stopPropagation();
                    this.props.onClickEmployer();
                  }}
                >
                  {this.props.employer.name}
                  <YourEmployerTag />
                </a>
              );
            }
            return "";
          },
        },
        {
          title: <span>Submit Date</span>,
          dataIndex: "createdTime",
          display: true,
          render: (createdTime, record) => {
            return formatDate(createdTime);
          },
        },
        {
          title: "Status",
          dataIndex: "decision",
          display: this.props.user && this.props.user.role === IGqlUserRole.dataScout,
          className: "dataset-status-col",
          render: (decision: IGqlDatasetDecision, record) => {
            return (
              <>
                {displayStatusTag(decision)}
                {decision.externalComments && decision.externalComments !== "" ? (
                  <Tooltip placement="bottom" title={decision.externalComments}>
                    <Icon
                      style={{ fontSize: "18px", position: "relative", top: "8px" }}
                      type="mail"
                      theme="twoTone"
                      twoToneColor="#157FF2"
                    />
                  </Tooltip>
                ) : null}
              </>
            );
          },
        },
        {
          title: "",
          dataIndex: "delete",
          display: true,
          render: (d, record: IGqlDatasetRow) => {
            return (this.props.user && this.props.user.role !== IGqlUserRole.dataScout) ||
              record.decision.sanitizedStatus === SanitizedStatus.submitted ? (
              <div
                className="delete DatasetsTable-delete"
                onClick={e => {
                  e.stopPropagation();
                  this.props.onClickDeleteDataset(record.id);
                }}
              >
                Delete
              </div>
            ) : null;
          },
        },
      ],
      (col: any) => col.display,
    );

    const sortingDirectiondMap = {
      descend: SortDirection.desc,
      ascend: SortDirection.asc,
    };
    const { history } = this.props;

    const emptyText = (() => {
      if (this.props.isLoading) {
        return <div className="empty-table-content" />;
      }
      if (this.props.loadingError) {
        return (
          <div className="empty-table-content">
            Failed to load datasets. {this.props.loadingError}
          </div>
        );
      }
      return (
        <div className="empty-table-content">
          <div className="title">You have no submitted datasets</div>
          <div className="subtitle">Add your first dataset now</div>
          <Button
            className="add-button"
            type="primary"
            onClick={() => {
              history.push("/dataset_submit");
            }}
          >
            Add dataset
          </Button>
        </div>
      );
    })();

    const sortedDatasets = _.orderBy(
      this.props.datasets,
      [this.props.sorting.field],
      [this.props.sorting.direction],
    );

    return (
      <div className="DatasetsTable">
        <Table
          columns={columns}
          dataSource={_.map(sortedDatasets, d => ({ ...d, key: d.id, delete: "delete" }))}
          pagination={false}
          loading={this.props.isLoading ? { tip: "Loading", delay: spinnerDelay } : false}
          onChange={(pagination, filters, sorter) => {
            const { pageSize, current } = pagination as any;
            const { field, order } = sorter as any;
            if (pageSize !== this.props.pageSize) {
              this.props.onChangePageSize(pageSize);
            }
            if (current !== this.props.currentPage) {
              this.props.onChangeCurrentPage(current);
            }
            if (
              field !== this.props.sorting.field ||
              sortingDirectiondMap[order] !== this.props.sorting.direction
            ) {
              this.props.onChangeSorting(field, sortingDirectiondMap[order]);
            }
          }}
          locale={{ emptyText }}
        />
      </div>
    );
  }
}

export default DatasetsTable;
