import * as _ from "lodash";
import * as React from "react";

const WebsiteLink = ({ url }) => (
  <a href={_.startsWith(url, "http") ? url : `https://${url}`} target="_blank">
    {_.truncate(url, {
      length: 50,
    })}
  </a>
);

export default WebsiteLink;
