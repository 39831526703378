import { Tag } from "antd";
import * as React from "react";
import "./YourEmployerTag.less";

const YourEmployerTag = () => (
  <Tag color="#15A0CD" className="YourEmployerTag" style={{ marginLeft: "10px" }}>
    Your employer
  </Tag>
);

export default YourEmployerTag;
