export default {
  ACCEPT_TERMS_OF_SERVICE_REQUEST: "ACCEPT_TERMS_OF_SERVICE_REQUEST",
  ACCEPT_TERMS_OF_SERVICE_SUCCESS: "ACCEPT_TERMS_OF_SERVICE_SUCCESS",
  ACCEPT_TERMS_OF_SERVICE_FAILURE: "ACCEPT_TERMS_OF_SERVICE_FAILURE",
  CONFIRM_SIGN_UP_REQUEST: "CONFIRM_SIGN_UP_REQUEST",
  CONFIRM_SIGN_UP_SUCCESS: "CONFIRM_SIGN_UP_SUCCESS",
  CONFIRM_SIGN_UP_FAILURE: "CONFIRM_SIGN_UP_FAILURE",
  CREATE_DATASET_REQUEST: "CREATE_DATASET_REQUEST",
  CREATE_DATASET_SUCCESS: "CREATE_DATASET_SUCCESS",
  CREATE_DATASET_FAILURE: "CREATE_DATASET_FAILURE",
  CREATE_PROVIDER_REQUEST: "CREATE_PROVIDER_REQUEST",
  CREATE_PROVIDER_SUCCESS: "CREATE_PROVIDER_SUCCESS",
  CREATE_PROVIDER_FAILURE: "CREATE_PROVIDER_FAILURE",
  DELETE_DATASETS_REQUEST: "DELETE_DATASETS_REQUEST",
  DELETE_DATASETS_SUCCESS: "DELETE_DATASETS_SUCCESS",
  DELETE_DATASETS_FAILURE: "DELETE_DATASETS_FAILURE",
  DELETE_PROVIDER_AND_ALL_ITS_DATASETS_REQUEST: "DELETE_PROVIDER_AND_ALL_ITS_DATASETS_REQUEST",
  DELETE_PROVIDER_AND_ALL_ITS_DATASETS_SUCCESS: "DELETE_PROVIDER_AND_ALL_ITS_DATASETS_SUCCESS",
  DELETE_PROVIDER_AND_ALL_ITS_DATASETS_FAILURE: "DELETE_PROVIDER_AND_ALL_ITS_DATASETS_FAILURE",
  DELETE_PROVIDERS_REQUEST: "DELETE_PROVIDERS_REQUEST",
  DELETE_PROVIDERS_SUCCESS: "DELETE_PROVIDERS_SUCCESS",
  DELETE_PROVIDERS_FAILURE: "DELETE_PROVIDERS_FAILURE",
  FETCH_DATASET_REQUEST: "FETCH_DATASET_REQUEST",
  FETCH_DATASET_SUCCESS: "FETCH_DATASET_SUCCESS",
  FETCH_DATASET_FAILURE: "FETCH_DATASET_FAILURE",
  FETCH_DATASETS_REQUEST: "FETCH_DATASETS_REQUEST",
  FETCH_DATASETS_SUCCESS: "FETCH_DATASETS_SUCCESS",
  FETCH_DATASETS_FAILURE: "FETCH_DATASETS_FAILURE",
  FETCH_EMPLOYER_REQUEST: "FETCH_EMPLOYER_REQUEST",
  FETCH_EMPLOYER_SUCCESS: "FETCH_EMPLOYER_SUCCESS",
  FETCH_EMPLOYER_FAILURE: "FETCH_EMPLOYER_FAILURE",
  FETCH_PROVIDERS_REQUEST: "FETCH_PROVIDERS_REQUEST",
  FETCH_PROVIDERS_SUCCESS: "FETCH_PROVIDERS_SUCCESS",
  FETCH_PROVIDERS_FAILURE: "FETCH_PROVIDERS_FAILURE",
  FETCH_USER_REQUEST: "FETCH_USER_REQUEST",
  FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
  FETCH_USER_FAILURE: "FETCH_USER_FAILURE",
  HIDE_EMPLOYER_EDIT_MODAL: "HIDE_EMPLOYER_EDIT_MODAL",
  RESEND_SIGN_UP_CODE_MAIL_REQUEST: "RESEND_SIGN_UP_CODE_MAIL_REQUEST",
  RESEND_SIGN_UP_CODE_MAIL_SUCCESS: "RESEND_SIGN_UP_CODE_MAIL_SUCCESS",
  RESEND_SIGN_UP_CODE_MAIL_FAILURE: "RESEND_SIGN_UP_CODE_MAIL_FAILURE",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  RETRIEVE_SESSION_SUCCESS: "RETRIEVE_SESSION_SUCCESS",
  SEND_SIGN_UP_CODE_MAIL_REQUEST: "SEND_SIGN_UP_CODE_MAIL_REQUEST",
  SEND_SIGN_UP_CODE_MAIL_SUCCESS: "SEND_SIGN_UP_CODE_MAIL_SUCCESS",
  SEND_SIGN_UP_CODE_MAIL_FAILURE: "SEND_SIGN_UP_CODE_MAIL_FAILURE",
  SEND_RESET_PASSWORD_MAIL_REQUEST: "SEND_RESET_PASSWORD_MAIL_REQUEST",
  SEND_RESET_PASSWORD_MAIL_SUCCESS: "SEND_RESET_PASSWORD_MAIL_SUCCESS",
  SEND_RESET_PASSWORD_MAIL_FAILURE: "SEND_RESET_PASSWORD_MAIL_FAILURE",
  SHOW_EMPLOYER_EDIT_MODAL: "SHOW_EMPLOYER_EDIT_MODAL",
  SIGN_IN_REQUEST: "SIGN_IN_REQUEST",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  SIGN_OUT_REQUEST: "SIGN_OUT_REQUEST",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",
  SIGN_UP_REQUEST: "SIGN_UP_REQUEST",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
  SUBMIT_EMPLOYER_INFO_REQUEST: "SUBMIT_EMPLOYER_INFO_REQUEST",
  SUBMIT_EMPLOYER_INFO_SUCCESS: "SUBMIT_EMPLOYER_INFO_SUCCESS",
  SUBMIT_EMPLOYER_INFO_FAILURE: "SUBMIT_EMPLOYER_INFO_FAILURE",
  UPDATE_DATASET_REQUEST: "UPDATE_DATASET_REQUEST",
  UPDATE_DATASET_SUCCESS: "UPDATE_DATASET_SUCCESS",
  UPDATE_DATASET_FAILURE: "UPDATE_DATASET_FAILURE",
  UPDATE_EMPLOYER_REQUEST: "UPDATE_EMPLOYER_REQUEST",
  UPDATE_EMPLOYER_SUCCESS: "UPDATE_EMPLOYER_SUCCESS",
  UPDATE_EMPLOYER_FAILURE: "UPDATE_EMPLOYER_FAILURE",
  UPDATE_PROVIDER_REQUEST: "UPDATE_PROVIDER_REQUEST",
  UPDATE_PROVIDER_SUCCESS: "UPDATE_PROVIDER_SUCCESS",
  UPDATE_PROVIDER_FAILURE: "UPDATE_PROVIDER_FAILURE",
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
};
