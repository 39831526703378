import { Alert, Button, Modal, Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { spinnerDelay } from "../../../../constants/constants";
import "../../../../styles/edit-modal.less";
import ProviderSubmitForm, {
  formName as name,
  initialValues,
} from "../ProviderSubmitForm/ProviderSubmitForm";

const ProviderAddModal = ({
  isVisible,
  onCancel,
  onSave,
  onClickCreateButton,
  isInitializing,
  initializationError,
  isSaving,
}: {
  isVisible: boolean;
  onCancel: () => void;
  onSave: (values: any) => Promise<void>;
  onClickCreateButton: () => Promise<void>;
  isInitializing: boolean;
  initializationError: string | null;
  isSaving: boolean;
}) => {
  return (
    <Modal
      width={710}
      className="ProviderAddModal edit-modal"
      title="Create a new provider"
      visible={isVisible}
      onOk={onClickCreateButton}
      onCancel={onCancel}
      maskClosable={false}
      footer={[
        <Button
          key="create"
          className="primary-button"
          type="primary"
          loading={isSaving}
          onClick={onClickCreateButton}
          disabled={isSaving || isInitializing || !_.isEmpty(initializationError)}
        >
          Save changes
        </Button>,
        <Button key="cancel" className="cancel-button" onClick={onCancel} disabled={isSaving}>
          Cancel
        </Button>,
      ]}
    >
      {initializationError && (
        <Alert className="initialization-error-banner" message={initializationError} type="error" />
      )}
      <Spin
        spinning={isInitializing || !_.isEmpty(initializationError)}
        indicator={initializationError ? <div /> : undefined}
        tip={initializationError ? "" : "Initializing..."}
        delay={spinnerDelay}
      >
        {isVisible && (
          <ProviderSubmitForm
            initialValues={{
              name: "",
              address: "",
              contactEmail: "",
              contactPhone: "",
            }}
            onSubmit={onSave}
          />
        )}
      </Spin>
    </Modal>
  );
};

export const formName = name;
export const formInitialValues = initialValues;
export default ProviderAddModal;
