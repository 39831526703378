import { Avatar, Col, Dropdown, Icon, Menu, Row } from "antd";
import { History } from "history";
import * as _ from "lodash";
import * as React from "react";
import { Link } from "react-router-dom";
import { IGqlDatasetsStats } from "../../../../types/interfaces/IGqlDatasetsStats";
import { IGqlUserRole } from "../../../../types/interfaces/IGqlUser";
import graphqlApi from "../../../../utils/graphqlApi/graphqlApi";
import ContactInfoEditModal from "../ContactInfoEditModal/ConnectedContactInfoEditModal";
import EmployerInfoEditModal from "../EmployerInfoEditModal/ConnectedEmployerInfoEditModal";
import "./AppHeader.less";
import logo from "./images/DataPortalLogo2x.png";

const getSelectedMenuItem = location => {
  switch (location.pathname) {
    case "/datasets":
      return "datasets";
    case "/providers":
      return "providers";
    default:
      return null;
  }
};

interface IProps {
  location: object;
  history: History;
  user: any | null;
  employer: any | null;
  isEmployerEditModalVisible: boolean;
  onSignOut: () => Promise<any>;
  onUpdateContactInfo: () => Promise<any>;
  onUpdateEmployerInfo: () => Promise<any>;
  onSetIsEmployerEditModalVisible: (isVisible: boolean) => void;
}

interface IState {
  isContactInfoEditModalVisible: boolean;
  datasetsStats: IGqlDatasetsStats | null;
}

class AppHeader extends React.Component<IProps, IState> {
  public state: IState = {
    isContactInfoEditModalVisible: false,
    datasetsStats: null,
  };

  public async componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.user && this.props.user !== nextProps.user) {
      if (nextProps.user.role === IGqlUserRole.dataScout) {
        const datasetsStats: IGqlDatasetsStats = await graphqlApi.getDatasetsStats();
        this.setState({ datasetsStats });
      } else {
        this.setState({ datasetsStats: null });
      }
    }
  }

  public render() {
    const { location, history, user, employer, onSignOut } = this.props;
    const menu = (
      <Menu
        className={"header-menu"}
        selectedKeys={[]}
        onClick={() => {
          console.log("onclick"); // tslint:disable-line no-console
        }}
      >
        <Menu.Item>
          <div
            onClick={() => {
              this.setState({ isContactInfoEditModalVisible: true });
            }}
          >
            <Icon type="user" /> Contact
          </div>
        </Menu.Item>
        {employer && (
          <Menu.Item>
            <div
              onClick={() => {
                this.props.onSetIsEmployerEditModalVisible(true);
              }}
            >
              <Icon type="team" /> Employer
            </div>
          </Menu.Item>
        )}
        <Menu.Divider />
        <Menu.Item key="logout">
          <div
            onClick={async () => {
              await onSignOut();
              history.push("/sign_in");
            }}
          >
            <Icon type="logout" /> Sign out
          </div>
        </Menu.Item>
      </Menu>
    );
    return (
      <div className="AppHeader">
        <Row>
          <Col xxl={4} xl={4} lg={4} md={4} sm={24} xs={24}>
            <Link to={"/datasets"} id="logo">
              <img alt="logo" src={logo} />
            </Link>
          </Col>
          {user !== null && (
            <Col xxl={20} xl={20} lg={20} md={20} sm={24} xs={24} id="nav-container">
              <Menu
                className="menu-site"
                mode="horizontal"
                id="nav"
                key="nav"
                selectedKeys={_.compact([getSelectedMenuItem(location)])}
              >
                <Menu.Item key="user" className="menu-item-user">
                  <Dropdown overlay={menu}>
                    <span>
                      <Avatar
                        size="small"
                        icon="user"
                        className="user-avatar"
                        style={{ marginRight: "7px" }}
                      />{" "}
                      {user.name}
                    </span>
                  </Dropdown>
                </Menu.Item>
                {this.state.datasetsStats ? (
                  <Menu.Item key="total-reward-points" className="stats menu-item-reward-points">
                    <div>
                      <span className="heading">Reward points earned</span>
                      <span className="number">
                        {this.state.datasetsStats.totalRewardPoints}
                      </span>{" "}
                      <span className="suffix">points</span>
                    </div>
                  </Menu.Item>
                ) : null}
                {this.state.datasetsStats ? (
                  <Menu.Item key="total-leads" className="stats menu-item-leads">
                    <div>
                      <span className="heading">Base payment eligible</span>
                      <span className="number">
                        {this.state.datasetsStats.totalBasePaymentEligibleLeads}
                      </span>{" "}
                      <span className="suffix">leads</span>
                    </div>
                  </Menu.Item>
                ) : null}
              </Menu>
            </Col>
          )}
        </Row>
        {user !== null && (
          <ContactInfoEditModal
            isVisible={this.state.isContactInfoEditModalVisible}
            onDone={() => {
              this.setState({ isContactInfoEditModalVisible: false });
            }}
          />
        )}
        {user !== null && (
          <EmployerInfoEditModal
            isVisible={this.props.isEmployerEditModalVisible}
            onCancel={() => {
              this.props.onSetIsEmployerEditModalVisible(false);
            }}
            onSubmit={async () => {
              this.props.onSetIsEmployerEditModalVisible(false);
              await this.props.onUpdateEmployerInfo();
            }}
          />
        )}
      </div>
    );
  }
}

export default AppHeader;
