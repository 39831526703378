import { Alert, Button, Modal, Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { spinnerDelay } from "../../../../constants/constants";
import "../../../../styles/edit-modal.less";
import ContactInfoEditForm, { formName } from "./ContactInfoEditForm/ContactInfoEditForm";

const ContactInfoEditModal = ({
  user,
  isVisible,
  onCancel,
  onClickSubmitButton,
  onSubmit,
  isInitializing,
  initializationError,
  isSaving,
}: {
  user: any | null;
  isVisible: boolean;
  onCancel: () => void;
  onClickSubmitButton: () => Promise<void>;
  onSubmit: (userName: string) => Promise<void>;
  isInitializing: boolean;
  initializationError: string | null;
  isSaving: boolean;
}) => (
  <Modal
    width={710}
    className="ContactInfoEditModal edit-modal"
    title={`Edit contact`}
    visible={isVisible}
    onOk={onClickSubmitButton}
    onCancel={onCancel}
    footer={[
      <Button
        key="submit"
        className="primary-button"
        type="primary"
        loading={isSaving}
        onClick={onClickSubmitButton}
        disabled={isSaving || isInitializing || !_.isEmpty(initializationError)}
      >
        Submit
      </Button>,
      <Button key="cancel" className="cancel-button" onClick={onCancel} disabled={isSaving}>
        Cancel
      </Button>,
    ]}
  >
    {initializationError && (
      <Alert className="initialization-error-banner" message={initializationError} type="error" />
    )}
    <Spin
      spinning={isInitializing || !_.isEmpty(initializationError)}
      indicator={initializationError ? <div /> : undefined}
      tip={initializationError ? "" : "Initializing..."}
      delay={spinnerDelay}
    >
      <ContactInfoEditForm
        initialValues={{ name: user && user.name }}
        onSubmit={async values => {
          await onSubmit(values.name);
        }}
      />
    </Spin>
  </Modal>
);

export const form = formName;

export default ContactInfoEditModal;
