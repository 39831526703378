import * as _ from "lodash";
import { IEmployerInfo } from "../../types/interfaces/IEmployerInfo";
import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import sessionStore from "../../utils/sessionStore";
import actionTypes from "../actionTypes";

const submitEmployerInfoThunk = (employerInfo: IEmployerInfo) => async dispatch => {
  dispatch({ type: actionTypes.SUBMIT_EMPLOYER_INFO_REQUEST });
  try {
    const userId = await sessionStore.getUserId();
    const result = await graphqlApi.createEmployerCandidate(
      userId,
      employerInfo.name,
      employerInfo.website,
      employerInfo.address,
    );
    if (_.isEmpty(result.errors)) {
      dispatch({
        type: actionTypes.SUBMIT_EMPLOYER_INFO_SUCCESS,
        data: result.data,
      });
    } else {
      throw new Error(
        `Failed createEmployerCandidate, ${result.errors.map(it => it.message).join("\n")}`,
      );
    }
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.SUBMIT_EMPLOYER_INFO_FAILURE,
      error: error.message ? error.message : error,
    });
    throw error;
  }
};

export default submitEmployerInfoThunk;
