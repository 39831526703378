import gql from "graphql-tag";
import * as _ from "lodash";
import { IDatasetsLoadingParams } from "../../../types/interfaces/IDatasetsLoadingParams";
import { IGqlDatasetRow } from "../../../types/interfaces/IGqlDatasetRow";
import apolloClient from "../../apolloClient";

const GET_DATASETS_WITH_PARAMS = gql`
  query Datasets($params: QueryParams!) {
    datasets(params: $params) {
      id
      name
      provider {
        id
        name
      }
      createdBy {
        id
        name
      }
      createdTime
      decision {
        id
        sanitizedStatus
        isPaymentProcessed
        externalComments
      }
    }
  }
`;

const getDatasetsWithParams = async (params: IDatasetsLoadingParams): Promise<IGqlDatasetRow[]> => {
  const variables = {
    params: _.omitBy(
      {
        filters: _.map(params.filters, (value, key) => {
          return `${key}=${(value as string[]).join(".")}`;
        }),
      },
      _.isNull,
    ),
  };

  const ret: any = await apolloClient
    .query({
      query: GET_DATASETS_WITH_PARAMS,
      variables,
      fetchPolicy: "no-cache",
    })
    .catch(error => {
      throw new Error(error.graphQLErrors.join(",") || `Failed to get datasets with params.`);
    });

  if (!_.isEmpty(ret.errors) || !_.has(ret, "data.datasets")) {
    throw new Error((ret.errors as any[]).map(it => `${it.message}`).join("\n"));
  }
  return ret.data.datasets;
};

export default getDatasetsWithParams;
