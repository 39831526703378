import { Icon } from "antd";
import * as React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { REACT_APP_IS_TOS_ALWAYS_HIDDEN } from "../../../../utils/config";
import "./LegalFooter.less";

export default () => {
  return (
    <div className="legalFooter">
      <div className="legalFooter-content">
        <div className="copyright">
          <Fragment>
            Copyright <Icon type="copyright" /> 2018 - 2022 Two Sigma Investments, LP.
          </Fragment>
        </div>
        <div className="link-container">
          <Link to="/cookie_policy" target="_blank">
            Cookie Disclosure
          </Link>
          <Link to="/privacy_policy" target="_blank">
            Privacy Policy
          </Link>
          {!REACT_APP_IS_TOS_ALWAYS_HIDDEN && (
            <Link to="/terms_of_service" target="_blank">
              Terms of Service
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
