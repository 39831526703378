import * as _ from "lodash";
import { selectors } from "../../reducers/root";
import { IGqlUserInput } from "../../types/interfaces/IGqlUserInput";
import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import sessionStore from "../../utils/sessionStore";
import actionTypes from "../actionTypes";

const updateUserThunk = (name: string) => async (dispatch, getState) => {
  const userId = await sessionStore.getUserId();
  if (userId === null) {
    throw new Error("Cannot update user, missing user ID");
  }
  const user = selectors.getUser(getState());
  if (!user) {
    throw new Error("Cannot update user, user was not loaded");
  }
  const userInput: IGqlUserInput = {
    name,
  };
  dispatch({ type: actionTypes.UPDATE_USER_REQUEST });
  try {
    const { user: updated } = await graphqlApi.updateUser(userId, userInput);
    dispatch({
      type: actionTypes.UPDATE_USER_SUCCESS,
      user: updated,
    });
    return updated;
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.UPDATE_USER_FAILURE,
      error: error.message,
    });
    throw error;
  }
};

export default updateUserThunk;
