import { Alert, Col, Form, Row, Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Field, reduxForm } from "redux-form";
import FormInput from "../../../common/form/FormInput/FormInput";
import "./ContactInfoEditForm.less";

interface IProps {
  handleSubmit: any;
  submitting: boolean;
  error: any;
}

class ContactInfoEditForm extends React.Component<IProps, {}> {
  public render() {
    const { handleSubmit, submitting, error } = this.props;
    return (
      <div className="EditContactInfoForm">
        <Form onSubmit={handleSubmit}>
          <Spin spinning={submitting} tip={"Saving..."}>
            <Row>
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={"Name"}
                  name="name"
                  component={FormInput}
                  required={true}
                  placeholder=""
                />
              </Col>
            </Row>
          </Spin>
          {error && <Alert message={error} type="error" className="processing-error-banner" />}
        </Form>
      </div>
    );
  }
}

const validate = values => {
  const errors = {} as any;
  if (_.isEmpty(_.trim(values.name))) {
    errors.name = "Name is required";
  }
  return errors;
};

export const formName = "ContactInfoEditForm";

export default reduxForm({
  form: formName,
  validate,
})(ContactInfoEditForm);
