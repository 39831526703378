import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import actionCreators from "../../../../../../actions/actionCreators";
import { IState as IStoreState } from "../../../../../../reducers/root";
import { ISignUpData } from "../../../../../../types/interfaces/ISignUpData";
import SignUpPageContainer, { IDispatchProps, IStateProps } from "./SignUpStepContainer";

interface IOwnProps extends RouteComponentProps<any> {}

const ConnectedSignUpPage = connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(
  (state: IStoreState, ownProps: IOwnProps) => ({
    history: ownProps.history,
  }),
  (dispatch: any) => ({
    onInit: async () => {
      await dispatch(actionCreators.signOut());
    },
    onSubmit: async (signUpData: ISignUpData) => {
      await dispatch(actionCreators.signUp(signUpData));
    },
  }),
)(SignUpPageContainer);

export default withRouter(ConnectedSignUpPage);
