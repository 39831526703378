import { Alert, Button, Modal, Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { spinnerDelay } from "../../../../constants/constants";
import "../../../../styles/edit-modal.less";
import { IEmployerInput } from "../../../../types/interfaces/IEmployerInput";
import EmployerInfoEditForm, { formName } from "./EmployerInfoEditForm/EmployerInfoEditForm";

interface IFormData {
  name: string;
  website: string | null;
  address: string | null;
}

export const initialValues: IFormData = {
  name: "",
  website: "",
  address: "",
};

const EmployerInfoEditModal = ({
  employer,
  isVisible,
  onCancel,
  onClickSubmitButton,
  onSubmit,
  isInitializing,
  initializationError,
  isSaving,
}: {
  employer: any | null;
  isVisible: boolean;
  onCancel: () => void;
  onClickSubmitButton: () => Promise<void>;
  onSubmit: (employerInput: IEmployerInput) => Promise<void>;
  isInitializing: boolean;
  initializationError: string | null;
  isSaving: boolean;
}) => {
  return (
    <Modal
      width={710}
      className="EmployerInfoEditModal edit-modal"
      title={`Edit employer`}
      visible={isVisible}
      onOk={onClickSubmitButton}
      onCancel={onCancel}
      footer={[
        <Button
          key="submit"
          className="primary-button"
          type="primary"
          loading={isSaving}
          onClick={onClickSubmitButton}
          disabled={isSaving || isInitializing || !_.isEmpty(initializationError)}
        >
          Submit
        </Button>,
        <Button key="cancel" className="cancel-button" onClick={onCancel} disabled={isSaving}>
          Cancel
        </Button>,
      ]}
    >
      {initializationError && (
        <Alert className="initialization-error-banner" message={initializationError} type="error" />
      )}
      <Spin
        spinning={isInitializing || !_.isEmpty(initializationError)}
        indicator={initializationError ? <div /> : undefined}
        tip={initializationError ? "" : "Initializing..."}
        delay={spinnerDelay}
      >
        <EmployerInfoEditForm
          initialValues={{
            name: "",
            website: "",
            address: "",
          }}
          onSubmit={onSubmit}
        />
      </Spin>
    </Modal>
  );
};

export const form = formName;

export default EmployerInfoEditModal;
