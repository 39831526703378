import * as React from "react";
import SanitizedStatus from "../../../../types/enums/SanitizedStatus";
import { IGqlDatasetDecision } from "../../../../types/interfaces/IGqlDatasetDecision";
import "./DatasetStatusTag.less";

export const SubmittedStatusTag = () => (
  <div className="submitted-tag tag">
    <span>Submitted</span>
  </div>
);

export const InReviewStatusTag = () => (
  <div className="in-review-tag tag">
    <span>In Review</span>
  </div>
);

export const PaymentEligibleStatusTag = () => (
  <div className="payment-eligible-tag tag">
    <span>Payment Eligible</span>
  </div>
);

export const PaymentIneligibleStatusTag = () => (
  <div className="payment-ineligible-tag tag">
    <span>Payment Ineligible</span>
  </div>
);

export const PaymentProcessedStatusTag = () => (
  <div className="payment-processed-tag tag">
    <span>Payment Processed</span>
  </div>
);

export const displayStatusTag = (datasetDecision: IGqlDatasetDecision) => {
  let statusTag = <></>;
  switch (datasetDecision.sanitizedStatus) {
    case SanitizedStatus.inReview: {
      statusTag = <InReviewStatusTag />;
      break;
    }
    case SanitizedStatus.submitted: {
      statusTag = <SubmittedStatusTag />;
      break;
    }
    case SanitizedStatus.paymentEligible: {
      statusTag = <PaymentEligibleStatusTag />;
      break;
    }
    case SanitizedStatus.paymentIneligible: {
      statusTag = <PaymentIneligibleStatusTag />;
      break;
    }
  }
  const paymentProcessedTag = datasetDecision.isPaymentProcessed ? (
    <PaymentProcessedStatusTag />
  ) : null;
  return (
    <>
      {statusTag}
      {paymentProcessedTag}
    </>
  );
};
