enum DatasetUpdateFrequency {
  unknown = "UNKNOWN",
  intraday = "INTRADAY",
  daily = "DAILY",
  weekly = "WEEKLY",
  monthly = "MONTHLY",
  quarterly = "QUARTERLY",
}

export default DatasetUpdateFrequency;
