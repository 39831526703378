import gql from "graphql-tag";
import * as _ from "lodash";
import { IGqlEmployer } from "../../../types/interfaces/IGqlEmployer";
import apolloClient from "../../apolloClient";

const GET_EMPLOYER = gql`
  query Employer {
    employer {
      id
      name
      website
      address
      contactName
      contactEmail
      contactPhone
      description
    }
  }
`;

const getEmployer = async (): Promise<IGqlEmployer | null> => {
  console.log("GraphQL getEmployer"); // tslint:disable-line no-console

  const ret: any = await apolloClient
    .query({
      query: GET_EMPLOYER,
      variables: {},
      fetchPolicy: "no-cache",
    })
    .catch(error => {
      throw new Error(error.graphQLErrors.join(",") || `Failed to get employer.`);
    });

  if (!_.isEmpty(ret.errors)) {
    throw new Error(`Failed to get employer: ${_.map(ret.errors, "message").join(", ")}`);
  }
  return ret.data.employer;
};

export default getEmployer;
