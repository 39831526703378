import Auth from "@aws-amplify/auth";
import * as _ from "lodash";

import { ISignUpData } from "../../types/interfaces/ISignUpData";
import actionTypes from "../actionTypes";

const signUpThunk = (data: ISignUpData) => async dispatch => {
  dispatch({ type: actionTypes.SIGN_UP_REQUEST });
  try {
    const username = _.trim(data.email.toLowerCase());
    const password = data.password;
    const user = await Auth.signUp({
      username,
      password,
      attributes: {
        name: data.name,
      },
    });
    dispatch({
      type: actionTypes.SIGN_UP_SUCCESS,
      user,
    });
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.SIGN_UP_FAILURE,
      error: error.message,
    });
    throw error;
  }
};

export default signUpThunk;
