import * as _ from "lodash";
import { FILE_ATTACHMENTS_ENDPOINT, SERVICE_BASE_URL } from "../config";
import { httpDelete } from "../httpUtils";
import sessionStore from "../sessionStore";

const fileAttachmentsApi = {
  deleteFile: async fileId => {
    const idToken = await sessionStore.getIdToken();
    await httpDelete(
      `${SERVICE_BASE_URL}${FILE_ATTACHMENTS_ENDPOINT}/${fileId}`,
      !_.isEmpty(idToken) ? `Bearer ${idToken}` : "",
    );
    return true;
  },
};

export default fileAttachmentsApi;
