import { SERVICE_BASE_URL, TAXONOMY_ENDPOINT } from "../../config";
import { httpGet } from "../../httpUtils";

const getTaxonomy = async (): Promise<{
  taxonomy: any;
  errors?: any[];
}> => {
  const ret = await httpGet(`${SERVICE_BASE_URL}${TAXONOMY_ENDPOINT}`);
  return {
    taxonomy: ret.data as any,
  };
};

export default getTaxonomy;
