import { Alert, Button, Layout } from "antd";
import * as H from "history";
import * as _ from "lodash";
import * as React from "react";
import "../../../../../styles/list-page.less";
import SanitizedStatus from "../../../../../types/enums/SanitizedStatus";
import {
  FilterableColumn,
  IDatasetsLoadingParams,
} from "../../../../../types/interfaces/IDatasetsLoadingParams";
import { IGqlDatasetRow } from "../../../../../types/interfaces/IGqlDatasetRow";
import { IGqlUser, IGqlUserRole } from "../../../../../types/interfaces/IGqlUser";
import { getQueryStringFromParams } from "../../../../../utils/queryStringUtils";
import LegalFooter from "../../../common/LegalFooter/LegalFooter";
import DatasetsDeleteConfirmModal from "./DatasetsDeleteConfirmModal/DatasetsDeleteConfirmModal";
import DatasetsTable from "./DatasetsTable/ConnectedDatasetsTable";
import ProviderEditModal from "./ProviderEditModal/ConnectedProviderEditModal";
import SanitiziedStatusFilter from "./SanitizedStatusFilter/SanitizedStatusFilter";

const { Footer, Content } = Layout;

interface IProps {
  history: H.History;
  user: IGqlUser | null;
  queryParams: IDatasetsLoadingParams;
  datasets: IGqlDatasetRow[];
  isLoading: boolean;
  loadingError: string | null;
  onDeleteDatasets: (datasetIds: string[]) => Promise<void>;
  onSetIsEmployerEditModalVisible: (isVisible: boolean) => void;
  onRequestLoadDatasets: (params: IDatasetsLoadingParams) => void;
}

interface IState {
  deleteSingleDatasetId: string | null;
  isDatasetsDeleteConfirmModalVisible: boolean;
  editingProviderId: string | null;
  filters: Set<SanitizedStatus>;
}

class DatasetsListPage extends React.Component<IProps, IState> {
  public state: IState = {
    deleteSingleDatasetId: null,
    isDatasetsDeleteConfirmModalVisible: false,
    editingProviderId: null,
    filters: new Set(),
  };

  public async componentWillReceiveProps(nextProps: IProps) {
    if (
      nextProps.queryParams.filters &&
      this.props.queryParams.filters !== nextProps.queryParams.filters
    ) {
      const filters = new Set();
      _.forEach(
        nextProps.queryParams.filters[FilterableColumn.DECISION_SANITIZED_STATUS],
        (status: SanitizedStatus) => filters.add(status),
      );
      this.setState({ filters });
    }
  }

  public render() {
    const { history, user, datasets, isLoading, loadingError } = this.props;
    const datasetCount = datasets.length;
    const datasetsMap = datasets.reduce((map, dataset) => {
      return map.set(dataset.id, dataset);
    }, new Map());

    const onFilterChange = e => {
      const filters = this.state.filters;
      if (filters.has(e.target.value)) {
        filters.delete(e.target.value);
      } else {
        filters.add(e.target.value);
      }
      this.setState({ filters });
      this.props.queryParams.filters[FilterableColumn.DECISION_SANITIZED_STATUS] = Array.from(
        filters.values(),
      );
      history.push(`/datasets?${getQueryStringFromParams(this.props.queryParams)}`);
    };

    return (
      <section className="DatasetsListPage list-page">
        <Layout>
          <Content>
            <div className="page-content">
              <div className="page-title-container">
                <div className="page-title-container-left">
                  <div className="page-title">
                    Your Datasets {datasetCount ? ` (${datasetCount})` : ""}
                  </div>
                </div>
                <div className="page-title-container-right">
                  {user && user.role === IGqlUserRole.dataScout ? (
                    <SanitiziedStatusFilter
                      onFilterChange={onFilterChange}
                      filters={this.state.filters}
                      isLoading={this.props.isLoading}
                    />
                  ) : null}
                  <Button
                    className="add-dataset-button"
                    type="primary"
                    onClick={() => {
                      history.push("/dataset_submit");
                    }}
                  >
                    Add dataset
                  </Button>
                </div>
              </div>
              <div className={"companies-table"}>
                {loadingError && (
                  <Alert className="loading-error-banner" message={loadingError} type="error" />
                )}
                <DatasetsTable
                  history={history}
                  user={user}
                  datasets={datasets}
                  isLoading={isLoading}
                  loadingError={loadingError}
                  pageSize={25}
                  currentPage={0}
                  sorting={{}}
                  totalItemCount={10}
                  onChangePageSize={_.noop}
                  onChangeCurrentPage={_.noop}
                  onChangeSorting={_.noop}
                  onClickRow={record => {
                    history.push(`/dataset_details?id=${record.id}`);
                  }}
                  onClickEmployer={() => {
                    this.props.onSetIsEmployerEditModalVisible(true);
                  }}
                  onClickProvider={providerId => {
                    this.setState({ editingProviderId: providerId });
                  }}
                  onClickDeleteDataset={datasetId => {
                    this.setState({
                      deleteSingleDatasetId: datasetId,
                      isDatasetsDeleteConfirmModalVisible: true,
                    });
                  }}
                />
              </div>
            </div>
          </Content>
          <Footer style={{ padding: 0 }}>
            <LegalFooter />
          </Footer>
        </Layout>
        <DatasetsDeleteConfirmModal
          isVisible={this.state.isDatasetsDeleteConfirmModalVisible}
          datasets={
            this.state.deleteSingleDatasetId
              ? [datasetsMap.get(this.state.deleteSingleDatasetId)]
              : []
          }
          onConfirm={async () => {
            const datasetIds = this.state.deleteSingleDatasetId
              ? [this.state.deleteSingleDatasetId]
              : [];
            await this.props.onDeleteDatasets(datasetIds);
            this.setState({
              deleteSingleDatasetId: null,
              isDatasetsDeleteConfirmModalVisible: false,
            });
          }}
          onCancel={() => {
            this.setState({
              deleteSingleDatasetId: null,
              isDatasetsDeleteConfirmModalVisible: false,
            });
          }}
        />
        {this.state.editingProviderId && (
          <ProviderEditModal
            providerId={this.state.editingProviderId}
            onDone={() => {
              this.setState({
                editingProviderId: null,
              });
            }}
          />
        )}
      </section>
    );
  }
}

export default DatasetsListPage;
