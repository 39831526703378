import { Alert, Layout, Spin } from "antd";
import Divider from "antd/es/divider";
import Col from "antd/es/grid/col";
import Icon from "antd/es/icon";
import Row from "antd/lib/grid/row";
import * as H from "history";
import * as _ from "lodash";
import * as moment from "moment-timezone";
import * as React from "react";
import { spinnerDelay } from "../../../../constants/constants";
import "../../../../styles/dataset-details.less";
import SanitizedStatus from "../../../../types/enums/SanitizedStatus";
import { IDataset } from "../../../../types/interfaces/IDataset";
import { IGqlEmployer } from "../../../../types/interfaces/IGqlEmployer";
import { IGqlUser, IGqlUserRole } from "../../../../types/interfaces/IGqlUser";
import graphqlApi from "../../../../utils/graphqlApi/graphqlApi";
import { displayStatusTag } from "../../common/DatasetStatusTag/DatasetStatusTag";
import { generateLabelMap } from "../../common/form/FormTreeSelect/FormTreeSelect";
import LegalFooter from "../../common/LegalFooter/LegalFooter";
import WebsiteLink from "../../common/WebsiteLink/WebsiteLink";
import YourEmployerTag from "../../common/YourEmployerTag/YourEmployerTag";

const { Footer, Content } = Layout;

interface IProps {
  isLoading: boolean;
  loadingError: string | null;
  dataset: IDataset;
  employer: IGqlEmployer | null;
  history: H.History;
  user: IGqlUser | null;
}

interface IState {
  assetTreeDataLabelMap: Map<string, string>;
  categoryTreeDataLabelMap: Map<string, string>;
  geographyTreeDataLabelMap: Map<string, string>;
  industryTreeDataLabelMap: Map<string, string>;
  languagesLabelMap: Map<string, string>;
}

class DatasetDetailsPage extends React.Component<IProps, IState> {
  public state: IState = {
    assetTreeDataLabelMap: new Map(),
    categoryTreeDataLabelMap: new Map(),
    geographyTreeDataLabelMap: new Map(),
    industryTreeDataLabelMap: new Map(),
    languagesLabelMap: new Map(),
  };

  public async componentDidMount() {
    const { taxonomy } = await graphqlApi.getTaxonomy();
    const assetTreeData = taxonomy.asset;
    const categoryTreeData = taxonomy.category;
    const geographyTreeData = taxonomy.geography;
    const industryTreeData = taxonomy.industry;
    const languages = taxonomy.language;
    const assetTreeDataLabelMap = generateLabelMap(assetTreeData);
    const categoryTreeDataLabelMap = generateLabelMap(categoryTreeData);
    const geographyTreeDataLabelMap = generateLabelMap(geographyTreeData);
    const industryTreeDataLabelMap = generateLabelMap(industryTreeData);
    const languagesLabelMap = generateLabelMap(languages);
    this.setState({
      assetTreeDataLabelMap,
      categoryTreeDataLabelMap,
      geographyTreeDataLabelMap,
      industryTreeDataLabelMap,
      languagesLabelMap,
    });
  }

  public render() {
    const { isLoading, loadingError, dataset } = this.props;

    const formatDate = (dateTimeString: string): string => {
      return moment(dateTimeString).format("M/DD/YYYY");
    };

    const taxonomyDisplay = (
      labelMap: Map<string, string>,
      taxonomy: string[],
      delimiter: string,
    ) => {
      return _.join(_.map(taxonomy, (t: string) => labelMap.get(t)), delimiter);
    };

    return (
      <section className="DatasetDetailsPage dataset-details">
        <Layout>
          <Content>
            <Icon
              onClick={() => this.props.history.push(`/datasets`)}
              className="details-close"
              type="close"
            />
            <Spin
              spinning={isLoading || !_.isEmpty(loadingError)}
              indicator={loadingError ? <div /> : undefined}
              tip={loadingError ? "" : "Loading..."}
              delay={spinnerDelay}
            />
            {dataset ? (
              <div className="details">
                <div className="title-container">
                  <h1 className="title">{dataset.name}</h1>
                  {dataset.createdBy && (
                    <div className="author">Submitted by {dataset.createdBy.name}</div>
                  )}
                  <div className="update-time">Updated {formatDate(dataset.lastModifiedTime)}</div>
                  {loadingError && (
                    <Alert className="loading-error-banner" message={loadingError} type="error" />
                  )}
                </div>

                {dataset.createdBy && dataset.createdBy.role === IGqlUserRole.dataScout ? (
                  <div className="details-decision">
                    <Row className="details-row">
                      <Col span={10}>
                        <div className="heading">Status:</div>
                        <span>{displayStatusTag(dataset.decision)}</span>
                      </Col>

                      <Col span={14}>
                        <div className="heading">Comments:</div>
                        <span style={{ whiteSpace: "pre-line" }}>
                          {dataset.decision.externalComments}
                        </span>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <></>
                )}

                <Divider />

                <div className="details-content">
                  <Row className="details-row">
                    <Col span={4}>
                      <div className="heading">Submitted</div>
                      <span>{formatDate(dataset.createdTime)}</span>
                    </Col>
                    <Col span={6}>
                      <div className="heading">Provider</div>
                      <span>
                        {dataset.provider ? (
                          dataset.provider.name
                        ) : (
                          <>
                            {_.get(this.props.employer, "name")}
                            <YourEmployerTag />
                          </>
                        )}
                      </span>
                    </Col>
                    <Col span={10}>
                      <div className="heading">Website</div>
                      <span>{dataset.website ? <WebsiteLink url={dataset.website} /> : "N/A"}</span>
                    </Col>
                    <Col span={4}>
                      <div className="edit-dataset-btn">
                        {(this.props.user && this.props.user.role !== IGqlUserRole.dataScout) ||
                        dataset.decision.sanitizedStatus === SanitizedStatus.submitted ? (
                          <a
                            onClick={e => {
                              this.props.history.push(`/dataset_edit?id=${_.get(dataset, "id")}`);
                            }}
                          >
                            Edit Dataset
                          </a>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row className="details-row">
                    <Col span={4}>
                      <div className="heading">History</div>
                      <span>{dataset.historyLength}</span>
                    </Col>
                    <Col span={6}>
                      <div className="heading">Update frequency</div>
                      <span>{_.capitalize(dataset.updateFrequency)}</span>
                    </Col>
                    <Col span={14}>
                      <div className="heading">Languages</div>
                      <span>
                        {taxonomyDisplay(this.state.languagesLabelMap, dataset.languages, ", ")}
                      </span>
                    </Col>
                  </Row>

                  <Row className="details-row">
                    <Col span={24}>
                      <div className="heading">Description</div>
                      <span style={{ whiteSpace: "pre-line" }}>{dataset.description}</span>
                    </Col>
                  </Row>

                  <Row className="details-row">
                    <Col span={6}>
                      <div className="heading">Dataset categorization</div>
                      <span style={{ whiteSpace: "pre-line" }}>
                        {taxonomyDisplay(
                          this.state.categoryTreeDataLabelMap,
                          dataset.categories,
                          "\n",
                        )}
                      </span>
                    </Col>
                    <Col span={6}>
                      <div className="heading">Regions covered</div>
                      <span style={{ whiteSpace: "pre-line" }}>
                        {taxonomyDisplay(
                          this.state.geographyTreeDataLabelMap,
                          dataset.regions,
                          "\n",
                        )}
                      </span>
                    </Col>
                    <Col span={6}>
                      <div className="heading">Asset classes covered</div>
                      <span style={{ whiteSpace: "pre-line" }}>
                        {taxonomyDisplay(
                          this.state.assetTreeDataLabelMap,
                          dataset.assetClasses,
                          "\n",
                        )}
                      </span>
                    </Col>
                    <Col span={6}>
                      <div className="heading">Industries covered</div>
                      <span style={{ whiteSpace: "pre-line" }}>
                        {taxonomyDisplay(
                          this.state.industryTreeDataLabelMap,
                          dataset.industries,
                          "\n",
                        )}
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Content>
          <Footer style={{ padding: 0 }}>
            <LegalFooter />
          </Footer>
        </Layout>
      </section>
    );
  }
}

export default DatasetDetailsPage;
