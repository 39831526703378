import * as _ from "lodash";

const passwordMinLength = 12;

const validatePassword = password => {
  if (!_.isString(password)) {
    return ["Password is not a valid string"];
  }

  const ret: string[] = [];

  if (!/[0-9]/.test(password)) {
    ret.push("Password must contain a number");
  }

  if (!/[A-Z]/.test(password)) {
    ret.push("Password must contain an upper case letter");
  }

  if (!/[a-z]/.test(password)) {
    ret.push("Password must contain a lower case letter");
  }

  // if (!/[!|@|#|$|%|^|&|*|(|)|-|_]/.test(password)) {
  //   ret.push("Password must contain a special letter: !@#$%^&*()-_");
  // }

  if (password.length < passwordMinLength) {
    ret.push("Password must contain a least 12 characters");
  }

  return ret;
};

export default validatePassword;
