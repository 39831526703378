import { Alert, Button, Modal } from "antd";
import * as _ from "lodash";
import * as React from "react";

import "../../../../../../styles/alert-modal.less";
import { IDataset } from "../../../../../../types/interfaces/IDataset";
import "./DatasetsDeleteConfirmModal.less";

interface IProps {
  datasets: IDataset[];
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
}

interface IState {
  isProcessing: boolean;
  processingError: string | null;
}

class DatasetsDeleteConfirmModal extends React.Component<IProps, IState> {
  public state: IState = {
    isProcessing: false,
    processingError: null,
  };

  public render() {
    const { isVisible, onCancel, datasets } = this.props;
    const { isProcessing, processingError } = this.state;
    const subject = _.size(datasets) > 1 ? `datasets` : `dataset`;
    return (
      <Modal
        width={710}
        className="DatasetsDeleteConfirmModal alert-modal"
        title={`Delete ${subject}`}
        visible={isVisible}
        onOk={this.handleConfirmRequest}
        onCancel={onCancel}
        footer={[
          <Button
            key="submit"
            className="primary-button"
            type="primary"
            loading={isProcessing}
            onClick={this.handleConfirmRequest}
            disabled={isProcessing}
          >
            {"Delete " + subject}
          </Button>,
          <Button
            key="cancel"
            className="secondary-button"
            onClick={onCancel}
            disabled={isProcessing}
          >
            Cancel
          </Button>,
        ]}
      >
        {isVisible && (
          <div className="modal-content">
            {_.size(this.props.datasets) === 1 ? (
              <span>Are you sure you want to delete dataset “{datasets[0].name}”</span>
            ) : (
              <div className="modal-content">
                Are you sure you want to delete these {_.size(datasets)} datasets:
                <ul>
                  {_.map(datasets, d => (
                    <li key={d.id}>{d.name}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="alert-modal-cannot-undone-warning">This cannot be undone.</div>
            {processingError && (
              <Alert
                type="error"
                className="processing-error-banner"
                message={`Failed to delete dataset. ${processingError}`}
              />
            )}
          </div>
        )}
      </Modal>
    );
  }

  private handleConfirmRequest = async () => {
    this.setState({ isProcessing: true });
    try {
      await this.props.onConfirm();
    } catch (error) {
      this.setState({ processingError: error.message });
    }
    this.setState({ isProcessing: false });
  };
}

export default DatasetsDeleteConfirmModal;
