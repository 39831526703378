import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import actionCreators from "../../../../../../actions/actionCreators";
import { IState as IStoreState } from "../../../../../../reducers/root";
import SubmitEmailStepContainer, { IDispatchProps, IStateProps } from "./SubmitEmailStepContainer";

interface IOwnProps extends RouteComponentProps<any> {}

const ConnectedSubmitEmailStep = connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(
  (state: IStoreState, ownProps: IOwnProps) => ({
    history: ownProps.history,
  }),
  (dispatch: any) => ({
    onSubmit: async (email: string) => {
      await dispatch(actionCreators.sendResetPasswordMail(email));
    },
  }),
)(SubmitEmailStepContainer);

export default withRouter(ConnectedSubmitEmailStep);
