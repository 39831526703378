import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import actionTypes from "../actionTypes";

const fetchDatasetThunk = datasetId => async (dispatch, getState) => {
  dispatch({ type: actionTypes.FETCH_DATASET_REQUEST });
  try {
    const dataset = await graphqlApi.getDataset(datasetId);
    dispatch({
      type: actionTypes.FETCH_DATASET_SUCCESS,
      dataset,
    });
    return dataset;
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.FETCH_DATASET_FAILURE,
      error: error.message,
    });
    throw error;
  }
};

export default fetchDatasetThunk;
