import gql from "graphql-tag";
import * as _ from "lodash";
import { IGqlDatasetRow } from "../../../types/interfaces/IGqlDatasetRow";
import apolloClient from "../../apolloClient";

const GET_DATASETS = gql`
  query Datasets {
    datasets {
      id
      name
      provider {
        id
        name
      }
      createdBy {
        id
        name
      }
      createdTime
      decision {
        id
        sanitizedStatus
        isPaymentProcessed
        externalComments
      }
    }
  }
`;

const getDatasets = async (): Promise<IGqlDatasetRow[]> => {
  const ret: any = await apolloClient
    .query({
      query: GET_DATASETS,
      variables: {},
      fetchPolicy: "no-cache",
    })
    .catch(error => {
      throw new Error(error.graphQLErrors.join(",") || `Failed to get datasets.`);
    });

  if (!_.isEmpty(ret.errors) || !_.has(ret, "data.datasets")) {
    throw new Error((ret.errors as any[]).map(it => `${it.message}`).join("\n"));
  }
  return ret.data.datasets;
};

export default getDatasets;
