import { Form } from "antd";
import * as React from "react";
import ReactJson from "react-json-view";

const makeFormField = Component => ({
  input,
  meta,
  children,
  hasFeedback,
  label,
  required,
  isDebugging,
  layout,
  description,
  ...rest
}) => {
  const hasError = meta.touched && meta.invalid;
  return (
    <Form.Item
      label={label}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
      {...layout}
    >
      {description && <div className="form-field-description">{description}</div>}
      <Component {...input} {...rest} children={children} meta={meta} />
      {isDebugging && <ReactJson name="meta" src={meta} />}
    </Form.Item>
  );
};

export default makeFormField;
