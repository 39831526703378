import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import actionTypes from "../actionTypes";

const fetchProvidersThunk = () => async dispatch => {
  dispatch({ type: actionTypes.FETCH_PROVIDERS_REQUEST });
  try {
    const providers = await graphqlApi.getProviders();
    dispatch({
      type: actionTypes.FETCH_PROVIDERS_SUCCESS,
      providers,
    });
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.FETCH_PROVIDERS_FAILURE,
      error: error.message,
    });
    throw error;
  }
};

export default fetchProvidersThunk;
