import { Alert, Button, Modal, Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { spinnerDelay } from "../../../../../../constants/constants";
import "../../../../../../styles/edit-delete-modal.less";
import { IProviderInput } from "../../../../../../types/interfaces/IProviderInput";
import ProviderSubmitForm, {
  formName as name,
  initialValues,
} from "../../../../common/ProviderSubmitForm/ProviderSubmitForm";

const ProviderEditModal = ({
  isVisible,
  onClickSaveButton,
  onSave,
  onClickDeleteButton,
  onCancel,
  isInitializing,
  initializationError,
  isSaving,
  isDeleting,
  deletionError,
  canDelete,
}: {
  isVisible: boolean;
  onClickSaveButton: () => Promise<void>;
  onSave: (values: IProviderInput) => Promise<void>;
  onClickDeleteButton: () => Promise<void>;
  onCancel: () => void;
  isInitializing: boolean;
  initializationError: string | null;
  isSaving: boolean;
  isDeleting: boolean;
  deletionError: string | null;
  canDelete: boolean;
}) => {
  return (
    <Modal
      width={710}
      className="ProviderEditModal edit-delete-modal"
      title={`Edit a provider`}
      visible={isVisible}
      onOk={onClickSaveButton}
      onCancel={onCancel}
      footer={[
        <Button
          key="save"
          className="primary-button"
          type="primary"
          loading={isSaving}
          onClick={onClickSaveButton}
          disabled={isSaving || isDeleting || isInitializing || !_.isEmpty(initializationError)}
        >
          Save changes
        </Button>,
        <Button key="cancel" className="cancel-button" onClick={onCancel} disabled={isSaving}>
          Cancel
        </Button>,
        canDelete ? (
          <Button
            key="delete"
            className="delete-button"
            onClick={onClickDeleteButton}
            disabled={isSaving || isDeleting}
            loading={isDeleting}
          >
            Delete provider
          </Button>
        ) : null,
      ]}
    >
      {initializationError && (
        <Alert className="initialization-error-banner" message={initializationError} type="error" />
      )}
      <Spin
        spinning={isInitializing || !_.isEmpty(initializationError)}
        indicator={initializationError ? <div /> : undefined}
        tip={initializationError ? "" : "Initializing..."}
        delay={spinnerDelay}
      >
        <ProviderSubmitForm initialValues={initialValues} onSubmit={onSave} />
      </Spin>
      {deletionError && (
        <Alert className="initialization-error-banner" message={deletionError} type="error" />
      )}
    </Modal>
  );
};

export const formName = name;
export const formInitialValues = initialValues;
export default ProviderEditModal;
