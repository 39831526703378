import { Spin } from "antd";
import * as React from "react";
import delayDuration from "../../../../../../constants/spinnerDelay";
import ResetPasswordForm from "./ResetPasswordForm/ResetPasswordForm";

const initialValues = {
  code: "",
  newPassword: "",
  newPassword2: "",
};

const ResetPasswordStep = ({
  isSubmitting,
  onSubmit,
}: {
  isSubmitting: boolean;
  onSubmit: (code: string, newPassword: string) => Promise<void>;
}) => (
  <div>
    <div className="title-container">
      <div className="title">Forgot your password?</div>
      <div className="subtitle">
        We have sent a password reset code by email. Enter it below to reset your password.
      </div>
    </div>
    <Spin tip={"Updating password..."} delay={delayDuration} spinning={isSubmitting}>
      <ResetPasswordForm
        initialValues={initialValues}
        onSubmit={async values => {
          await onSubmit(values.code, values.newPassword);
        }}
      />
    </Spin>
  </div>
);

export default ResetPasswordStep;
