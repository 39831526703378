import actionTypes from "../../actions/actionTypes";
import { IGqlEmployer } from "../../types/interfaces/IGqlEmployer";

export interface IState {
  employer: IGqlEmployer | null;
}

const initialState: IState = {
  employer: null,
};

export default function(state: IState = initialState, action): IState {
  switch (action.type) {
    case actionTypes.FETCH_EMPLOYER_SUCCESS:
    case actionTypes.UPDATE_EMPLOYER_SUCCESS: {
      return {
        ...state,
        employer: action.employer,
      };
    }
    case actionTypes.SIGN_OUT_SUCCESS: {
      return {
        ...state,
        employer: null,
      };
    }
    default:
      return state;
  }
}

export const getEmployer = (state: IState) => state.employer;
export const getEmployerId = (state: IState) =>
  state.employer !== null ? state.employer.id : null;
