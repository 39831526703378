import * as React from "react";
import "../../../../styles/edit-modal.less";
import TermsOfServiceBody from "../../common/TermsOfServiceBody/TermsOfServiceBody";
import TermsOfServiceProvider from "../../common/TermsOfServiceProvider/TermsOfServiceProvider";
import "./TermsOfServicePage.less";

const TermsOfServicePage = () => (
  <div style={{ width: "1074px", margin: "0 auto" }} className="TermsOfServicePage">
    <TermsOfServiceProvider>
      {props => <TermsOfServiceBody isVisible={true} onAccept={null} {...props} />}
    </TermsOfServiceProvider>
  </div>
);

export default TermsOfServicePage;
