import { Alert, Spin } from "antd";
import * as React from "react";
import { spinnerDelay } from "../../../../../../constants/constants";
import VerificationForm, { formName } from "./VerificationForm/VerificationForm";

const VerifyStep = ({
  onSubmit,
  onResendCode,
  isResendingVerificationCode,
  resendingVerificationCodeError,
}: {
  onSubmit: (verificationCode: string) => Promise<void>;
  onResendCode: () => Promise<void>;
  isResendingVerificationCode: boolean;
  resendingVerificationCodeError: string | null;
}) => {
  return (
    <div className="VerifyStep">
      <div className="title-container">
        <div className="title">Verify your account</div>
        <div className="subtitle">Enter the verification code we sent to your email.</div>
      </div>
      {resendingVerificationCodeError && (
        <Alert
          className="loading-error-banner"
          message={resendingVerificationCodeError}
          type="error"
          style={{ width: "467px", margin: "0 auto 20px" }}
        />
      )}
      <Spin
        spinning={isResendingVerificationCode}
        tip="Resending verification code..."
        delay={spinnerDelay}
      >
        <VerificationForm
          initialValues={{ code: "" }}
          onSubmit={async values => {
            await onSubmit(values.code);
          }}
        />
        <p className="tip">
          Didn't receive a code? <a onClick={onResendCode}>Resend it</a>
        </p>
      </Spin>
    </div>
  );
};

export const form = formName;

export default VerifyStep;
