import { Alert, Button, Spin } from "antd";
import { Map as ImmutableMap } from "immutable";
import * as _ from "lodash";
import * as React from "react";
import { Link } from "react-router-dom";
import spinnerDelay from "../../../../constants/spinnerDelay";
import IAgreeCheckbox from "./IAgreeCheckbox/IAgreeCheckbox";

interface IProps {
  isVisible: boolean;
  isLoading: boolean;
  loadingError: string | null;
  tos: any;
  onAccept: ((version: number) => Promise<void>) | null;
}

interface IState {
  isSaving: boolean;
  savingError: string | null;
  isAgreeCheckboxChecked: ImmutableMap<string, boolean>;
}

const Linkify = ({ children }) => {
  const linkMap = {
    "Privacy Policy": (
      <Link to="/privacy_policy" target="_blank">
        Privacy Policy
      </Link>
    ),
    "Cookie Policy": (
      <Link to="/cookie_policy" target="_blank">
        Cookie Policy
      </Link>
    ),
    "DataDiscovery@twosigma.com": (
      <a href="mailto:DataDiscovery@twosigma.com">DataDiscovery@twosigma.com</a>
    ),
  };
  if (_.isString(children)) {
    const tokens = children.split(new RegExp(`\\b(${_.keys(linkMap).join("|")})\\b`, "g"));
    if (tokens.length !== 1) {
      return _.map(tokens, (token, i) => (
        <span key={i}>{_.has(linkMap, token) ? linkMap[token] : token}</span>
      ));
    }
  }
  return children;
};

class TermsOfServiceBody extends React.Component<IProps, IState> {
  public state: IState = {
    isSaving: false,
    savingError: null,
    isAgreeCheckboxChecked: ImmutableMap({}),
  };

  private anchors: Map<string, Element> = new Map<string, Element>();

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    if (!prevProps.isVisible && this.props.isVisible) {
      this.setState({ isAgreeCheckboxChecked: ImmutableMap({}) }, () => {
        this.scrollToTop();
      });
    }
  }

  public render() {
    const { savingError } = this.state;
    const { isLoading, loadingError, tos, onAccept } = this.props;
    const isReadOnly = onAccept === null;
    this.anchors = new Map<string, Element>();

    let agreeCheckboxIndex = 0;
    const renderCheckbox = label => {
      const checkboxId = `agree-${agreeCheckboxIndex++}`;
      return (
        <IAgreeCheckbox
          isChecked={this.state.isAgreeCheckboxChecked.get(checkboxId)}
          label={label}
          onChange={({ target: { checked } }) => {
            this.handleChangeCheckbox(checkboxId, checked);
          }}
          ref={element => {
            if (element) {
              this.anchors.set(checkboxId, element);
            }
          }}
        />
      );
    };
    return (
      <div>
        <Spin spinning={isLoading} delay={spinnerDelay}>
          {!_.isEmpty(loadingError) && (
            <Alert className="loading-error-banner" message={loadingError} type="error" />
          )}
          <div className="TermsOfServiceBody">
            <div
              ref={element => {
                if (element) {
                  this.anchors.set("top", element);
                }
              }}
            />
            {tos &&
              _.map(tos.content, (it, i) => {
                if (it.type === "title") {
                  return (
                    <div key={i} className="title">
                      {it.text}
                    </div>
                  );
                }
                if (it.type === "content") {
                  return (
                    <p key={i} className="content">
                      <Linkify>{it.text}</Linkify>
                      {it.checkbox && !isReadOnly && renderCheckbox(it.checkbox.label)}
                    </p>
                  );
                }
                if (it.type === "indented_content") {
                  return (
                    <p key={i} className="indented-text">
                      <Linkify>{it.text}</Linkify>
                      {it.checkbox && !isReadOnly && renderCheckbox(it.checkbox.label)}
                    </p>
                  );
                }
                if (it.type === "ordered_list") {
                  return (
                    <ol key={i} className="l2">
                      {_.map(it.items, (item, index) => {
                        return (
                          <li key={index}>
                            {item.subject && <strong>{item.subject}</strong>}{" "}
                            {<Linkify>{item.text}</Linkify>}{" "}
                            {item.footer && <strong>{item.footer}</strong>}
                            {item.items && (
                              <ol className="l3">
                                {_.map(item.items, (subitem, si) => (
                                  <li key={si}>
                                    <Linkify>{subitem.text}</Linkify>
                                  </li>
                                ))}
                              </ol>
                            )}
                            {item.checkbox && !isReadOnly && renderCheckbox(item.checkbox.label)}
                          </li>
                        );
                      })}
                    </ol>
                  );
                }
                return null;
              })}
          </div>
        </Spin>

        {!_.isEmpty(savingError) && (
          <Alert className="loading-error-banner" message={savingError} type="error" />
        )}
        {!isReadOnly && (
          <Button
            key="accept"
            className="primary-button"
            type="primary"
            loading={this.state.isSaving}
            onClick={() => {
              for (let j = 0; j < agreeCheckboxIndex; ++j) {
                const checkboxId = `agree-${j}`;
                if (!this.state.isAgreeCheckboxChecked.get(checkboxId)) {
                  (this.anchors.get(checkboxId) as any).scrollIntoView({
                    block: j === agreeCheckboxIndex - 1 ? "end" : "center",
                    behavior: "smooth",
                  });
                  return;
                }
              }
              this.handleAccept(tos.version);
            }}
            disabled={this.state.isSaving}
          >
            Accept
          </Button>
        )}
      </div>
    );
  }

  private scrollToTop() {
    (this.anchors.get("top") as any).scrollIntoView({
      block: "start",
      behavior: "instant",
    });
  }

  private handleChangeCheckbox = (checkboxId, checked) => {
    this.setState({
      isAgreeCheckboxChecked: this.state.isAgreeCheckboxChecked.set(checkboxId, checked),
    });
  };

  private handleAccept = async version => {
    if (!this.props.onAccept) {
      return;
    }
    this.setState({ isSaving: true });
    try {
      await this.props.onAccept(version);
    } catch (error) {
      this.setState({ savingError: error.message });
    }
    this.setState({ isSaving: false });
  };
}

export default TermsOfServiceBody;
