import { Alert, Button, Col, Form, Row, Select, Spin, Tooltip, TreeSelect } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Element, scroller } from "react-scroll";
import { Field, reduxForm } from "redux-form";
import DatasetUpdateFrequency from "../../../../types/enums/DatasetUpdateFrequency";
import { IFileUpload } from "../../../../types/interfaces/IFileUpload";
import { ITaxonomyItem } from "../../../../types/interfaces/ITaxonomyItem";
import { FILE_ATTACHMENTS_ENDPOINT, SERVICE_BASE_URL } from "../../../../utils/config";
import graphqlApi from "../../../../utils/graphqlApi/graphqlApi";
import sessionStore from "../../../../utils/sessionStore";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import FormDropdownSelect from "../form/FormDropdownSelect/FormDropdownSelect";
import FormInput from "../form/FormInput/FormInput";
import FormTextArea from "../form/FormTextArea/FormTextArea";
import FormTreeSelect, { generateLabelMap } from "../form/FormTreeSelect/FormTreeSelect";
import InfoTooltip from "../InfoTooltip/InfoTooltip";
import "./DatasetSubmitForm.less";
import FormFilesUpload from "./FormFilesUpload/FormFilesUpload";
import FormItemProviderSelect from "./FormItemProviderSelect/FormItemProviderSelect";
import FormUpdateFrequencyInput from "./FormUpdateFreqencyInput/FormUpdateFreqencyInput";
import SubmitButton from "./SubmitButton/SubmitButton";

const FILE_ATTACHMENTS_COUNT_LIMIT = 10;
const FILE_ATTACHMENTS_TOTAL_SIZE_LIMIT = 50 * 1000 * 1000;
const FILE_ATTACHMENTS_ACCEPTABLE_TYPES = {
  txt: "text/plain",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  pdf: "application/pdf",
  gif: "image/gif",
  jpg: "image/jpeg",
  png: "image/png",
};

interface IProps {
  handleSubmit: any;
  error: any;
  submitting: boolean;
  invalid: boolean;
  onCancel: () => void;
}

interface IState {
  assetTreeData: ITaxonomyItem[];
  categoryTreeData: ITaxonomyItem[];
  geographyTreeData: ITaxonomyItem[];
  industryTreeData: ITaxonomyItem[];
  languages: ITaxonomyItem[];
  assetTreeDataLabelMap: Map<string, string>;
  categoryTreeDataLabelMap: Map<string, string>;
  geographyTreeDataLabelMap: Map<string, string>;
  industryTreeDataLabelMap: Map<string, string>;
  languagesLabelMap: Map<string, string>;
  idToken: string;
}

const FormLabel = ({ text, tooltip }: { text: string; tooltip?: string }) => (
  <span className="DatasetSubmitForm-label">
    {text}
    {!_.isEmpty(tooltip) && (
      <span>
        {" "}
        <InfoTooltip text={tooltip} />
      </span>
    )}
  </span>
);

function renderTreeNode(nodeData) {
  return (
    <TreeSelect.TreeNode
      key={nodeData.id}
      value={nodeData.id}
      title={
        _.isEmpty(nodeData.definition) ? (
          nodeData.label
        ) : (
          <Tooltip placement="right" title={nodeData.definition}>
            {nodeData.label}
          </Tooltip>
        )
      }
    >
      {!_.isEmpty(nodeData.children) && _.map(nodeData.children, renderTreeNode)}
    </TreeSelect.TreeNode>
  );
}

class DatasetSubmitForm extends React.Component<IProps, IState> {
  public state: IState = {
    assetTreeData: [],
    categoryTreeData: [],
    geographyTreeData: [],
    industryTreeData: [],
    languages: [],
    assetTreeDataLabelMap: new Map(),
    categoryTreeDataLabelMap: new Map(),
    geographyTreeDataLabelMap: new Map(),
    industryTreeDataLabelMap: new Map(),
    languagesLabelMap: new Map(),
    idToken: "",
  };

  public async componentDidMount() {
    const { taxonomy } = await graphqlApi.getTaxonomy();
    const assetTreeData = taxonomy.asset;
    const categoryTreeData = taxonomy.category;
    const geographyTreeData = taxonomy.geography;
    const industryTreeData = taxonomy.industry;
    const languages = taxonomy.language;
    const assetTreeDataLabelMap = generateLabelMap(assetTreeData);
    const categoryTreeDataLabelMap = generateLabelMap(categoryTreeData);
    const geographyTreeDataLabelMap = generateLabelMap(geographyTreeData);
    const industryTreeDataLabelMap = generateLabelMap(industryTreeData);
    const languagesLabelMap = generateLabelMap(languages);
    this.setState({
      assetTreeData,
      categoryTreeData,
      geographyTreeData,
      industryTreeData,
      languages,
      assetTreeDataLabelMap,
      categoryTreeDataLabelMap,
      geographyTreeDataLabelMap,
      industryTreeDataLabelMap,
      languagesLabelMap,
    });
    sessionStore.getIdToken().then(idToken => {
      this.setState({ idToken });
    });
  }

  public render() {
    const { handleSubmit, error, submitting } = this.props;
    const {
      assetTreeData,
      categoryTreeData,
      geographyTreeData,
      industryTreeData,
      languages,
      assetTreeDataLabelMap,
      categoryTreeDataLabelMap,
      geographyTreeDataLabelMap,
      industryTreeDataLabelMap,
      languagesLabelMap,
    } = this.state;
    return (
      <div className="DatasetSubmitForm">
        <ErrorBoundary messagePrefix="Page Error: ">
          <Form onSubmit={handleSubmit}>
            <Spin spinning={submitting} tip={"Saving..."}>
              <Row className="form-row">
                <Col lg={24} md={24} sm={24}>
                  <Element name={"position-providerId"}>
                    <Field
                      label={<FormLabel text="Provider name" />}
                      name="providerId"
                      component={FormItemProviderSelect}
                      required={true}
                      placeholder="Dataset provider"
                    />
                  </Element>
                </Col>
              </Row>
              <Row className="form-row">
                <Col lg={24} md={24} sm={24}>
                  <Element name={"position-name"}>
                    <Field
                      label={<FormLabel text="Dataset name" />}
                      name="name"
                      component={FormInput}
                      required={true}
                      placeholder="Dataset name"
                    />
                  </Element>
                </Col>
              </Row>
              <Row className="form-row">
                <Col lg={24} md={24} sm={24}>
                  <Element name={"position-website"}>
                    <Field
                      label={
                        <FormLabel
                          text="Dataset website"
                          tooltip="Link to a website that describes this dataset"
                        />
                      }
                      name="website"
                      component={FormInput}
                      placeholder="Dataset website"
                    />
                  </Element>
                </Col>
              </Row>
              <Row className="form-row">
                <Col lg={24} md={24} sm={24}>
                  <Element name={"position-updateFrequency"}>
                    <Field
                      label={
                        <FormLabel
                          text="Update frequency"
                          tooltip="How often is the parent source data updated? This is the actual frequency of the timestamps in the dataset."
                        />
                      }
                      name="updateFrequency"
                      component={FormUpdateFrequencyInput}
                      required={true}
                    />
                  </Element>
                </Col>
              </Row>
              <Row className="form-row">
                <Col lg={24} md={24} sm={24}>
                  <Element name={"position-historyLength"}>
                    <Field
                      label="History length"
                      name="historyLength"
                      placeholder="How far back does this dataset go?"
                      required={true}
                      component={FormDropdownSelect}
                      showSearch
                    >
                      {_.map(
                        [-1, ..._.range(1900, new Date().getFullYear() + 1).reverse()],
                        year => (
                          <Select.Option key={`${year}`} value={year}>
                            {year === -1 ? "Unknown" : year}
                          </Select.Option>
                        ),
                      )}
                    </Field>
                  </Element>
                </Col>
              </Row>
              <Row className="form-row">
                <Col lg={24} md={24} sm={24}>
                  <Element name={"position-categories"}>
                    <Field
                      name="categories"
                      label="Categories"
                      description="Choose the category or categories that best describe this dataset."
                      placeholder="Select or search categories"
                      searchPlaceholder="AAAABBBCCC"
                      component={FormTreeSelect}
                      style={{ width: "100%" }}
                      required={true}
                      labelMap={categoryTreeDataLabelMap}
                    >
                      {_.map(categoryTreeData, renderTreeNode)}
                    </Field>
                  </Element>
                </Col>
              </Row>
              <Row className="form-row">
                <Col lg={24} md={24} sm={24}>
                  <Element name={"position-assetClasses"}>
                    <Field
                      name="assetClasses"
                      label="Asset classes covered"
                      description="Choose asset classes only if the dataset contains references to tradeable instruments."
                      placeholder="Select or search asset classes covered"
                      component={FormTreeSelect}
                      style={{ width: "100%" }}
                      required={true}
                      labelMap={assetTreeDataLabelMap}
                    >
                      {_.map(assetTreeData, renderTreeNode)}
                    </Field>
                  </Element>
                </Col>
              </Row>
              <Row className="form-row">
                <Col lg={24} md={24} sm={24}>
                  <Element name={"position-regions"}>
                    <Field
                      label="Regions"
                      description="Choose the geographies that are covered in this dataset. You can expand a region to choose specific countries."
                      placeholder="Select or search regions"
                      name="regions"
                      style={{ width: "100%" }}
                      component={FormTreeSelect}
                      required={true}
                      labelMap={geographyTreeDataLabelMap}
                    >
                      {_.map(geographyTreeData, renderTreeNode)}
                    </Field>
                  </Element>
                </Col>
              </Row>
              <Row className="form-row">
                <Col lg={24} md={24} sm={24}>
                  <Element name={"position-industries"}>
                    <Field
                      label="Industries"
                      description="Choose the industries whose activity this dataset describes."
                      placeholder="Select or search industries"
                      name="industries"
                      style={{ width: "100%" }}
                      component={FormTreeSelect}
                      required={true}
                      labelMap={industryTreeDataLabelMap}
                    >
                      {_.map(industryTreeData, renderTreeNode)}
                    </Field>
                  </Element>
                </Col>
              </Row>
              <Row className="form-row">
                <Col lg={24} md={24} sm={24}>
                  <Element name={"position-languages"}>
                    <Field
                      label="Languages"
                      name="languages"
                      description="What languages are used in this dataset?"
                      placeholder="Select or search languages"
                      style={{ width: "100%" }}
                      component={FormTreeSelect}
                      labelMap={languagesLabelMap}
                      required={true}
                    >
                      {_.map(languages, renderTreeNode)}
                    </Field>
                  </Element>
                </Col>
              </Row>
              <Row className="form-row form-row-last">
                <Col lg={24} md={24} sm={24}>
                  <Field
                    label={
                      <FormLabel
                        text="Description"
                        tooltip="What else should we know about this dataset?"
                      />
                    }
                    name="description"
                    component={FormTextArea}
                    required={true}
                    rows={6}
                  />
                </Col>
              </Row>
              <Row className="form-row form-row-last">
                <Col lg={24} md={24} sm={24}>
                  <Element name={"position-attachments"}>
                    <Field
                      label={<FormLabel text="Attachments" />}
                      description={
                        <span className="form-field-description-important">
                          Upload documentation but <b>do not upload trial data.</b>
                        </span>
                      }
                      name="fileAttachments"
                      component={FormFilesUpload}
                      rows={6}
                      uploadUrl={`${SERVICE_BASE_URL}${FILE_ATTACHMENTS_ENDPOINT}`}
                      uploadHeaders={{
                        authorization: !_.isEmpty(this.state.idToken)
                          ? `Bearer ${this.state.idToken}`
                          : "",
                      }}
                      fileCountLimit={FILE_ATTACHMENTS_COUNT_LIMIT}
                      totalSizeLimit={FILE_ATTACHMENTS_TOTAL_SIZE_LIMIT}
                      acceptedFileTypes={FILE_ATTACHMENTS_ACCEPTABLE_TYPES}
                    />
                  </Element>
                </Col>
              </Row>
            </Spin>
            {error && <Alert className="submission-error-banner" message={error} type="error" />}
            <Row className="button-row">
              <SubmitButton isSubmitting={submitting} />
            </Row>
            <Row className="button-row">
              <Button
                key="cancel"
                className="cancel-button"
                onClick={this.props.onCancel}
                disabled={submitting}
              >
                Cancel
              </Button>
            </Row>
          </Form>
        </ErrorBoundary>
      </div>
    );
  }
}

const validate = values => {
  const errors = {} as any;
  if (values.providerId === null) {
    errors.providerId = "Please select a provider";
  }
  if (_.isEmpty(_.trim(values.name))) {
    errors.name = "Dataset name is required";
  }
  if (_.isEmpty(values.updateFrequency)) {
    errors.updateFrequency = "Please specify a valid update frequency";
  }
  if (values.historyLength === null) {
    errors.historyLength = "Please specify a valid history length";
  }
  if (_.isEmpty(values.categories)) {
    errors.categories = "Please choose at least one category";
  }
  if (_.isEmpty(values.regions)) {
    errors.regions = "Please choose at least one region";
  }
  if (_.isEmpty(values.industries)) {
    errors.industries = "Please choose at least one industry";
  }
  if (_.isEmpty(values.languages)) {
    errors.languages = "Please choose at least one language";
  }
  if (_.isEmpty(_.trim(values.description))) {
    errors.description = "Please enter description";
  }
  if (_.some(values.fileAttachments, { status: "uploading" })) {
    errors.fileAttachments =
      "A file upload is in progress. To submit, cancel the upload or wait for it to complete";
  }
  return errors;
};

const scrollToFirstError = errors => {
  const fields = [
    "providerId",
    "name",
    "updateFrequency",
    "historyLength",
    "categories",
    "regions",
    "industries",
    "languages",
    "description",
    "fileAttachments",
  ];
  for (const field of fields) {
    const fieldName = `position-${field}`;
    if (!_.isEmpty(errors[field]) && document.querySelectorAll(`[name="${fieldName}"]`).length) {
      scroller.scrollTo(fieldName, { smooth: true, duration: 800, delay: 800 });
      break;
    }
  }
};

export interface IDatasetSubmitFormData {
  providerId: string | null;
  name: string;
  website: string;
  updateFrequency: DatasetUpdateFrequency | null;
  historyLength: number | null;
  categories: string[];
  regions: string[];
  assetClasses: string[];
  industries: string[];
  languages: string[];
  description: string;
  fileAttachments: IFileUpload[];
}

export const defaultValues: IDatasetSubmitFormData = {
  providerId: null,
  name: "",
  website: "",
  updateFrequency: null,
  historyLength: null,
  regions: [],
  languages: [],
  description: "",
  categories: [],
  assetClasses: [],
  industries: [],
  fileAttachments: [],
};

export const datasetSubmitFormName = "DatasetSubmitForm";

export default reduxForm({
  form: datasetSubmitFormName,
  validate,
  touchOnChange: true,
  onSubmitFail: errors => scrollToFirstError(errors),
})(DatasetSubmitForm);
