import gql from "graphql-tag";
import * as _ from "lodash";
import { IGqlProvider } from "../../../types/interfaces/IGqlProvider";
import apolloClient from "../../apolloClient";

const GET_PROVIDERS = gql`
  query Providers {
    providers {
      id
      name
      address
      contactName
      contactEmail
      contactPhone
      description
    }
  }
`;

const getProviders = async (): Promise<IGqlProvider[]> => {
  const ret: any = await apolloClient
    .query({
      query: GET_PROVIDERS,
      variables: {},
      fetchPolicy: "no-cache",
    })
    .catch(error => {
      throw new Error(error.graphQLErrors.join(",") || `Failed to get providers.`);
    });
  if (!_.isEmpty(ret.errors) || !_.has(ret, "data.providers")) {
    throw new Error(`Failed to get dataset: ${_.map(ret.errors, "message").join(", ")}`);
  }
  return ret.data.providers;
};

export default getProviders;
