import { Alert, Button, Col, Form, Row } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Field, reduxForm } from "redux-form";
import FormInput from "../../../../../common/form/FormInput/FormInput";

interface IProps {
  handleSubmit: any;
  error: any;
  submitting: boolean;
  invalid: boolean;
}

class ForgotPasswordForm extends React.Component<IProps, {}> {
  public render() {
    const { handleSubmit, error, submitting, invalid } = this.props;
    return (
      <div className="ForgotPasswordForm">
        <Form onSubmit={handleSubmit}>
          <Row className="form-item-row">
            <Col lg={24} md={24} sm={24}>
              <Field
                label={"email"}
                name="email"
                component={FormInput}
                placeholder=""
                required={true}
              />
            </Col>
          </Row>
          {error && <Alert className="submission-error-banner" message={error} type="error" />}
          <Button
            className="primary-button"
            htmlType="submit"
            type="primary"
            disabled={submitting || invalid}
          >
            Reset my password
          </Button>
        </Form>
      </div>
    );
  }
}

const validate = values => {
  const errors = {} as any;
  if (_.isEmpty(_.trim(values.email))) {
    errors.email = "Email is required";
  }
  if (!_.includes(values.email, "@")) {
    errors.email = "Not a valid email";
  }
  return errors;
};

export default reduxForm({
  form: "ForgotPasswordForm",
  validate,
})(ForgotPasswordForm);
