import Auth from "@aws-amplify/auth";
import * as _ from "lodash";

import actionTypes from "../actionTypes";

const resetPasswordThunk = (
  username: string,
  code: string,
  newPassword: string,
) => async dispatch => {
  dispatch({ type: actionTypes.RESET_PASSWORD_REQUEST });
  try {
    await Auth.forgotPasswordSubmit(_.trim(username.toLowerCase()), _.trim(code), newPassword);
    dispatch({
      type: actionTypes.RESET_PASSWORD_SUCCESS,
    });
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.RESET_PASSWORD_FAILURE,
      error: error.message ? error.message : error,
    });
    throw error;
  }
};

export default resetPasswordThunk;
