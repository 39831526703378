import { Alert } from "antd";
import * as _ from "lodash";
import * as React from "react";
import "../../../../styles/sign-in-page.less";

const UnderMaintenancePage = ({ initializingError }) => {
  return (
    <div className="ApprovalPendingPageBody sign-in-page">
      {!_.isEmpty(initializingError) ? (
        <div className="title-container">
          <Alert className="loading-error-banner" message={initializingError} type="error" />
        </div>
      ) : (
        <div className="title-container">
          <div className="title">We're sorry</div>
          <div className="subtitle">
            The Data Portal is currently under maintenance. Please try again later or contact us by
            mailing <a href="mailto:DataDiscovery@twosigma.com">DataDiscovery@twosigma.com</a>.
          </div>
        </div>
      )}
    </div>
  );
};

export default UnderMaintenancePage;
