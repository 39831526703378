import { Button, Tooltip } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { getFormSyncErrors } from "redux-form";
import { IState } from "../../../../../reducers/root";

const SubmitButton = ({ isSubmitting, syncErrors }) => (
  <Tooltip placement="right" title={syncErrors.fileAttachments}>
    <Button
      className="primary-button"
      htmlType="submit"
      type="primary"
      disabled={isSubmitting || _.has(syncErrors, "fileAttachments")}
      loading={isSubmitting}
    >
      Submit dataset
    </Button>
  </Tooltip>
);

const mapStateToProps = (state: IState) => ({
  syncErrors: getFormSyncErrors("DatasetSubmitForm")(state),
});

export default connect(mapStateToProps)(SubmitButton);
