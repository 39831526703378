import Auth from "@aws-amplify/auth";
import Cookies from "js-cookie";
import SignUpStatus from "../types/enums/SignUpStatus";
import { isDataPortalServiceError, isNetworkError } from "./errorUtils";
import graphqlApi from "./graphqlApi/graphqlApi";

const removeCookie = key => Cookies.remove(key);

const getUserId = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  return cognitoUser.username;
};
const getIdToken = async () => {
  const session = await Auth.currentSession();
  return (session as any).idToken.jwtToken;
};
const hasSession = async () => {
  try {
    const session = await Auth.currentSession();
    return session;
  } catch (error) {
    return false;
  }
};
const clearSession = () => {
  removeCookie("signUpStatus");
  Auth.signOut({ global: true });
};

const getUserStatus = async (): Promise<
  | {
      signUpStatus: SignUpStatus;
      isTermsOfServiceAccepted: boolean;
    }
  | undefined
> => {
  if (!(await hasSession())) {
    return undefined;
  }
  const userId = await getUserId();
  try {
    const user = await graphqlApi.getUserStatus(userId);
    const signUpStatus = (() => {
      switch (user.status) {
        case "INCOMPLETE":
          return SignUpStatus.incomplete;
        case "PENDING":
          return SignUpStatus.pending;
        case "REJECTED":
          return SignUpStatus.rejected;
        case "APPROVED":
          return SignUpStatus.approved;
      }
      throw new Error("Unknown sign up status.");
    })();
    const isTermsOfServiceAccepted: boolean =
      signUpStatus === SignUpStatus.approved ? user.isTermsOfServiceAccepted : false;
    return {
      signUpStatus,
      isTermsOfServiceAccepted,
    };
  } catch (error) {
    if (isNetworkError(error) || isDataPortalServiceError(error)) {
      throw error;
    }
    return {
      signUpStatus: SignUpStatus.pending,
      isTermsOfServiceAccepted: false,
    };
  }
};

const sessionStore = {
  hasSession,
  clearSession,
  getUserId,
  getIdToken,
  getUserStatus,
};

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    sessionStore: any;
  }
}

window.sessionStore = sessionStore;

export default sessionStore;
