import actionTypes from "../../actions/actionTypes";

export interface IState {
  providers: any[];
}

const initialState: IState = {
  providers: [],
};

export default function(state: IState = initialState, action): IState {
  switch (action.type) {
    case actionTypes.SIGN_OUT_REQUEST: {
      return initialState;
    }
    case actionTypes.FETCH_PROVIDERS_SUCCESS:
      return {
        ...state,
        providers: action.providers,
      };
    default:
      return state;
  }
}

export const getProviders = (state: IState) => state.providers;
