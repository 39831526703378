import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import actionTypes from "../actionTypes";

const deleteDatasetsThunk = (datasetIds: string[]) => async dispatch => {
  dispatch({ type: actionTypes.DELETE_DATASETS_REQUEST });
  try {
    const deletedDatasetIds = await graphqlApi.deleteDatasets(datasetIds);
    dispatch({
      type: actionTypes.DELETE_DATASETS_SUCCESS,
      deletedDatasetIds,
    });
    return deletedDatasetIds;
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.DELETE_DATASETS_FAILURE,
      error: error.message ? error.message : error,
    });
    throw new Error(`Failed to delete datasets, ${error.message}`);
  }
};

export default deleteDatasetsThunk;
