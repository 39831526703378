import { Input } from "antd";
import * as React from "react";
import validatePassword from "../../../../../utils/validatePassword";
import makeFormField from "../makeFormField";

interface IProps {
  meta: {
    dirty: boolean;
  };
  input: any;
  value;
  onChange;
}

interface IState {
  error: string;
  isPasswordRevealed: boolean;
}

class PasswordInput extends React.Component<IProps, IState> {
  public state: IState = {
    error: "",
    isPasswordRevealed: false,
  };

  public toggleIsPasswordRevealed = () => {
    this.setState(({ isPasswordRevealed }) => ({
      isPasswordRevealed: !isPasswordRevealed,
    }));
  };

  public render() {
    const { onChange, value, meta, input, ...rest } = this.props;
    return (
      <span>
        <Input
          {...input}
          {...rest}
          onChange={onChange}
          value={value}
          type={this.state.isPasswordRevealed ? "text" : "password"}
          // addonAfter={
          //   <Icon
          //     type="eye-o"
          //     onClick={this.toggleIsPasswordRevealed}
          //     style={{ cursor: "pointer" }}
          //   />
          // }
        />
        {meta.dirty &&
          validatePassword(this.props.value).map(it => (
            <div className="form-password-explain" key={it} style={{ color: "#f5222d" }}>
              {it}
            </div>
          ))}
      </span>
    );
  }
}
const FormPassword = makeFormField(PasswordInput);

export default FormPassword;
