import * as _ from "lodash";
import * as React from "react";

import { Checkbox, Select } from "antd";
import "../../../../../../styles/alert-modal.less";
import SanitizedStatus from "../../../../../../types/enums/SanitizedStatus";

interface IProps {
  onFilterChange;
  filters: Set<SanitizedStatus>;
  isLoading: boolean;
}

interface IState {
  isDropdownOpen: boolean;
}

const validFilters = [
  {
    key: SanitizedStatus.submitted,
    label: "Submitted",
  },
  {
    key: SanitizedStatus.inReview,
    label: "In review",
  },
  {
    key: SanitizedStatus.paymentEligible,
    label: "Payment eligible",
  },
  {
    key: SanitizedStatus.paymentIneligible,
    label: "Payment ineligible",
  },
];

class SanitiziedStatusFilter extends React.Component<IProps, IState> {
  public state: IState = {
    isDropdownOpen: false,
  };

  public render() {
    const filterLookup = _.keyBy(validFilters, "key");
    const valueDisplay =
      this.props.filters.size === 0
        ? "All"
        : _.map(Array.from(this.props.filters), filter => filterLookup[filter].label).join(", ");
    return (
      <>
        <span style={{ marginRight: "15px", fontWeight: "bold" }}>Status </span>
        <Select
          style={{ width: 170 }}
          open={this.state.isDropdownOpen}
          onFocus={() => this.setState({ isDropdownOpen: true })}
          onBlur={() => this.setState({ isDropdownOpen: false })}
          value={valueDisplay}
        >
          {_.map(validFilters, filter => (
            <Select.Option key={`${filter.key}-option`}>
              <Checkbox
                value={filter.key}
                onChange={this.props.onFilterChange}
                checked={this.props.filters.has(filter.key)}
                disabled={this.props.isLoading}
              >
                {filter.label}
              </Checkbox>
            </Select.Option>
          ))}
        </Select>
      </>
    );
  }
}

export default SanitiziedStatusFilter;
