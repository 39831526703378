import * as _ from "lodash";
import SignUpStatus from "../../types/enums/SignUpStatus";
import sessionStore from "../../utils/sessionStore";
import fetchEmployerThunk from "../fetchEmployerThunk/fetchEmployerThunk";
import fetchUserThunk from "../fetchUserThunk/fetchUserThunk";

const initializeAppThunk = () => async dispatch => {
  if (await sessionStore.hasSession()) {
    const userId = await sessionStore.getUserId();
    console.info(`user ID = ${userId}`); // tslint:disable-line no-console

    const userStatus = await sessionStore.getUserStatus().catch(_.noop);
    const signUpStatus = _.get(userStatus, "signUpStatus");
    console.info("sign up status", signUpStatus); // tslint:disable-line no-console
    if (signUpStatus === SignUpStatus.approved) {
      await Promise.all([dispatch(fetchUserThunk()), dispatch(fetchEmployerThunk())]);
    }
  } else {
    console.warn("No Session found. Not signed in."); // tslint:disable-line no-console
  }
};

export default initializeAppThunk;
