import gql from "graphql-tag";
import * as _ from "lodash";
import { IGqlDataset } from "../../../types/interfaces/IGqlDataset";
import apolloClient from "../../apolloClient";

const GET_DATASET = gql`
  query Dataset($datasetId: ID!) {
    dataset(id: $datasetId) {
      id
      createdBy {
        id
        name
        email
        role
        status
      }
      name
      website
      updateFrequency
      historyLength
      categories
      regions
      assetClasses
      industries
      languages
      description
      providerId
      provider {
        id
        name
      }
      fileAttachments {
        id
        name
        size
        status
      }
      decision {
        id
        sanitizedStatus
        isPaymentProcessed
        externalComments
      }
      createdTime
      lastModifiedTime
    }
  }
`;

const getDataset = async (datasetId: string): Promise<IGqlDataset> => {
  const variables = { datasetId };
  console.log("GraphQL getDataset", variables); // tslint:disable-line no-console

  const ret: any = await apolloClient
    .query({
      query: GET_DATASET,
      variables,
      fetchPolicy: "no-cache",
    })
    .catch(error => {
      throw new Error(error.graphQLErrors.join(",") || `Failed to get dataset ${datasetId}.`);
    });

  if (!_.isEmpty(ret.errors) || _.isEmpty(_.get(ret, "data.dataset"))) {
    throw new Error(
      `Failed to get dataset ${datasetId}: ${_.map(ret.errors, "message").join(", ")}`,
    );
  }
  return ret.data.dataset;
};

export default getDataset;
