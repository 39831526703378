import { selectors } from "../../reducers/root";
import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import actionTypes from "../actionTypes";

const fetchDatasetsThunk = () => async (dispatch, getState) => {
  const version = selectors.getDatasetsTargetVersion(getState());
  dispatch({ type: actionTypes.FETCH_DATASETS_REQUEST, version });
  try {
    const datasets = await graphqlApi.getDatasets();
    dispatch({
      type: actionTypes.FETCH_DATASETS_SUCCESS,
      datasets,
      version,
    });
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.FETCH_DATASETS_FAILURE,
      error: error.message,
      version,
    });
    throw error;
  }
};

export default fetchDatasetsThunk;
