import gql from "graphql-tag";
import * as _ from "lodash";
import { IDatasetInput } from "../../../types/interfaces/IDatasetInput";
import { IGqlDataset } from "../../../types/interfaces/IGqlDataset";
import apolloClient from "../../apolloClient";

const UPDATE_DATASET = gql`
  mutation updateDataset(
    $id: ID!
    $name: String!
    $website: String
    $updateFrequency: String!
    $historyLength: String!
    $categories: [String]!
    $regions: [String]!
    $assetClasses: [String]
    $industries: [String]!
    $languages: [String]!
    $description: String!
    $fileAttachments: [String]!
    $providerId: String
  ) {
    updateDataset(
      id: $id
      input: {
        name: $name
        website: $website
        updateFrequency: $updateFrequency
        historyLength: $historyLength
        categories: $categories
        regions: $regions
        assetClasses: $assetClasses
        industries: $industries
        languages: $languages
        description: $description
        fileAttachments: $fileAttachments
        providerId: $providerId
      }
    ) {
      id
      name
      website
      updateFrequency
      historyLength
      categories
      regions
      assetClasses
      industries
      languages
      description
      providerId
    }
  }
`;

const updateDataset = async (
  datasetId: string,
  datasetInput: IDatasetInput,
): Promise<IGqlDataset> => {
  const variables = {
    id: datasetId,
    name: datasetInput.name,
    website: datasetInput.website,
    updateFrequency: datasetInput.updateFrequency,
    historyLength: datasetInput.historyLength,
    categories: datasetInput.categories,
    regions: datasetInput.regions,
    assetClasses: datasetInput.assetClasses,
    industries: datasetInput.industries,
    languages: datasetInput.languages,
    description: datasetInput.description,
    fileAttachments: datasetInput.fileAttachments,
    providerId: datasetInput.providerId,
  };

  console.log("GraphQL updateDataset", variables); // tslint:disable-line no-console

  const ret: any = await apolloClient.mutate({
    mutation: UPDATE_DATASET,
    variables,
  });
  if (!_.isEmpty(ret.errors)) {
    throw new Error(`Failed updateDataset, ${ret.errors.join(",")}`);
  }
  return ret.data.updateDataset;
};

export default updateDataset;
