import gql from "graphql-tag";
import * as _ from "lodash";
import { IGqlProviderWithDatasets } from "../../../types/interfaces/IGqlProviderWithDatasets";
import { IProviderInput } from "../../../types/interfaces/IProviderInput";
import apolloClient from "../../apolloClient";

const UPDATE_PROVIDER = gql`
  mutation updateProvider(
    $id: ID!
    $name: String!
    $website: String!
    $address: String!
    $contactName: String!
    $contactEmail: String!
    $contactPhone: String!
    $description: String!
  ) {
    updateProvider(
      id: $id
      input: {
        name: $name
        website: $website
        address: $address
        contactName: $contactName
        contactEmail: $contactEmail
        contactPhone: $contactPhone
        description: $description
      }
    ) {
      id
      name
      website
      address
      contactName
      contactEmail
      contactPhone
      description
      datasets {
        id
        name
      }
    }
  }
`;

const updateProvider = async (
  providerId: string,
  providerInput: IProviderInput,
): Promise<IGqlProviderWithDatasets> => {
  const variables = {
    id: providerId,
    name: providerInput.name,
    website: providerInput.website || "",
    address: providerInput.address || "",
    contactName: providerInput.contactName,
    contactEmail: providerInput.contactEmail,
    contactPhone: providerInput.contactPhone,
    description: providerInput.description,
  };

  console.log("GraphQL updateProvider", variables); // tslint:disable-line no-console

  const ret: any = await apolloClient
    .mutate({
      mutation: UPDATE_PROVIDER,
      variables,
    })
    .catch(error => {
      throw new Error(error.graphQLErrors.join(",") || `Failed to update provider.`);
    });
  if (!_.isEmpty(ret.errors) || _.isEmpty(_.get(ret, "data.updateProvider"))) {
    throw new Error(`Failed to update provider: ${_.map(ret.errors, "message").join(", ")}`);
  }
  return ret.data.updateProvider;
};

export default updateProvider;
