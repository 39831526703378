import { Layout } from "antd";
import * as _ from "lodash";
import * as React from "react";
import "../../../../styles/dataset-submit.less";
import DatasetUpdateFrequency from "../../../../types/enums/DatasetUpdateFrequency";
import { IDatasetInput } from "../../../../types/interfaces/IDatasetInput";
import sessionStore from "../../../../utils/sessionStore";
import DatasetSubmitForm, {
  defaultValues,
  IDatasetSubmitFormData,
} from "../../common/DatasetSubmitForm/DatasetSubmitForm";
import LegalFooter from "../../common/LegalFooter/LegalFooter";

const { Footer, Content } = Layout;

const DatasetAddPage = ({
  onCancel,
  onSubmit,
}: {
  onCancel: () => void;
  onSubmit: (data: IDatasetInput) => Promise<void>;
}) => (
  <section className="DatasetAddPage dataset-submit">
    <Layout>
      <Content>
        <div className="title-container">
          <div className="title">Tell us about this dataset</div>
        </div>
        <DatasetSubmitForm
          initialValues={defaultValues}
          onCancel={onCancel}
          onSubmit={async (values: IDatasetSubmitFormData) => {
            await onSubmit({
              userId: await sessionStore.getUserId(),
              ...values,
              providerId: values.providerId === "your_employer" ? null : values.providerId,
              updateFrequency: values.updateFrequency as DatasetUpdateFrequency,
              historyLength: values.historyLength === -1 ? "Unknown" : `${values.historyLength}`,
              categories: values.categories,
              fileAttachments: _.map(
                _.filter(values.fileAttachments, { status: "done" }),
                attachment => attachment.id,
              ),
            } as any);
          }}
        />
      </Content>
      <Footer style={{ padding: 0 }}>
        <LegalFooter />
      </Footer>
    </Layout>
  </section>
);

export default DatasetAddPage;
