import gql from "graphql-tag";
import * as _ from "lodash";
import { IDatasetInput } from "../../../types/interfaces/IDatasetInput";
import { IGqlDataset } from "../../../types/interfaces/IGqlDataset";
import apolloClient from "../../apolloClient";

const CREATE_DATASET = gql`
  mutation CreateDataset(
    $name: String!
    $website: String
    $updateFrequency: String!
    $historyLength: String!
    $categories: [String]!
    $regions: [String]!
    $assetClasses: [String]
    $industries: [String]!
    $languages: [String]!
    $description: String!
    $fileAttachments: [String]!
    $providerId: String
  ) {
    createDataset(
      input: {
        name: $name
        website: $website
        updateFrequency: $updateFrequency
        historyLength: $historyLength
        categories: $categories
        regions: $regions
        assetClasses: $assetClasses
        industries: $industries
        languages: $languages
        description: $description
        fileAttachments: $fileAttachments
        providerId: $providerId
      }
    ) {
      id
      name
      website
      updateFrequency
      historyLength
      categories
      regions
      assetClasses
      industries
      languages
      description
      providerId
    }
  }
`;

const createDataset = async (datasetInput: IDatasetInput): Promise<IGqlDataset> => {
  const variables = {
    name: datasetInput.name,
    website: datasetInput.website,
    updateFrequency: datasetInput.updateFrequency,
    historyLength: datasetInput.historyLength,
    categories: datasetInput.categories,
    regions: datasetInput.regions,
    assetClasses: datasetInput.assetClasses,
    industries: datasetInput.industries,
    languages: datasetInput.languages,
    description: datasetInput.description,
    fileAttachments: datasetInput.fileAttachments,
    providerId: datasetInput.providerId,
  };

  const ret: any = await apolloClient
    .mutate({
      mutation: CREATE_DATASET,
      variables,
    })
    .catch(error => {
      throw new Error(error.graphQLErrors.join(",") || "Failed to create dataset.");
    });

  if (!_.isEmpty(ret.errors)) {
    throw new Error(`Failed to create dataset: ${_.map(ret.errors, "message").join(", ")}`);
  }
  return ret.data.createDataset;
};

export default createDataset;
