import { ISignInData } from "../types/interfaces/ISignInData";
import acceptTermsOfServiceThunk from "./acceptTermsOfServiceThunk/acceptTermsOfServiceThunk";
import actionTypes from "./actionTypes";
import confirmSignUpThunk from "./confirmSignUpThunk/confirmSignUpThunk";
import createDatasetThunk from "./createDatasetThunk/createDatasetThunk";
import createProviderThunk from "./createProviderThunk/createProviderThunk";
import deleteDatasetsThunk from "./deleteDatasetsThunk/deleteDatasetsThunk";
import deleteProviderAndDatasetsThunk from "./deleteProviderAndDatasetsThunk/deleteProviderAndDatasetsThunk";
import deleteProvidersThunk from "./deleteProvidersThunk/deleteProvidersThunk";
import fetchDatasetsThunk from "./fetchDatasetsThunk/fetchDatasetsThunk";
import fetchDatasetsWithParamsThunk from "./fetchDatasetsWithParamsThunk/fetchDatasetsWithParamsThunk";
import fetchDatasetThunk from "./fetchDatasetThunk/fetchDatasetThunk";
import fetchEmployerThunk from "./fetchEmployerThunk/fetchEmployerThunk";
import fetchProvidersThunk from "./fetchProvidersThunk/fetchProvidersThunk";
import fetchUserThunk from "./fetchUserThunk/fetchUserThunk";
import initializeAppThunk from "./initializeAppThunk/initializeAppThunk";
import resendVerificationCodeThunk from "./resendVerificationCodeThunk/resendVerificationCodeThunk";
import resetPasswordThunk from "./resetPasswordThunk/resetPasswordThunk";
import sendResetPasswordMailThunk from "./sendResetPasswordMailThunk/sendResetPasswordMailThunk";
import signInThunk from "./signInThunk/signInThunk";
import signOutThunk from "./signOutThunk/signOutThunk";
import signUpThunk from "./signUpThunk/signUpThunk";
import submitEmployerInfoThunk from "./submitEmployerInfoThunk/submitEmployerInfoThunk";
import updateDatasetThunk from "./updateDatasetThunk/updateDatasetThunk";
import updateEmployerThunk from "./updateEmployerThunk/updateEmployerThunk";
import updateProviderThunk from "./updateProviderThunk/updateProviderThunk";
import updateUserThunk from "./updateUserThunk/updateUserThunk";

export default {
  initialize: initializeAppThunk,
  acceptTermsOfService: acceptTermsOfServiceThunk,
  confirmSignUp: confirmSignUpThunk,
  createProvider: createProviderThunk,
  deleteDatasets: deleteDatasetsThunk,
  deleteProviderAndDatasets: deleteProviderAndDatasetsThunk,
  deleteProviders: deleteProvidersThunk,
  fetchEmployer: fetchEmployerThunk,
  fetchDataset: fetchDatasetThunk,
  fetchDatasets: fetchDatasetsThunk,
  fetchDatasetsWithParams: fetchDatasetsWithParamsThunk,
  fetchProviders: fetchProvidersThunk,
  fetchUser: fetchUserThunk,
  hideEmployerEditModal: () => ({ type: actionTypes.HIDE_EMPLOYER_EDIT_MODAL }),
  signUp: signUpThunk,
  resendVerificationCode: resendVerificationCodeThunk,
  resetPassword: resetPasswordThunk,
  sendResetPasswordMail: sendResetPasswordMailThunk,
  showEmployerEditModal: () => ({ type: actionTypes.SHOW_EMPLOYER_EDIT_MODAL }),
  signIn: (signInData: ISignInData) => async dispatch => {
    await dispatch(signInThunk(signInData));
    await dispatch(initializeAppThunk());
  },
  signOut: signOutThunk,
  submitEmployerInfo: submitEmployerInfoThunk,
  createDataset: createDatasetThunk,
  updateDataset: updateDatasetThunk,
  updateProvider: updateProviderThunk,
  updateEmployer: updateEmployerThunk,
  updateUser: updateUserThunk,
};
