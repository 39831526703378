import { Layout, Spin } from "antd";
import * as React from "react";
import { spinnerDelay } from "../../../../constants/constants";
import "../../../../styles/sign-in-page.less";
import { IEmployerInfo } from "../../../../types/interfaces/IEmployerInfo";
import LegalFooter from "../../common/LegalFooter/LegalFooter";
import EmployerInfoSubmitForm from "./EmployerInfoSubmitForm/EmployerInfoSubmitForm";

const { Footer, Content } = Layout;

export const initialValues: IEmployerInfo = {
  name: "",
  website: "",
  address: "",
};

const EmployerInfoSubmitPage = ({
  isSubmitting,
  onCancel,
  onSubmit,
}: {
  onCancel: () => void;
  onSubmit: (employerInfo: IEmployerInfo) => Promise<void>;
  isSubmitting: boolean;
}) => (
  <section className="EmployerInfoSubmitPage sign-in-page">
    <Layout>
      <Content>
        <div className="title-container">
          <div className="title">Almost finished ...</div>
        </div>
        <Spin spinning={isSubmitting} tip={"Processing..."} delay={spinnerDelay}>
          <EmployerInfoSubmitForm
            initialValues={initialValues}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </Spin>
      </Content>
      <Footer style={{ padding: 0 }}>
        <LegalFooter />
      </Footer>
    </Layout>
  </section>
);

export default EmployerInfoSubmitPage;
