import { Alert, Button, Col, Form, Row } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Field, reduxForm } from "redux-form";
import ErrorBoundary from "../../../common/ErrorBoundary/ErrorBoundary";
import FormInput from "../../../common/form/FormInput/FormInput";
import InfoTooltip from "../../../common/InfoTooltip/InfoTooltip";

interface IProps {
  handleSubmit: any;
  error: any;
  onCancel: () => Promise<void>;
  submitting: boolean;
  invalid: boolean;
}

interface IState {
  isUserCompanyTheProvider: boolean;
}

const FormLabel = ({ text, tooltip }: { text: string; tooltip?: string }) => (
  <span className="EmployerInfoSubmitForm-label">
    {text}
    {!_.isEmpty(tooltip) && (
      <span>
        {" "}
        <InfoTooltip text={tooltip} />
      </span>
    )}
  </span>
);

class EmployerInfoSubmitForm extends React.Component<IProps, IState> {
  public state: IState = {
    isUserCompanyTheProvider: false,
  };

  public render() {
    const { handleSubmit, submitting, invalid, error } = this.props;
    return (
      <div className="EmployerInfoSubmitForm">
        <ErrorBoundary messagePrefix="Page Error: ">
          <Form onSubmit={handleSubmit}>
            <Row className="form-item-row">
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={<FormLabel text="Employer Name" />}
                  name="name"
                  component={FormInput}
                  required={true}
                  placeholder=""
                />
              </Col>
            </Row>
            <Row className="form-item-row">
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={<FormLabel text="Employer Website" />}
                  name="website"
                  component={FormInput}
                  required={false}
                  placeholder=""
                />
              </Col>
            </Row>
            <Row className="form-item-row">
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={<FormLabel text="Employer Address" />}
                  name="address"
                  component={FormInput}
                  required={false}
                  placeholder=""
                />
              </Col>
            </Row>
            {error && <Alert className="submission-error-banner" message={error} type="error" />}
            <Button
              className="primary-button"
              htmlType="submit"
              type="primary"
              disabled={submitting || invalid}
            >
              Finish signup
            </Button>
          </Form>
        </ErrorBoundary>
      </div>
    );
  }
}

const validate = values => {
  const errors = {} as any;
  if (_.isEmpty(_.trim(values.name))) {
    errors.name = "Employer name is required";
  }
  return errors;
};

export const formName = "EmployerInfoSubmitForm";

export default reduxForm({
  form: formName,
  validate,
})(EmployerInfoSubmitForm);
