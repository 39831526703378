import { Button, Modal } from "antd";
import * as React from "react";

import "../../../../../../styles/alert-modal.less";
import { IFileUpload } from "../../../../../../types/interfaces/IFileUpload";
import "./FilesUploadDeleteConfirmModal.less";

interface IProps {
  file: IFileUpload;
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

class FilesUploadDeleteConfirmModal extends React.Component<IProps, {}> {
  public render() {
    const { isVisible, onCancel, file } = this.props;
    return (
      <Modal
        width={710}
        className="FilesUploadDeleteConfirmModal alert-modal"
        visible={isVisible}
        title="Delete file attachment"
        onOk={this.handleConfirmRequest}
        onCancel={onCancel}
        footer={[
          <Button
            key="submit"
            className="primary-button"
            type="primary"
            onClick={this.handleConfirmRequest}
          >
            Delete file
          </Button>,
          <Button key="cancel" className="secondary-button" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        {isVisible && (
          <div className="modal-content">
            <div>Are you sure you want to delete the file “{file.originalName}”?</div>
            <div>This cannot be undone.</div>
          </div>
        )}
      </Modal>
    );
  }

  private handleConfirmRequest = async () => {
    this.props.onConfirm();
  };
}

export default FilesUploadDeleteConfirmModal;
