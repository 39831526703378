import gql from "graphql-tag";
import * as _ from "lodash";
import apolloClient from "../../apolloClient";

const ACCEPT_TERMS_OF_SERVICE = gql`
  mutation AcceptTermsOfService($userId: ID!, $version: Int!) {
    acceptTermsOfService(userId: $userId, version: $version)
  }
`;

const acceptTermsOfService = async (
  userId: string,
  version: number,
): Promise<{ userId: string }> => {
  const variables = { userId, version };

  console.log("GraphQL acceptTermsOfService", variables); // tslint:disable-line no-console

  const ret: any = await apolloClient.mutate({
    mutation: ACCEPT_TERMS_OF_SERVICE,
    variables,
  });
  if (!_.isEmpty(ret.errors)) {
    throw new Error(
      `Failed acceptTermsOfService, ${_.map(ret.errors, "extensions.code").join(",")}`,
    );
  }
  return {
    userId: ret.data.acceptTermsOfService,
  };
};

export default acceptTermsOfService;
