import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { initialize } from "redux-form";
import actionCreators from "../../../../actions/actionCreators";
import { IState as IStoreState, selectors } from "../../../../reducers/root";
import {
  datasetSubmitFormName,
  defaultValues,
} from "../../common/DatasetSubmitForm/DatasetSubmitForm";
import DatasetDetailsPageContainer, {
  IDispatchProps,
  IStateProps,
} from "./DatasetDetailsPageContainer";

interface IOwnProps extends RouteComponentProps<any> {}

const ConnectedDatasetDetailsPage = connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(
  (state: IStoreState, ownProps: IOwnProps) => ({
    location: ownProps.location,
    history: ownProps.history,
    employer: selectors.getEmployer(state),
    user: selectors.getUser(state),
  }),
  (dispatch: any) => ({
    onInit: async (datasetId: string) => {
      await dispatch(initialize(datasetSubmitFormName, defaultValues));
      const dataset = await dispatch(actionCreators.fetchDataset(datasetId));
      return dataset;
    },
  }),
)(DatasetDetailsPageContainer);

export default withRouter(ConnectedDatasetDetailsPage);
