import * as React from "react";
import DatasetUpdateFrequency from "../../../../../types/enums/DatasetUpdateFrequency";
import makeFormField from "../../form/makeFormField";
import OrdinalSlider from "../../OridinalSlider/OrdinalSlider";

const marksArray = [
  {
    id: DatasetUpdateFrequency.intraday,
    mark: "Intraday",
  },
  {
    id: DatasetUpdateFrequency.daily,
    mark: "Daily",
  },
  {
    id: DatasetUpdateFrequency.weekly,
    mark: "Weekly",
  },
  {
    id: DatasetUpdateFrequency.monthly,
    mark: "Monthly",
  },
  {
    id: DatasetUpdateFrequency.quarterly,
    mark: "Quarterly",
  },
  {
    id: DatasetUpdateFrequency.unknown,
    mark: "Unknown",
  },
];

const UpdateFrequencyInput = ({ value, onChange }) => {
  return (
    <div className="UpdateFrequencyInput">
      <OrdinalSlider
        className="UpdateFrequencyInput-slider"
        marksArray={marksArray}
        paddingLeft={0.1}
        paddingRight={0.1}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default makeFormField(UpdateFrequencyInput);
