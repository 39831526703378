import * as H from "history";
import * as _ from "lodash";
import { parse } from "qs";
import * as React from "react";
import { IDataset } from "../../../../types/interfaces/IDataset";
import { IGqlEmployer } from "../../../../types/interfaces/IGqlEmployer";
import { IGqlUser } from "../../../../types/interfaces/IGqlUser";
import DatasetDetailsPage from "./DatasetDetailsPage";

export interface IStateProps {
  location: H.Location;
  history: H.History;
  employer: IGqlEmployer | null;
  user: IGqlUser | null;
}

export interface IDispatchProps {
  onInit: (datasetId: string) => Promise<IDataset>;
}

interface IProps extends IStateProps, IDispatchProps {}

interface IState {
  isLoading: boolean;
  dataset: IDataset | null;
  loadingError: string | null;
}

const getDatasetId = location => {
  const queryString = location.search.replace(/^\?/, "");
  return _.get(parse(queryString), "id", null);
};

export default class DatasetDetailsPageContainer extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: true,
    loadingError: null,
    dataset: null,
  };

  public async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const datasetId = getDatasetId(this.props.location);
      const dataset: IDataset = await this.props.onInit(datasetId);
      this.setState({ dataset });
    } catch (error) {
      this.setState({ loadingError: error.message });
    }
    this.setState({ isLoading: false });
  }

  public render() {
    return this.state.dataset ? (
      <DatasetDetailsPage
        isLoading={this.state.isLoading}
        loadingError={this.state.loadingError}
        dataset={this.state.dataset}
        employer={this.props.employer}
        history={this.props.history}
        user={this.props.user}
      />
    ) : null;
  }
}
