import { selectors } from "../../reducers/root";
import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import sessionStore from "../../utils/sessionStore";
import actionTypes from "../actionTypes";

const createProviderThunk = providerData => async (dispatch, getState) => {
  const userId = await sessionStore.getUserId();
  if (userId === null) {
    throw new Error("Cannot fetch user, missing user ID");
  }
  const employer = selectors.getEmployer(getState());
  dispatch({ type: actionTypes.CREATE_PROVIDER_REQUEST });
  try {
    const provider = await graphqlApi.createProvider({
      ...providerData,
      userId,
      employerId: employer ? employer.id : null,
    });
    dispatch({
      type: actionTypes.CREATE_PROVIDER_SUCCESS,
      provider,
    });
    return provider;
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.CREATE_PROVIDER_FAILURE,
      error: error.message,
    });
    throw error;
  }
};

export default createProviderThunk;
