import { connect } from "react-redux";
import { initialize, submit } from "redux-form";
import actionCreators from "../../../../../../actions/actionCreators";
import { IState as IStoreState, selectors } from "../../../../../../reducers/root";
import { IGqlProviderWithDatasets } from "../../../../../../types/interfaces/IGqlProviderWithDatasets";
import { IProviderInput } from "../../../../../../types/interfaces/IProviderInput";
import graphqlApi from "../../../../../../utils/graphqlApi/graphqlApi";
import { formInitialValues, formName } from "./ProviderEditModal";
import ProviderEditModalContainer, {
  IDispatchProps,
  IStateProps,
} from "./ProviderEditModalContainer";

interface IOwnProps {
  providerId: string | null;
  onDone: () => void;
}

const mapStateToProps = (state: IStoreState, ownProps: IOwnProps) => {
  const { providerId } = ownProps;
  return {
    providerId,
    user: selectors.getUser(state),
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: IOwnProps) => {
  return {
    onReset: async (providerId: string | null): Promise<IGqlProviderWithDatasets | null> => {
      if (providerId) {
        const provider = await graphqlApi.getProvider(providerId);
        const values = {
          name: provider.name,
          address: provider.address,
          website: provider.website,
          contactName: provider.contactName,
          contactEmail: provider.contactEmail,
          contactPhone: provider.contactPhone,
          description: provider.description,
        };
        await dispatch(initialize(formName, values));
        return provider;
      } else {
        await dispatch(initialize(formName, formInitialValues));
        return null;
      }
    },
    onClickSaveButton: async () => {
      dispatch(submit(formName));
    },
    onSave: async (id: string | undefined, provider: IProviderInput) => {
      await dispatch(actionCreators.updateProvider(id, provider));
      ownProps.onDone();
    },
    onDelete: async (id: string) => {
      await dispatch(actionCreators.deleteProviderAndDatasets(id));
      ownProps.onDone();
    },
    onDone: () => {
      ownProps.onDone();
    },
  };
};

const ConnectedProviderEditModal = connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderEditModalContainer);

export default ConnectedProviderEditModal;
