import { Spin } from "antd";
import * as React from "react";
import { spinnerDelay } from "../../../../../../constants/constants";
import ForgotPasswordForm from "./ForgotPasswordForm/ForgotPasswordForm";

const SubmitEmailStep = ({
  isSubmitting,
  onSubmit,
}: {
  isSubmitting: boolean;
  onSubmit: (username: string) => Promise<void>;
}) => {
  return (
    <div>
      <div className="title-container">
        <div className="title">Forgot your password?</div>
        <div className="subtitle">
          Enter your email below and we will send a message to reset your password.
        </div>
      </div>
      <Spin tip={"Sending code..."} delay={spinnerDelay} spinning={isSubmitting}>
        <ForgotPasswordForm
          initialValues={{ email: "" }}
          onSubmit={async values => {
            await onSubmit(values.email);
          }}
        />
      </Spin>
    </div>
  );
};

export default SubmitEmailStep;
