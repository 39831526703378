import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import datasets, * as fromDatasets from "./datasets/datasets";
import employer, * as fromEmployer from "./employer/employer";
import modals, * as fromModals from "./modals/modals";
import providers, * as fromProviders from "./providers/providers";
import user, * as fromUser from "./user/user";

export interface IState {
  user: fromUser.IState;
  employer: fromEmployer.IState;
  datasets: fromDatasets.IState;
  providers: fromProviders.IState;
  modals: fromModals.IState;
}

export default combineReducers({
  form: formReducer,
  user,
  employer,
  datasets,
  providers,
  modals,
});

export const selectors = {
  getUser: (state: IState) => fromUser.getUser(state.user),
  getEmployer: (state: IState) => fromEmployer.getEmployer(state.employer),
  getEmployerId: (state: IState) => fromEmployer.getEmployerId(state.employer),
  getDatasets: (state: IState) => fromDatasets.getDatasets(state.datasets),
  getDatasetsTargetVersion: (state: IState) => fromDatasets.getTargetVersion(state.datasets),
  getDatasetsLoadingVersion: (state: IState) => fromDatasets.getLoadingVersion(state.datasets),
  getDatasetsCurrentVersion: (state: IState) => fromDatasets.getCurrentVersion(state.datasets),
  getIsDatasetsLoading: (state: IState) => fromDatasets.getIsLoading(state.datasets),
  getDatasetsLoadingError: (state: IState) => fromDatasets.getLoadingError(state.datasets),
  getProviders: (state: IState) => fromProviders.getProviders(state.providers),
  getIsEmployerEditModalVisible: (state: IState) =>
    fromModals.getIsEmployerEditModalVisible(state.modals),
};
