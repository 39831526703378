import * as _ from "lodash";
import { selectors } from "../../reducers/root";
import { IDatasetsLoadingParams } from "../../types/interfaces/IDatasetsLoadingParams";
import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import actionTypes from "../actionTypes";

const fetchDatasetsWithParamsThunk = (params: IDatasetsLoadingParams) => async (
  dispatch,
  getState,
) => {
  const version = selectors.getDatasetsTargetVersion(getState());
  dispatch({
    type: actionTypes.FETCH_DATASETS_REQUEST,
    version,
    params,
  });
  try {
    const datasets = await graphqlApi.getDatasetsWithParams(params);
    dispatch({
      type: actionTypes.FETCH_DATASETS_SUCCESS,
      datasets,
      version,
    });
    return datasets;
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.FETCH_DATASETS_FAILURE,
      error: error.message,
      version,
    });
    throw error;
  }
};

export default fetchDatasetsWithParamsThunk;
