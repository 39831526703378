import * as _ from "lodash";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { initialize } from "redux-form";
import actionCreators from "../../../../actions/actionCreators";
import { IState as IStoreState } from "../../../../reducers/root";
import { IDatasetFileAttachment } from "../../../../types/interfaces/IDatasetFileAttachment";
import { IDatasetInput } from "../../../../types/interfaces/IDatasetInput";
import {
  datasetSubmitFormName,
  defaultValues,
  IDatasetSubmitFormData,
} from "../../common/DatasetSubmitForm/DatasetSubmitForm";
import printNameWithSize from "../../common/DatasetSubmitForm/FormFilesUpload/FilesUpload/printNameWithSize";
import DatasetEditPageContainer, { IDispatchProps, IStateProps } from "./DatasetEditPageContainer";

interface IOwnProps extends RouteComponentProps<any> {}

const ConnectedDatasetEditPage = connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(
  (state: IStoreState, ownProps: IOwnProps) => ({
    location: ownProps.location,
    history: ownProps.history,
  }),
  (dispatch: any) => ({
    onInit: async (datasetId: string) => {
      await dispatch(initialize(datasetSubmitFormName, defaultValues));
      const dataset = await dispatch(actionCreators.fetchDataset(datasetId));
      const values: IDatasetSubmitFormData = {
        providerId: dataset.providerId === null ? "your_employer" : dataset.providerId,
        name: dataset.name,
        website: dataset.website,
        updateFrequency: dataset.updateFrequency,
        historyLength: dataset.historyLength === "Unknown" ? -1 : _.toNumber(dataset.historyLength),
        categories: dataset.categories,
        regions: dataset.regions,
        assetClasses: dataset.assetClasses,
        industries: dataset.industries,
        languages: dataset.languages,
        description: dataset.description,
        fileAttachments:
          _.map(
            _.filter(dataset.fileAttachments, { status: "VALID" }) as IDatasetFileAttachment[],
            attachment => ({
              id: attachment.id,
              name: printNameWithSize(attachment.name, attachment.size),
              originalName: attachment.name,
              size: attachment.size,
              isExisting: true,
              uid: attachment.id,
              status: "done" as any,
              type: "unknown",
            }),
          ) || [],
      };
      await dispatch(initialize(datasetSubmitFormName, values));
      return dataset;
    },
    onSubmit: async (datasetId, datasetInput: IDatasetInput) => {
      await dispatch(actionCreators.updateDataset(datasetId, datasetInput));
    },
  }),
)(DatasetEditPageContainer);

export default withRouter(ConnectedDatasetEditPage);
