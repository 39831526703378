import { Select } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Fragment } from "react";
import { IProvider } from "../../../../../../types/interfaces/IProvider";
import ISorting from "../../../../../../types/interfaces/ISorting";
import ProviderAddModal from "../../../ProviderAddModal/ConnectedProviderAddModal";
import YourEmployerTag from "../../../YourEmployerTag/YourEmployerTag";

const Option = Select.Option;

interface IProps {
  providers: IProvider[];
  selectedProviderId: string;
  isDisabled: boolean;
  employer: any | null;
  onInit: () => Promise<void>;
  onChange: (event) => void;
  sorting: ISorting;
}

interface IState {
  isInitializing: boolean;
  isNewProviderModalVisible: boolean;
  confirmModalDeferred: any | null;
  // selectedProvider: IProvider | null;
}

class ProviderSelect extends React.Component<IProps, IState> {
  public state: IState = {
    isInitializing: true,
    isNewProviderModalVisible: false,
    confirmModalDeferred: null,
    // selectedProvider: null,
  };

  public initialize = async () => {
    this.setState({ isInitializing: true });
    await this.props.onInit();
    this.setState({ isInitializing: false });
  };

  public handleSelect = value => {
    if (value === "new_provider") {
      this.setState({
        isNewProviderModalVisible: true,
        // selectedProvider: null,
      });
      this.props.onChange(null);
    } else if (value === "your_employer") {
      this.props.onChange("your_employer");
    } else {
      const provider = _.find(this.props.providers, { id: value as string });
      this.props.onChange(provider ? provider.id : null);
    }
  };

  public async componentDidMount() {
    this.initialize();
  }

  public render() {
    const { employer, providers, isDisabled, selectedProviderId, onChange, ...rest } = this
      .props as any;
    const sortedProviders = _.orderBy(
      providers,
      [this.props.sorting.field],
      [this.props.sorting.direction],
    );
    const providerMap = _.keyBy(sortedProviders, "id");
    return (
      <Fragment>
        <Select
          {...rest}
          showSearch
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          optionFilterProp="children"
          value={selectedProviderId}
          onSelect={this.handleSelect}
          filterOption={(input, option) => {
            if (option == null || option.key === null) {
              return false;
            }
            const provider = providerMap[option.key as string];
            return provider.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          disabled={isDisabled}
        >
          <Option value="new_provider">(New provider)</Option>
          {employer && (
            <Option value="your_employer">
              {employer.name}
              <YourEmployerTag />
            </Option>
          )}
          {_.map(sortedProviders, provider => {
            return (
              <Option key={provider.id} value={provider.id}>
                {provider.name}
              </Option>
            );
          })}
        </Select>
        <ProviderAddModal
          isVisible={this.state.isNewProviderModalVisible}
          onCancel={() => {
            this.setState({ isNewProviderModalVisible: false });
            onChange(undefined);
          }}
          onSave={async provider => {
            this.setState({
              isNewProviderModalVisible: false,
            });
            onChange(provider.id);
            // new provider was created, now refresh to load all providers again
            this.initialize();
          }}
        />
      </Fragment>
    );
  }
}

export default ProviderSelect;
