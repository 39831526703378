import * as _ from "lodash";
import * as qs from "qs";
import {
  filterableColumns,
  IDatasetsLoadingParams,
} from "../types/interfaces/IDatasetsLoadingParams";

export const getQueryStringFromSearch = (search: string): string => search.replace(/^\?/, "");

export const getParamsFromQueryString = (queryString: string): IDatasetsLoadingParams => {
  const parsed = qs.parse(queryString);
  const params = {
    filters: {},
  };
  _.forEach(filterableColumns, filterableColumn => {
    if (parsed[filterableColumn]) {
      params.filters[filterableColumn] = parsed[filterableColumn].split(".");
    }
  });
  return params;
};

export const getQueryStringFromParams = (params: IDatasetsLoadingParams): string => {
  const queryStringObject = {};
  _.forEach(params.filters, (filter, key) => {
    if (!_.isEmpty(filter) && filter) {
      queryStringObject[key] = filter.join(".");
    }
  });
  return qs.stringify(_.omitBy(queryStringObject, _.isUndefined));
};
