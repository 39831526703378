import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import actionCreators from "../../../../../../actions/actionCreators";
import { IState as IStoreState } from "../../../../../../reducers/root";
import ResetPasswordStepContainer, {
  IDispatchProps,
  IStateProps,
} from "./ResetPasswordStepContainer";

interface IOwnProps extends RouteComponentProps<any> {}

const ConnectedResetPasswordStep = connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(
  (state: IStoreState, ownProps: IOwnProps) => ({
    history: ownProps.history,
  }),
  (dispatch: any) => ({
    onSubmit: async (username: string, code: string, newPassword: string) => {
      await dispatch(actionCreators.resetPassword(username, code, newPassword));
    },
  }),
)(ResetPasswordStepContainer);

export default withRouter(ConnectedResetPasswordStep);
