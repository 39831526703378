import actionTypes from "../../actions/actionTypes";
import { IGqlUser } from "../../types/interfaces/IGqlUser";

export interface IState {
  user: IGqlUser | null;
}

const initialState: IState = {
  user: null,
};

export default function(state: IState = initialState, action): IState {
  switch (action.type) {
    case actionTypes.FETCH_USER_SUCCESS:
    case actionTypes.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        user: action.user,
      };
    }
    case actionTypes.SIGN_OUT_SUCCESS: {
      return {
        ...state,
        user: null,
      };
    }
    default:
      return state;
  }
}

export const getUser = (state: IState) => state.user;
