import gql from "graphql-tag";
import { IEmployerInput } from "../../../types/interfaces/IEmployerInput";
import apolloClient from "../../apolloClient";

const UPDATE_EMPLOYER = gql`
  mutation updateEmployer(
    $name: String!
    $website: String
    $address: String!
    $contactName: String
    $contactEmail: String
    $contactPhone: String
    $description: String
  ) {
    updateEmployer(
      input: {
        name: $name
        website: $website
        address: $address
        contactName: $contactName
        contactEmail: $contactEmail
        contactPhone: $contactPhone
        description: $description
      }
    ) {
      id
      name
    }
  }
`;

const updateEmployer = async (employerId: string, employerInput: IEmployerInput): Promise<any> => {
  const variables = {
    id: employerId,
    name: employerInput.name,
    website: employerInput.website || "",
    address: employerInput.address || "",
    contactName: employerInput.contactName,
    contactEmail: employerInput.contactEmail,
    contactPhone: employerInput.contactPhone,
    description: employerInput.description,
    isApproved: employerInput.isApproved,
  };

  return apolloClient.mutate({
    mutation: UPDATE_EMPLOYER,
    variables,
  });
};

export default updateEmployer;
