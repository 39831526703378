import { Select } from "antd";
import * as React from "react";
import makeFormField from "../makeFormField";

const MySelect = ({ value, ...rest }) => (
  <Select value={value === "" ? undefined : value} {...rest} />
);
const FormDropdownSelect = makeFormField(MySelect);

export default FormDropdownSelect;
