import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import sessionStore from "../../utils/sessionStore";
import actionTypes from "../actionTypes";

const fetchUserThunk = () => async dispatch => {
  const userId = await sessionStore.getUserId();
  if (userId === null) {
    throw new Error("Cannot fetch user, missing user ID");
  }
  dispatch({ type: actionTypes.FETCH_USER_REQUEST });
  try {
    const user = await graphqlApi.getUser(userId);
    dispatch({
      type: actionTypes.FETCH_USER_SUCCESS,
      user,
    });
    return user;
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.FETCH_USER_FAILURE,
      error: error.message,
    });
    throw error;
  }
};

export default fetchUserThunk;
