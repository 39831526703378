import actionTypes from "../../actions/actionTypes";
import { IGqlDatasetRow } from "../../types/interfaces/IGqlDatasetRow";

export interface IState {
  targetVersion: number;
  loadingVersion: number | null;
  currentVersion: number;
  loadingError: string | null;
  datasets: IGqlDatasetRow[];
}

const initialState: IState = {
  targetVersion: 0,
  loadingVersion: null,
  currentVersion: -1,
  loadingError: null,
  datasets: [],
};

export default function(state: IState = initialState, action): IState {
  switch (action.type) {
    case actionTypes.SIGN_OUT_REQUEST: {
      // Make sure currentVersion and targetVersion are the same so signing out doesn't trigger datasets reloading
      return {
        ...initialState,
        currentVersion: 0,
      };
    }
    case actionTypes.SIGN_IN_SUCCESS: {
      return {
        ...state,
        targetVersion: state.targetVersion + 1,
      };
    }
    case actionTypes.UPDATE_USER_SUCCESS:
    case actionTypes.CREATE_DATASET_SUCCESS:
    case actionTypes.DELETE_DATASETS_SUCCESS:
    case actionTypes.DELETE_PROVIDER_AND_ALL_ITS_DATASETS_SUCCESS:
    case actionTypes.UPDATE_PROVIDER_SUCCESS: {
      return {
        ...state,
        targetVersion: state.targetVersion + 1,
      };
    }
    case actionTypes.FETCH_DATASETS_REQUEST: {
      return {
        ...state,
        loadingError: null,
        loadingVersion: action.version,
      };
    }
    case actionTypes.FETCH_DATASETS_SUCCESS: {
      if (action.version === state.loadingVersion) {
        return {
          ...state,
          datasets: action.datasets,
          loadingVersion: null,
          currentVersion: action.version,
        };
      } else {
        return state;
      }
    }
    case actionTypes.FETCH_DATASETS_FAILURE: {
      if (action.version === state.loadingVersion) {
        return {
          ...state,
          loadingError: action.error,
          loadingVersion: null,
          currentVersion: action.version,
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}

export const getDatasets = (state: IState) => state.datasets;
export const getTargetVersion = (state: IState) => state.targetVersion;
export const getLoadingVersion = (state: IState) => state.loadingVersion;
export const getCurrentVersion = (state: IState) => state.currentVersion;
export const getIsLoading = (state: IState) => state.loadingVersion !== null;
export const getLoadingError = (state: IState) => state.loadingError;
