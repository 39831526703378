import * as H from "history";
import * as React from "react";
import { SubmissionError } from "redux-form";
import "../../../../styles/sign-in-page.less";
import { IEmployerInfo } from "../../../../types/interfaces/IEmployerInfo";
import EmployerInfoSubmitPage from "./EmployerInfoSubmitPage";

export interface IStateProps {
  history: H.History;
}

export interface IDispatchProps {
  onSubmit: (employerInfo: IEmployerInfo) => Promise<void>;
}

interface IProps extends IStateProps, IDispatchProps {}

interface IState {
  isProcessing: boolean;
}

export const initialValues: IEmployerInfo = {
  name: "",
  website: "",
  address: "",
};

export default class EmployerInfoSubmitPageContainer extends React.Component<IProps, IState> {
  public state: IState = {
    isProcessing: false,
  };

  public render() {
    const { history } = this.props;
    return (
      <EmployerInfoSubmitPage
        isSubmitting={this.state.isProcessing}
        onCancel={() => {
          history.push(`/datasets`);
        }}
        onSubmit={async (values: IEmployerInfo) => {
          this.setState({ isProcessing: true });
          try {
            await this.props.onSubmit(values);
            this.setState({ isProcessing: false }, () => {
              history.push(`/approval_pending`);
            });
          } catch (error) {
            this.setState({ isProcessing: false });
            throw new SubmissionError({
              email: error.code === "UsernameExistsException" ? error.message : undefined,
              _error: `Failed to finish signing up. ${error.message}`,
            });
          }
        }}
      />
    );
  }
}
