import { connect } from "react-redux";
import actionCreators from "../../../../actions/actionCreators";
import { IState } from "../../../../reducers/root";
import PrivateRoute from "./PrivateRoute";

const mapStateToProps = (state: IState, ownProps: any) => ({
  component: ownProps.component,
  ...ownProps,
});

const mapDispatchToProps = dispatch => ({
  onAccept: version => dispatch(actionCreators.acceptTermsOfService(version)),
});

const ConnectedPrivateRoute = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateRoute);

export default ConnectedPrivateRoute;
