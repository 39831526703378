import { Alert, Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { spinnerDelay } from "../../../../../../constants/constants";
import { ISignUpData } from "../../../../../../types/interfaces/ISignUpData";
import SignUpForm from "./SignUpForm/SignUpForm";

export const initialValues = {
  name: "",
  email: "",
  password: "",
};

const SignUpPage = ({
  isInitializing,
  initializationError,
  isSubmitting,
  onSubmit,
}: {
  isInitializing: boolean;
  initializationError: string | null;
  isSubmitting: boolean;
  onSubmit: (data: ISignUpData) => Promise<void>;
}) => {
  return (
    <div className="SignUpPage">
      <div className="title-container">
        <div className="title">Sign up</div>
      </div>
      {initializationError && (
        <Alert className="initialization-error-banner" message={initializationError} type="error" />
      )}
      <Spin
        spinning={isInitializing || !_.isEmpty(initializationError)}
        indicator={initializationError ? <div /> : undefined}
        tip={initializationError ? "" : "Initializing..."}
        delay={spinnerDelay}
      >
        <SignUpForm initialValues={initialValues} onSubmit={onSubmit} />
      </Spin>
    </div>
  );
};

export default SignUpPage;
