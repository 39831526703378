import { connect } from "react-redux";
import { initialize, submit } from "redux-form";
import actionCreators from "../../../../actions/actionCreators";
import { IState as IStoreState, selectors } from "../../../../reducers/root";
import { IEmployerInput } from "../../../../types/interfaces/IEmployerInput";
import { form } from "./EmployerInfoEditModal";
import EmployerInfoEditModalContainer, {
  IDispatchProps,
  IStateProps,
} from "./EmployerInfoEditModalContainer";

interface IOwnProps {
  isVisible: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const mapStateToProps = (state: IStoreState, ownProps: IOwnProps) => ({
  isVisible: ownProps.isVisible,
  employer: selectors.getEmployer(state),
});

const mapDispatchToProps = (dispatch: any, ownProps: IOwnProps) => {
  return {
    onInit: async () => {
      const employer = await dispatch(actionCreators.fetchEmployer());
      const values = {
        name: employer.name,
        website: employer.website,
        address: employer.address,
        contactName: employer.contactName,
        contactEmail: employer.contactEmail,
        contactPhone: employer.contactPhone,
        description: employer.description,
      };
      await dispatch(initialize(form, values));
    },
    onClickSubmitButton: async () => {
      dispatch(submit(form));
    },
    onSubmit: async (employerInput: IEmployerInput) => {
      await dispatch(actionCreators.updateEmployer(employerInput));
      ownProps.onSubmit();
    },
    onCancel: () => {
      ownProps.onCancel();
    },
  };
};

const ConnectedEmployerInfoEditModal = connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(
  mapStateToProps,
  mapDispatchToProps,
)(EmployerInfoEditModalContainer);
export default ConnectedEmployerInfoEditModal;
