import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import actionTypes from "../actionTypes";

const fetchEmployerThunk = () => async dispatch => {
  dispatch({ type: actionTypes.FETCH_USER_REQUEST });
  try {
    const employer = await graphqlApi.getEmployer();
    dispatch({
      type: actionTypes.FETCH_EMPLOYER_SUCCESS,
      employer,
    });
    return employer;
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.FETCH_EMPLOYER_FAILURE,
      error: error.message,
    });
    throw error;
  }
};

export default fetchEmployerThunk;
