import { Alert } from "antd";
import * as _ from "lodash";
import * as React from "react";
import "../../../../styles/sign-in-page.less";

const ApprovalPendingPage = ({ initializingError }) => {
  return (
    <div className="ApprovalPendingPageBody sign-in-page">
      {!_.isEmpty(initializingError) ? (
        <div className="title-container">
          <Alert className="loading-error-banner" message={initializingError} type="error" />
        </div>
      ) : (
        <div className="title-container">
          <div className="title">Your application is pending</div>
          <div className="subtitle">
            We have received your sign up request. You will receive an email confirmation once your
            account is approved
          </div>
        </div>
      )}
    </div>
  );
};

export default ApprovalPendingPage;
