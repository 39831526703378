import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import actionCreators from "../../../../actions/actionCreators";
import { IState as IStoreState } from "../../../../reducers/root";
import { IEmployerInfo } from "../../../../types/interfaces/IEmployerInfo";
import EmployerInfoSubmitPageContainer, {
  IDispatchProps,
  IStateProps,
} from "./EmployerInfoSubmitPageContainer";

interface IOwnProps extends RouteComponentProps<any> {}

const ConnecedEmployerInfoSubmitPage = connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(
  (state: IStoreState, ownProps: IOwnProps) => ({
    history: ownProps.history,
  }),
  (dispatch: any) => ({
    onSubmit: async (employerInfo: IEmployerInfo) => {
      await dispatch(actionCreators.submitEmployerInfo(employerInfo));
    },
  }),
)(EmployerInfoSubmitPageContainer);

export default withRouter(ConnecedEmployerInfoSubmitPage);
