import { Icon, Upload } from "antd";
import * as _ from "lodash";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { IFileUpload } from "../../../../../../types/interfaces/IFileUpload";
import fileAttachmentsApi from "../../../../../../utils/fileAttachmentApi/fileAttachmentApi";
import FilesUploadDeleteConfirmModal from "./FilesUploadDeleteConfirmModal";
import FilesUploadFileCountLimitAlert from "./FilesUploadFileCountLimitAlert";
import FilesUploadTotalSizeLimitAlert from "./FilesUploadTotalSizeLimitAlert";
import printNameWithSize from "./printNameWithSize";

import "./FilesUpload.less";

const Dragger = Upload.Dragger;

const FILE_TYPE_SERVER_ERROR_REGEX = /^Input file type \(.*\) \([a-zA-Z0-9\-\/]+\) did not match any of the valid file types: \[.*\]$/;

interface IProps {
  meta;
  children;
  hasFeedback;
  label;
  required;
  value: IFileUpload[];
  onChange;
  uploadUrl: string;
  uploadHeaders: { authorization: string };
  fileCountLimit: number;
  totalSizeLimit: number;
  acceptedFileTypes: { [extension: string]: string };
}

interface IConfirmFileRemoveModalDeferred {
  file: IFileUpload;
  resolve: (props: { isConfirmed: boolean }) => void;
}

interface IState {
  fileList: IFileUpload[];
  confirmFileRemoveModalDeferred: IConfirmFileRemoveModalDeferred | null;
  isFileCountLimitAlertVisible: boolean;
  isTotalSizeLimitAlertVisible: boolean;
}

const getUploadItemDisplay = (file: IFileUpload): string | React.ReactNode => {
  switch (file.status) {
    case "error": {
      const errorMessage = (() => {
        if (_.isString(file.error)) {
          return file.error;
        }
        return _.get(file.error, "message", null) || file.response.error;
      })();
      return (
        <div>
          <div>{printNameWithSize(file.originalName, file.size)}</div>
          <div>
            <Icon type="info-circle" className="error-info-icon" />{" "}
            <span className="error-info-message">{errorMessage}</span>
          </div>
        </div>
      );
    }
    case "done":
    case "uploading":
    default: {
      return printNameWithSize(file.originalName, file.size);
    }
  }
};

class FilesUpload extends React.Component<IProps, IState> {
  public state: IState = {
    fileList: [],
    confirmFileRemoveModalDeferred: null,
    isFileCountLimitAlertVisible: false,
    isTotalSizeLimitAlertVisible: false,
  };

  private draggerRef = React.createRef();

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    const existingFileIds = new Set(
      _.difference(
        _.map(_.filter(this.props.value, { isExisting: true }), "id"),
        _.map(this.state.fileList, "id"),
      ),
    );
    if (!_.isEmpty(existingFileIds)) {
      this.setState({
        fileList: [
          ...this.state.fileList,
          ..._.filter(this.props.value, ({ id }) => existingFileIds.has(id)),
        ],
      });
    }

    // This is a hack to remove tooltip when name is not a string
    // When there are errors, we are passing React Node instead of plain string as `name` in the
    // fileList. The antd componet take the name and use it for 'title' (which stringified to
    // `[object Object]`, which is the behavior we don't want.
    // This hack finds those unwanted title and remove them.
    if (this.draggerRef.current) {
      const node = ReactDOM.findDOMNode(this.draggerRef.current as any);
      if (node) {
        const listErrorItemElements = Array.from(
          (node as any).getElementsByClassName("ant-upload-list-item-error"),
        );
        _.forEach(listErrorItemElements, listErrorItemElement => {
          const listItemNameElements = Array.from(
            (listErrorItemElement as any).getElementsByClassName("ant-upload-list-item-name"),
          );
          _.forEach(listItemNameElements, listItemNameElement => {
            if ((listItemNameElement as any).title === "[object Object]") {
              (listItemNameElement as any).removeAttribute("title");
            }
          });
        });
      }
    }
  }

  public render() {
    const { uploadUrl, uploadHeaders, fileCountLimit, totalSizeLimit } = this.props;
    return (
      <div className="FilesUpload">
        <Dragger
          name="file"
          multiple={true}
          action={uploadUrl}
          headers={uploadHeaders}
          fileList={_.map(
            this.state.fileList,
            file =>
              ({
                uid: file.uid,
                size: file.size,
                name: getUploadItemDisplay(file),
                status: file.status,
                percent: file.percent,
                response: file.response,
                error: file.error,
                type: file.type,
              } as any),
          )}
          onChange={this.handleChange}
          onPreview={_.noop}
          onRemove={this.handleRemove as any}
          beforeUpload={this.beforeUpload}
          ref={this.draggerRef as any}
        >
          <p>
            <Icon type="upload" /> Drop files or <span style={{ color: "#157ff2" }}>browse</span> to
            upload
          </p>
        </Dragger>
        <FilesUploadFileCountLimitAlert
          limit={fileCountLimit}
          isVisible={this.state.isFileCountLimitAlertVisible}
          onClose={() => {
            this.setState({ isFileCountLimitAlertVisible: false });
          }}
        />
        <FilesUploadTotalSizeLimitAlert
          limit={totalSizeLimit}
          isVisible={this.state.isTotalSizeLimitAlertVisible}
          onClose={() => {
            this.setState({ isTotalSizeLimitAlertVisible: false });
          }}
        />
        {this.state.confirmFileRemoveModalDeferred && (
          <FilesUploadDeleteConfirmModal
            file={this.state.confirmFileRemoveModalDeferred.file}
            isVisible={this.state.confirmFileRemoveModalDeferred !== null}
            onCancel={() => {
              (this.state
                .confirmFileRemoveModalDeferred as IConfirmFileRemoveModalDeferred).resolve({
                isConfirmed: false,
              });
            }}
            onConfirm={() => {
              (this.state
                .confirmFileRemoveModalDeferred as IConfirmFileRemoveModalDeferred).resolve({
                isConfirmed: true,
              });
            }}
          />
        )}
      </div>
    );
  }

  private isAcceptedType = type => {
    return _.includes(_.values(this.props.acceptedFileTypes), type);
  };

  private getTotalSize = fileList =>
    _.reduce(
      fileList,
      (sum, { isExisting, status, type, size }) => {
        if (isExisting) {
          return sum + size;
        }
        if (status === "error") {
          return sum;
        }
        if (!this.isAcceptedType(type)) {
          return sum;
        }
        return sum + size;
      },
      0,
    );

  private isWithinFileCountLimit = fileList => {
    return fileList.length + this.state.fileList.length <= this.props.fileCountLimit;
  };

  private isWithinTotalSizeLimit = fileList => {
    return (
      this.getTotalSize(fileList) + this.getTotalSize(this.state.fileList) <=
      this.props.totalSizeLimit
    );
  };

  private beforeUpload = (file, fileList) => {
    // return false will prevent the file from being uploaded.
    // error handling will be done through state change or this.handleChange

    if (!this.isWithinFileCountLimit(fileList)) {
      this.setState({ isFileCountLimitAlertVisible: true });
      return false;
    } else if (!this.isWithinTotalSizeLimit(fileList)) {
      this.setState({ isTotalSizeLimitAlertVisible: true });
      return false;
    }

    if (!this.isAcceptedType(file.type)) {
      file.status = "error";
      return false;
    }
    return true;
  };

  private appendFile = file => {
    this.setState(
      state => ({ fileList: [...state.fileList, file] }),
      () => {
        this.props.onChange(this.state.fileList);
      },
    );
  };

  private updateFileWithUid = (uid, delta) => {
    const index = _.findIndex(this.state.fileList, { uid });
    if (index === -1) {
      return;
    }
    const file = this.state.fileList[index];
    this.setState({
      fileList: [
        ...this.state.fileList.slice(0, index),
        {
          ...file,
          ...delta,
        },
        ...this.state.fileList.slice(index + 1),
      ],
    });
    this.props.onChange(this.state.fileList);
  };

  private removeFileWithUid = uid => {
    const newFileList: IFileUpload[] = _.reject(this.state.fileList, { uid }) as IFileUpload[];
    this.props.onChange(newFileList);
    this.setState({ fileList: newFileList });
  };

  private handleChange = info => {
    const genericErrorMessage = `Upload failed.`;
    const genericErrorTooltip = null;
    const acceptableFileExtensions = _.keys(this.props.acceptedFileTypes).join(", ");
    const fileTypeErrorMessage = `Upload failed. The following file types are accepted: ${acceptableFileExtensions}.`;
    switch (info.file.status) {
      case undefined: {
        // ignore
        break;
      }
      case "uploading": {
        if (_.findIndex(this.state.fileList, { uid: info.file.uid }) === -1) {
          this.appendFile({
            ..._.pick(info.file, ["uid", "size", "status", "percent", "response", "error", "type"]),
            id: null,
            originalName: info.file.originFileObj.name,
            isExisting: false,
          });
        } else {
          this.updateFileWithUid(info.file.uid, {
            ..._.pick(info.file, ["status", "percent", "response", "error"]),
          });
        }
        break;
      }
      case "done": {
        if (info.file.response.status === "VIRUS") {
          this.updateFileWithUid(info.file.uid, {
            status: "error",
            error: genericErrorMessage,
            response: genericErrorTooltip,
            ..._.pick(info.file, ["percent"]),
          });
        } else {
          this.updateFileWithUid(info.file.uid, {
            id: info.file.response.id,
            ..._.pick(info.file, ["status", "percent", "response", "error"]),
          });
        }
        break;
      }
      case "error": {
        if (!this.isAcceptedType(info.file.type)) {
          this.appendFile({
            ..._.pick(info.file, ["uid", "size", "percent", "response", "type"]),
            status: "error",
            error: fileTypeErrorMessage,
            response: genericErrorTooltip,
            id: null,
            originalName: info.file.name,
            isExisting: false,
          });
          break;
        }

        console.error(`File Upload failed ${info.file.response.message}`); // tslint:disable-line no-console
        if (FILE_TYPE_SERVER_ERROR_REGEX.test(info.file.response.message)) {
          this.updateFileWithUid(info.file.uid, {
            ..._.pick(info.file, ["status", "percent"]),
            error: fileTypeErrorMessage,
            response: genericErrorTooltip,
          });
        } else {
          this.updateFileWithUid(info.file.uid, {
            ..._.pick(info.file, ["status", "percent"]),
            error: genericErrorMessage,
            response: genericErrorTooltip,
          });
        }
        break;
      }
    }
  };

  private confirmDelete = async file => {
    const { isConfirmed } = await this.showConfirmRemoveModal(file);
    return isConfirmed;
  };

  private showConfirmRemoveModal = (file): Promise<{ isConfirmed: boolean }> => {
    const deferred = { file } as {
      file: IFileUpload;
      resolve: (props: { isConfirmed: boolean }) => void;
    };
    const modalPromise = new Promise(resolve => {
      deferred.resolve = payload => {
        this.setState({ confirmFileRemoveModalDeferred: null }, () => {
          resolve(payload);
        });
      };
    });
    this.setState({ confirmFileRemoveModalDeferred: deferred });
    return modalPromise as Promise<{ isConfirmed: boolean }>;
  };

  private handleRemove = async ({ uid }) => {
    const file = _.find(this.state.fileList, { uid }) as IFileUpload;
    if (!file.id) {
      this.removeFileWithUid(file.uid);
      return true;
    } else if (await this.confirmDelete(file)) {
      fileAttachmentsApi.deleteFile(file.id).then(_.noop, error => {
        console.error("Failed to delete file", error); // tslint:disable-line no-console
      });
      this.removeFileWithUid(file.uid);
      return true;
    } else {
      return false;
    }
  };
}

export default FilesUpload;
