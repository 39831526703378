import { Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Redirect } from "react-router";
import { spinnerDelay } from "../../../../constants/constants";
import { SERVICE_BASE_URL, SERVICE_STATUS_ENDPOINT } from "../../../../utils/config";
import { isDataPortalServiceHttpError, isNetworkError } from "../../../../utils/errorUtils";
import { httpGet } from "../../../../utils/httpUtils";
import UnderMaintenancePage from "./UnderMaintenancePage";

interface IState {
  isInitializing: boolean;
  initializingError: string | null;
  isUnderMaintenance: boolean;
}

class UnderMaintenancePageContainer extends React.Component<{}, IState> {
  public state: IState = {
    isInitializing: true,
    initializingError: null,
    isUnderMaintenance: true,
  };

  public componentDidMount() {
    this.initialize();
  }

  public render() {
    if (navigator.onLine === false) {
      return <div>You are offline.</div>;
    }
    if (!this.state.isUnderMaintenance) {
      return <Redirect to={{ pathname: "/datasets" }} />;
    }
    return (
      <Spin spinning={this.state.isInitializing} tip={"Processing..."} delay={spinnerDelay}>
        <UnderMaintenancePage initializingError={this.state.initializingError} />
      </Spin>
    );
  }

  private initialize = async () => {
    this.setState({ isInitializing: true });
    try {
      const response = await httpGet(`${SERVICE_BASE_URL}${SERVICE_STATUS_ENDPOINT}`);
      if (_.get(response, "data") === "ok") {
        this.setState({ isUnderMaintenance: false });
      } else {
        this.setState({ initializingError: response.data });
      }
    } catch (error) {
      if (!isNetworkError(error) && !isDataPortalServiceHttpError(error)) {
        this.setState({ initializingError: error.message });
      }
    }
    this.setState({ isInitializing: false });
  };
}

export default UnderMaintenancePageContainer;
