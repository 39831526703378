import * as React from "react";
import "../../../../styles/sign-in-page.less";

const NotFoundPage = () => (
  <div className="NotFoundPage sign-in-page">
    <div className="title-container">
      <div className="title">The content is not found.</div>
    </div>
  </div>
);
export default NotFoundPage;
