import { Alert, Col, Form, Row, Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Field, reduxForm } from "redux-form";
import FormInput from "../form/FormInput/FormInput";
import FormTextArea from "../form/FormTextArea/FormTextArea";

interface IProps {
  handleSubmit: any;
  error: any;
  submitting: boolean;
  invalid: boolean;
}

class ProviderSubmitForm extends React.Component<IProps, {}> {
  public render() {
    const { handleSubmit, submitting, error } = this.props;
    return (
      <div className="ProviderSubmitForm">
        <Form onSubmit={handleSubmit}>
          <Spin spinning={submitting} tip={"Saving..."}>
            <Row className="top-level-row">
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={"Name"}
                  name="name"
                  component={FormInput}
                  required={true}
                  placeholder=""
                />
              </Col>
            </Row>
            <Row className="top-level-row">
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={"Website"}
                  name="website"
                  component={FormInput}
                  required={false}
                  placeholder=""
                />
              </Col>
            </Row>
            <Row className="top-level-row">
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={"Address"}
                  name="address"
                  component={FormInput}
                  required={true}
                  placeholder=""
                />
              </Col>
            </Row>
            <Row className="top-level-row">
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={"Contact name"}
                  name="contactName"
                  component={FormInput}
                  required={true}
                  placeholder=""
                />
              </Col>
            </Row>
            <Row gutter={20} className="top-level-row">
              <Col lg={15} md={15} sm={24}>
                <Field
                  label={"Contact email"}
                  name="contactEmail"
                  component={FormInput}
                  required={true}
                  placeholder=""
                  className="form-item-contact-email"
                />
              </Col>
              <Col lg={9} md={9} sm={24}>
                <Field
                  label={"Contact phone"}
                  name="contactPhone"
                  component={FormInput}
                  required={true}
                  placeholder=""
                  className="form-item-contact-phone"
                />
              </Col>
            </Row>
            <Row className="top-level-row">
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={"Description"}
                  name="description"
                  component={FormTextArea}
                  required={false}
                  placeholder=""
                />
              </Col>
            </Row>
          </Spin>
          {error && <Alert message={error} type="error" className="processing-error-banner" />}
        </Form>
      </div>
    );
  }
}

const validate = values => {
  const errors = {} as any;
  if (_.isEmpty(_.trim(values.name))) {
    errors.name = "Name is required";
  }
  if (_.isEmpty(_.trim(values.address))) {
    errors.address = "Address is required";
  }
  if (_.isEmpty(_.trim(values.contactName))) {
    errors.contactName = "Contact name is required";
  }
  if (_.isEmpty(_.trim(values.contactEmail))) {
    errors.contactEmail = "Contact email is required";
  }
  if (_.isEmpty(_.trim(values.contactPhone))) {
    errors.contactPhone = "Contact phone is required";
  }
  return errors;
};

export const formName = "ProviderSubmitForm";

export const initialValues = {
  name: "",
  address: "",
  website: "",
  contactName: "",
  contactEmail: "",
  contactPhone: "",
  description: "",
};

export default reduxForm({
  form: formName,
  validate,
})(ProviderSubmitForm);
