import Auth from "@aws-amplify/auth";
import * as _ from "lodash";

import actionTypes from "../actionTypes";

const confirmSignUpThunk = (username: string, code: string) => async dispatch => {
  dispatch({ type: actionTypes.CONFIRM_SIGN_UP_REQUEST });
  try {
    const data = await Auth.confirmSignUp(_.trim(username.toLowerCase()), _.trim(code));
    dispatch({
      type: actionTypes.CONFIRM_SIGN_UP_SUCCESS,
      data,
    });
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.CONFIRM_SIGN_UP_FAILURE,
      error: error.message ? error.message : error,
    });
    throw error;
  }
};

export default confirmSignUpThunk;
