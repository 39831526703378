import gql from "graphql-tag";
import { IGqlUserInput } from "../../../types/interfaces/IGqlUserInput";
import apolloClient from "../../apolloClient";

const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $name: String!) {
    updateUser(id: $id, input: { name: $name }) {
      name
      email
      id
      role
      status
      isTermsOfServiceAccepted
    }
  }
`;

const updateUser = async (userId: string, userInput: IGqlUserInput): Promise<any> => {
  const variables = {
    id: userId,
    name: userInput.name,
  };

  console.log("GraphQL updateUser", variables); // tslint:disable-line no-console

  const ret = await apolloClient.mutate({
    mutation: UPDATE_USER,
    variables,
  });
  return {
    user: (ret.data as any).updateUser,
  };
};

export default updateUser;
