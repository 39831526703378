import { connect } from "react-redux";
import { initialize, submit } from "redux-form";
import actionCreators from "../../../../actions/actionCreators";
import { IState as IStoreState } from "../../../../reducers/root";
import { formInitialValues, formName } from "./ProviderAddModal";
import ProviderAddModalContainer, {
  IDispatchProps,
  IStateProps,
} from "./ProviderAddModalContainer";

interface IOwnProps {
  isVisible: boolean;
  onSave: (IProvider) => void;
  onCancel: () => void;
}

const mapStateToProps = (state: IStoreState, ownProps: IOwnProps) => {
  const { isVisible } = ownProps;
  return {
    isVisible,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: IOwnProps) => {
  return {
    onInit: () => {
      dispatch(initialize(formName, formInitialValues));
    },
    onClickCreateButton: async () => {
      dispatch(submit(formName));
    },
    onSave: async providerData => {
      const createdProvider = await dispatch(actionCreators.createProvider(providerData));
      ownProps.onSave(createdProvider);
    },
    onCancel: () => {
      ownProps.onCancel();
    },
  };
};

const ConnectedProviderAddModal = connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderAddModalContainer);

export default ConnectedProviderAddModal;
