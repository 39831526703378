import { Alert, Button, Col, Form, Row, Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Field, reduxForm } from "redux-form";
import FormInput from "../../../common/form/FormInput/FormInput";

interface IProps {
  handleSubmit: any;
  error: any;
  submitting: boolean;
  invalid: boolean;
}

class SignInForm extends React.Component<IProps, {}> {
  public render() {
    const { handleSubmit, submitting, invalid, error } = this.props;
    return (
      <div className="SignInForm">
        <Form onSubmit={handleSubmit}>
          <Spin spinning={submitting} tip={"Signing in..."}>
            <Row className="form-item-row">
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={"Email"}
                  name="email"
                  component={FormInput}
                  placeholder=""
                  required={true}
                />
              </Col>
            </Row>
            <Row className="form-item-row">
              <Col lg={24} md={24} sm={24}>
                <Field
                  label={"Password"}
                  name="password"
                  component={FormInput}
                  type={"password"}
                  placeholder=""
                  required={true}
                />
              </Col>
            </Row>
          </Spin>
          {error && <Alert className="submission-error-banner" message={error} type="error" />}
          <Button
            className="primary-button"
            htmlType="submit"
            type="primary"
            disabled={submitting || invalid}
            loading={submitting}
          >
            Sign in
          </Button>
        </Form>
      </div>
    );
  }
}

const validate = values => {
  const errors = {} as any;
  if (_.isEmpty(_.trim(values.email))) {
    errors.email = "Email is required";
  }
  if (!_.includes(values.email, "@")) {
    errors.email = "Not a valid email";
  }
  if (_.isEmpty(values.password)) {
    errors.password = "Password is required";
  }
  return errors;
};

export default reduxForm({
  form: "SignInForm",
  validate,
})(SignInForm);
