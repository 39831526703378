import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import actionCreators from "../../../../actions/actionCreators";
import { IState as IStoreState } from "../../../../reducers/root";
import { ISignInData } from "../../../../types/interfaces/ISignInData";
import SignInPageContainer, { IDispatchProps, IStateProps } from "./SignInPageContainer";

interface IOwnProps extends RouteComponentProps<any> {}

const ConnectedSignInPage = connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(
  (state: IStoreState, ownProps: IOwnProps) => ({
    history: ownProps.history,
  }),
  (dispatch: any) => ({
    onInit: async () => {
      await dispatch(actionCreators.signOut());
    },
    onSubmit: async (signInData: ISignInData) => {
      await dispatch(actionCreators.signIn(signInData));
    },
  }),
)(SignInPageContainer);

export default withRouter(ConnectedSignInPage);
