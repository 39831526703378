import gql from "graphql-tag";
import * as _ from "lodash";
import apolloClient from "../../apolloClient";

const CREATE_EMLOYER_CANDIDATE = gql`
  mutation CreateEmployerCandidate($name: String!, $website: String, $address: String) {
    createEmployerCandidate(input: { name: $name, website: $website, address: $address }) {
      name
      website
      address
    }
  }
`;

const createEmployerCandidate = async (userId, name, website, address): Promise<any> => {
  const ret: any = await apolloClient
    .mutate({
      mutation: CREATE_EMLOYER_CANDIDATE,
      variables: { name, website, address },
    })
    .catch(error => {
      throw new Error(error.graphQLErrors.join(",") || "Failed to create employer candidate.");
    });
  if (!_.isEmpty(ret.errors)) {
    throw new Error(
      `Failed to create employer dataset: ${_.map(ret.errors, "message").join(", ")}`,
    );
  }
  return ret.data.createEmployerCandidate;
};

export default createEmployerCandidate;
