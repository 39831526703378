import * as _ from "lodash";

export const isNetworkError = error => error.message.search(/Network error/i) !== -1;

export const isDataPortalServiceError = error =>
  _.includes(error.message, "Data Portal Service not available");

export const isDataPortalServiceHttpError = error => {
  const message = _.get(error, "message");
  if (_.isString(message) && _.includes(error.message, "Request failed with status code 503")) {
    return true;
  }
  return _.get(error, "response.data") === "Data Portal Service not available";
};
