import { Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Redirect } from "react-router";
import { spinnerDelay } from "../../../../constants/constants";
import SignUpStatus from "../../../../types/enums/SignUpStatus";
import { SERVICE_BASE_URL, SERVICE_STATUS_ENDPOINT } from "../../../../utils/config";
import { httpGet } from "../../../../utils/httpUtils";
import sessionStore from "../../../../utils/sessionStore";
import ApprovalPendingPage from "./ApprovalPendingPage";

interface IState {
  isInitializing: boolean;
  initializingError: string | null;
  isPending: boolean;
  isUnderMaintenance: boolean;
}

const getIsServiceUp = async () => {
  const response = await httpGet(`${SERVICE_BASE_URL}${SERVICE_STATUS_ENDPOINT}`).catch(_.noop);
  return _.get(response, "data") === "ok";
};

class ApprovalPendingPageContainer extends React.Component<{}, IState> {
  public state: IState = {
    isInitializing: true,
    initializingError: null,
    isPending: true,
    isUnderMaintenance: false,
  };

  public componentDidMount() {
    this.initialize();
  }

  public render() {
    if (this.state.isUnderMaintenance) {
      return <Redirect to={{ pathname: "/under_maintenance" }} />;
    }
    if (!this.state.isPending) {
      return <Redirect to={{ pathname: "/datasets" }} />;
    }
    return (
      <Spin spinning={this.state.isInitializing} tip={"Processing..."} delay={spinnerDelay}>
        <ApprovalPendingPage initializingError={this.state.initializingError} />
      </Spin>
    );
  }

  private initialize = async () => {
    try {
      this.setState({ isInitializing: true });

      // check if the service is down, if so, redirect to the under_maintenance page.
      const isServiceUp = await getIsServiceUp();
      if (!isServiceUp) {
        this.setState({ isUnderMaintenance: true });
        return;
      }

      // check if the user has been approved, if so, redirect to the datasets page.
      const userStatus = await sessionStore.getUserStatus();
      this.setState({
        isPending:
          userStatus !== undefined &&
          (userStatus.signUpStatus === SignUpStatus.pending ||
            userStatus.signUpStatus === SignUpStatus.rejected),
      });
    } catch (error) {
      this.setState({ initializingError: error.message });
    } finally {
      this.setState({ isInitializing: false });
    }
  };
}

export default ApprovalPendingPageContainer;
