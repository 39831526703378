import * as H from "history";
import * as React from "react";
import { IDatasetsLoadingParams } from "../../../../types/interfaces/IDatasetsLoadingParams";
import { IGqlUser } from "../../../../types/interfaces/IGqlUser";
import { getParamsFromQueryString } from "../../../../utils/queryStringUtils";
import DatasetsListPage from "./DatasetsListPage/DatasetsListPage";
import DatasetsProvider from "./DatasetsProvider/ConnectedDatasetsProvider";

export interface IStateProps {
  history: H.History;
  user: IGqlUser | null;
  queryString: string;
}

export interface IDispatchProps {
  onDeleteDatasets: (datasetIds: string[]) => Promise<void>;
  onSetIsEmployerEditModalVisible: (isVisible: boolean) => void;
}

interface IProps extends IStateProps, IDispatchProps {}

const DatasetsListPageContainer = ({
  history,
  user,
  queryString,
  onDeleteDatasets,
  onSetIsEmployerEditModalVisible,
}: IProps) => {
  const queryParams: IDatasetsLoadingParams = getParamsFromQueryString(queryString);
  return (
    <DatasetsProvider queryParams={queryParams}>
      {({ datasets, isDatasetsLoading, datasetsLoadingError, onRequestLoadDatasets }) => (
        <DatasetsListPage
          history={history}
          user={user}
          queryParams={queryParams}
          datasets={datasets}
          isLoading={isDatasetsLoading}
          loadingError={datasetsLoadingError}
          onDeleteDatasets={onDeleteDatasets}
          onSetIsEmployerEditModalVisible={onSetIsEmployerEditModalVisible}
          onRequestLoadDatasets={onRequestLoadDatasets}
        />
      )}
    </DatasetsProvider>
  );
};

export default DatasetsListPageContainer;
