import gql from "graphql-tag";
import * as _ from "lodash";

import apolloClient from "../../apolloClient";

const DELETE_DATASETSS = gql`
  mutation deleteDatasets($ids: [ID]!) {
    deleteDatasets(ids: $ids)
  }
`;

const deleteDatasets = async (ids: string[]): Promise<{ ids: string[] }> => {
  const variables = {
    ids,
  };

  console.log("GraphQL deleteDatasets", variables); // tslint:disable-line no-console

  const ret = await apolloClient.mutate({
    mutation: DELETE_DATASETSS,
    variables,
  });

  if (!_.isEmpty(ret.errors) || ret.data === undefined) {
    throw new Error("Failed to delete datasets");
  }

  return ret.data.deleteDatasets;
};

export default deleteDatasets;
