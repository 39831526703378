import { connect } from "react-redux";
import { IState, selectors } from "../../../../../../reducers/root";
import DatasetsTable from "./DatasetsTable";

const mapStateToProps = (state: IState, ownProps: any) => ({
  ...ownProps,
  employer: selectors.getEmployer(state),
});

const ConnectedProviderSelect = connect(mapStateToProps)(DatasetsTable);

export default ConnectedProviderSelect;
