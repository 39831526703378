import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import actionCreators from "../../../../actions/actionCreators";
import actionTypes from "../../../../actions/actionTypes";
import { IState, selectors } from "../../../../reducers/root";
import AppHeader from "./AppHeader";

const mapStateToProps = (state: IState, ownProps: any) => ({
  location: ownProps.location,
  history: ownProps.history,
  user: selectors.getUser(state),
  employer: selectors.getEmployer(state),
  isEmployerEditModalVisible: selectors.getIsEmployerEditModalVisible(state),
});

const mapDispatchToProps = dispatch => ({
  onSetIsEmployerEditModalVisible: isVisible => {
    dispatch({
      type: isVisible ? actionTypes.SHOW_EMPLOYER_EDIT_MODAL : actionTypes.HIDE_EMPLOYER_EDIT_MODAL,
    });
  },
  onSignOut: async () => {
    return dispatch(actionCreators.signOut());
  },
  onUpdateContactInfo: async () => {
    return;
  },
  onUpdateEmployerInfo: async () => {
    await dispatch(actionCreators.fetchEmployer());
  },
});

const ConnectedAppHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppHeader);

export default withRouter(ConnectedAppHeader);
