import Auth from "@aws-amplify/auth";
import * as _ from "lodash";

import { ISignInData } from "../../types/interfaces/ISignInData";
import sessionStore from "../../utils/sessionStore";
import actionTypes from "../actionTypes";

const signInThunk = (signInData: ISignInData) => async dispatch => {
  const username = signInData.email;
  const password = signInData.password;

  dispatch({ type: actionTypes.SIGN_IN_REQUEST });
  sessionStore.clearSession();
  try {
    const cognitoUser = await Auth.signIn(_.trim(username.toLowerCase()), password);
    dispatch({
      type: actionTypes.SIGN_IN_SUCCESS,
      cognitoUser,
    });
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.SIGN_IN_FAILURE,
      error: error.message,
    });
    throw error;
  }
};

export default signInThunk;
