import * as React from "react";
import { SubmissionError } from "redux-form";
import "../../../../styles/edit-modal.less";
import ContactInfoEditModal from "./ContactInfoEditModal";

export interface IStateProps {
  user: any | null;
  isVisible: boolean;
}

export interface IDispatchProps {
  onCancel: () => void;
  onInit: () => Promise<void>;
  onClickSubmitButton: () => Promise<void>;
  onSubmit: (userName: string) => Promise<void>;
}

interface IProps extends IStateProps, IDispatchProps {}

interface IState {
  isInitializing: boolean;
  initializationError: string | null;
  isSaving: boolean;
}

export default class ContactInfoEditModalContainer extends React.Component<IProps, IState> {
  public state: IState = {
    isInitializing: true,
    initializationError: null,
    isSaving: false,
  };

  public componentDidMount() {
    this.initialize();
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    if (!this.props.isVisible && prevProps.isVisible) {
      this.initialize();
    }
  }

  public render() {
    return (
      <ContactInfoEditModal
        user={this.props.user}
        isVisible={this.props.isVisible}
        onCancel={this.props.onCancel}
        onClickSubmitButton={this.props.onClickSubmitButton}
        onSubmit={async name => {
          this.setState({ isSaving: true });
          try {
            await this.props.onSubmit(name);
            this.setState({ isSaving: false });
          } catch (error) {
            this.setState({ isSaving: false });
            throw new SubmissionError({
              _error: `Failed to update contact info. ${error.message}`,
            });
          }
        }}
        isInitializing={this.state.isInitializing}
        initializationError={this.state.initializationError}
        isSaving={this.state.isSaving}
      />
    );
  }

  private initialize = async () => {
    this.setState({ isInitializing: true });
    try {
      await this.props.onInit();
    } catch (error) {
      this.setState({ initializationError: error.message });
    }
    this.setState({ isInitializing: false });
  };
}
