import Cookies from "js-cookie";
import * as React from "react";
import { Link } from "react-router-dom";
import "./CookiePrivacyPopup.less";

interface IState {
  isVisible: boolean;
}

class CookiePrivacyPopup extends React.Component<{}, IState> {
  public state: IState = {
    isVisible: true,
  };

  public componentDidMount() {
    if (Cookies.get("cookie_notice_accepted")) {
      this.setState({ isVisible: false });
    }
  }

  public handleClick = event => {
    this.setState({ isVisible: false });
    Cookies.set("cookie_notice_accepted", true, { expires: 7 });
  };

  public render() {
    return !this.state.isVisible ? null : (
      <div className="cookiePolicy">
        <div className="contentWrapper">
          <div className="cookiePolicy__inner">
            <div className="cookiePolicy__info">
              <div className="cookiePolicy__text">
                <p>This website uses cookies to ensure you get the best experience.</p>
                <p>
                  To view our privacy policy, please click{" "}
                  <Link to="/privacy_policy" target="_blank">
                    here
                  </Link>
                  .
                </p>
              </div>
              <div className="cookiePolicy__more">
                <Link to="/cookie_policy" className=" arrowLink" target="_blank">
                  Learn More
                  <span className="ico">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <path d="M8.1 2c-.5.5-.5 1.3 0 1.8L11 6.7H1.3C.6 6.7.1 7.3.1 8s.6 1.3 1.3 1.3H11l-2.9 2.9c-.5.5-.5 1.3 0 1.8.2.2.6.4.9.4s.7-.1.9-.4l6-6-6-6c-.5-.5-1.3-.5-1.8 0z" />
                    </svg>
                  </span>
                </Link>
              </div>
            </div>
            <div className="cookiePolicy__cta">
              <button className="cookiePolicy__button js-trigger" onClick={this.handleClick}>
                Got It
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CookiePrivacyPopup;
