import gql from "graphql-tag";
import * as _ from "lodash";

import apolloClient from "../../apolloClient";

const DELETE_PROVIDER_AND_DATASETS = gql`
  mutation deleteProviderAndDatasets($id: ID!) {
    deleteProvider(id: $id, isDeleteDatasets: true)
  }
`;

const deleteProviderAndDatasets = async (id: string): Promise<{ id: string }> => {
  const variables = { id };
  const ret: any = await apolloClient
    .mutate({ mutation: DELETE_PROVIDER_AND_DATASETS, variables })
    .catch(error => {
      throw new Error(
        error.graphQLErrors.join(",") || `Failed to delete provider and datasets ${id}.`,
      );
    });

  if (!_.isEmpty(ret.errors)) {
    throw new Error(`Failed update employer ${id}, ${_.map(ret.errors, "message").join(",")}`);
  }

  return { id };
};

export default deleteProviderAndDatasets;
