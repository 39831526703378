import Auth from "@aws-amplify/auth";
import * as _ from "lodash";

import actionTypes from "../actionTypes";

const sendResetPasswordMailThunk = (username: string) => async dispatch => {
  dispatch({ type: actionTypes.SEND_RESET_PASSWORD_MAIL_REQUEST });
  try {
    await Auth.forgotPassword(_.trim(username.toLowerCase()));
    dispatch({
      type: actionTypes.SEND_RESET_PASSWORD_MAIL_SUCCESS,
      username,
    });
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.SEND_RESET_PASSWORD_MAIL_FAILURE,
      error: error.message,
    });
    throw error;
  }
};

export default sendResetPasswordMailThunk;
