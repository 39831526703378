import { message } from "antd";
import * as H from "history";
import * as _ from "lodash";
import { parse } from "qs";
import * as React from "react";
import { SubmissionError } from "redux-form";
import { IDataset } from "../../../../types/interfaces/IDataset";
import { IDatasetInput } from "../../../../types/interfaces/IDatasetInput";
import DatasetEditPage from "./DatasetEditPage";

export interface IStateProps {
  location: H.Location;
  history: H.History;
}

export interface IDispatchProps {
  onInit: (datasetId: string) => Promise<IDataset>;
  onSubmit: (datasetId: string, datasetInput: IDatasetInput) => Promise<void>;
}

interface IProps extends IStateProps, IDispatchProps {}

interface IState {
  isLoading: boolean;
  loadingError: string | null;
}

const getDatasetId = location => {
  const queryString = location.search.replace(/^\?/, "");
  return _.get(parse(queryString), "id", null);
};

export default class DatasetEditPageContainer extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: true,
    loadingError: null,
  };

  public async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const datasetId = getDatasetId(this.props.location);
      await this.props.onInit(datasetId);
    } catch (error) {
      this.setState({ loadingError: error.message });
    }
    this.setState({ isLoading: false });
  }

  public render() {
    const { history } = this.props;
    const datasetId = getDatasetId(this.props.location);
    return (
      <DatasetEditPage
        isLoading={this.state.isLoading}
        loadingError={this.state.loadingError}
        onCancel={() => {
          history.push(`/dataset_details?id=${datasetId}`);
        }}
        onSubmit={async (data: IDatasetInput) => {
          try {
            await this.props.onSubmit(datasetId, data);
            message.success("Dataset saved.");
            history.push(`/datasets`);
          } catch (error) {
            throw new SubmissionError({
              email: error.code === "UsernameExistsException" ? error.message : undefined,
              _error: `Failed to submit dataset. ${error.message}`,
            });
          }
        }}
      />
    );
  }
}
