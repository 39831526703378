import * as _ from "lodash";
import * as React from "react";
import { SubmissionError } from "redux-form";
import "../../../../../../styles/edit-modal.less";
import SanitizedStatus from "../../../../../../types/enums/SanitizedStatus";
import {
  IDatasetIdNameDecision,
  IGqlProviderWithDatasets,
} from "../../../../../../types/interfaces/IGqlProviderWithDatasets";
import { IGqlUser, IGqlUserRole } from "../../../../../../types/interfaces/IGqlUser";
import { IProviderInput } from "../../../../../../types/interfaces/IProviderInput";
import ProviderDeleteConfirmModal from "./ProviderDeleteConfirmModal/ProviderDeleteConfirmModal";
import ProviderEditModal from "./ProviderEditModal";

export interface IStateProps {
  providerId: string | null;
  user: IGqlUser | null;
}

export interface IDispatchProps {
  onReset: (providerId: string | null) => Promise<IGqlProviderWithDatasets | null>;
  onClickSaveButton: () => Promise<void>;
  onSave: (id: string | undefined, provider: IProviderInput) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  onDone: () => void;
}

interface IProps extends IStateProps, IDispatchProps {}

interface IState {
  provider: IGqlProviderWithDatasets | null;
  isInitializing: boolean;
  initializationError: string | null;
  isSaving: boolean;
  isDeleting: boolean;
  deletionError: string | null;
  confirmModalDeferred: any | null;
}

export default class ProviderEditModalContainer extends React.Component<IProps, IState> {
  public state: IState = {
    provider: null,
    isInitializing: false,
    initializationError: null,
    isSaving: false,
    isDeleting: false,
    deletionError: null,
    confirmModalDeferred: null,
  };

  private _isMounted: boolean = false; // tslint:disable-line variable-name

  public componentDidMount(): void {
    this._isMounted = true;
    if (this.props.providerId) {
      this.reset(this.props.providerId).then(_.noop);
    }
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    if (this.props.providerId !== prevProps.providerId) {
      this.reset(this.props.providerId).then(_.noop);
    }
  }

  public componentWillUnmount(): void {
    this._isMounted = false;
  }

  public render() {
    const { providerId, user } = this.props;
    const {
      confirmModalDeferred,
      isInitializing,
      initializationError,
      isSaving,
      isDeleting,
      deletionError,
    } = this.state;

    const canDelete = this.state.provider
      ? (user && user.role !== IGqlUserRole.dataScout) ||
        _.isEmpty(
          _.filter(
            this.state.provider.datasets,
            (d: IDatasetIdNameDecision) => d.decision.sanitizedStatus !== SanitizedStatus.submitted,
          ),
        )
      : false;

    return (
      <>
        <ProviderEditModal
          isVisible={providerId !== null}
          onClickSaveButton={this.props.onClickSaveButton}
          onSave={async values => {
            if (providerId === null) {
              throw new SubmissionError({
                _error: `Provider ID is null`,
              });
            }

            try {
              this.setState({ isSaving: true });
              await this.props.onSave(providerId, values);
            } catch (error) {
              throw new SubmissionError({
                _error: `Failed to update provider info. ${error.message}`,
              });
            } finally {
              this.safeSetState({ isSaving: false });
            }
          }}
          onClickDeleteButton={async () => {
            const { isConfirmed } = (await this.showDeleteConfirmModal()) as any;
            if (isConfirmed) {
              try {
                this.setState({ isDeleting: true });
                await this.props.onDelete(providerId as string);
              } catch (error) {
                this.setState({ deletionError: error.message });
              } finally {
                this.safeSetState({ isDeleting: false });
              }
            }
          }}
          onCancel={this.props.onDone}
          isInitializing={isInitializing}
          initializationError={initializationError}
          isSaving={isSaving}
          isDeleting={isDeleting}
          deletionError={deletionError}
          canDelete={canDelete}
        />
        {providerId && confirmModalDeferred && (
          <ProviderDeleteConfirmModal
            providerId={providerId}
            isVisible={confirmModalDeferred !== null}
            onCancel={() => {
              confirmModalDeferred.resolve({ isConfirmed: false });
            }}
            onConfirm={() => {
              confirmModalDeferred.resolve({ isConfirmed: true });
            }}
          />
        )}
      </>
    );
  }
  private showDeleteConfirmModal() {
    if (this.state.confirmModalDeferred !== null) {
      return Promise.resolve({ isConfirmed: false });
    }
    const deferred = {} as any;
    const modalPromise = new Promise((resolve, reject) => {
      deferred.resolve = payload => {
        this.setState({ confirmModalDeferred: null }, () => {
          resolve(payload);
        });
      };
      deferred.reject = () => {
        this.setState({ confirmModalDeferred: null }, reject);
      };
    });
    this.setState({ confirmModalDeferred: deferred });
    return modalPromise;
  }

  private safeSetState(args) {
    if (this._isMounted) {
      this.setState(current => ({ ...current, ...args }));
    }
  }

  private reset = async (providerId: string | null) => {
    this.setState({ isInitializing: true });
    try {
      const provider: IGqlProviderWithDatasets | null = await this.props.onReset(providerId);
      this.setState({ provider });
    } catch (error) {
      this.setState({ initializationError: error.message });
    }
    this.setState({ isInitializing: false });
  };
}
