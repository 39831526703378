import Auth from "@aws-amplify/auth";
import * as _ from "lodash";

import actionTypes from "../actionTypes";

const resendVerificationCodeThunk = (username: string) => async dispatch => {
  dispatch({ type: actionTypes.RESEND_SIGN_UP_CODE_MAIL_REQUEST });
  try {
    const user = await Auth.resendSignUp(_.trim(username.toLowerCase()));
    dispatch({
      type: actionTypes.RESEND_SIGN_UP_CODE_MAIL_SUCCESS,
      user,
    });
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.RESEND_SIGN_UP_CODE_MAIL_FAILURE,
      error: error.message,
    });
    throw error;
  }
};

export default resendVerificationCodeThunk;
