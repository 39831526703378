import { IDatasetInput } from "../../types/interfaces/IDatasetInput";
import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import actionTypes from "../actionTypes";

const createDatasetThunk = (datasetInput: IDatasetInput) => async dispatch => {
  dispatch({ type: actionTypes.CREATE_DATASET_REQUEST });
  try {
    const dataset = await graphqlApi.createDataset(datasetInput);
    dispatch({
      type: actionTypes.CREATE_DATASET_SUCCESS,
      dataset,
    });
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.CREATE_DATASET_FAILURE,
      error: error.message ? error.message : error,
    });
    throw new Error(`Failed to create dataset, ${error.message}`);
  }
};

export default createDatasetThunk;
