import * as React from "react";
import "../../../../styles/edit-modal.less";
import { SERVICE_BASE_URL, TOS_ENDPOINT } from "../../../../utils/config";
import { httpGet } from "../../../../utils/httpUtils";

interface IProps {
  children: (
    args: {
      isLoading: boolean;
      loadingError: string | null;
      tos: any;
    },
  ) => JSX.Element;
}

interface IState {
  isLoading: boolean;
  loadingError: string | null;
  tos: any;
}

class TermsOfServiceProvider extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: true,
    loadingError: null,
    tos: null,
  };

  public componentDidMount() {
    (async () => {
      this.setState({ isLoading: true });
      try {
        const { data: tos } = await httpGet(`${SERVICE_BASE_URL}${TOS_ENDPOINT}`);
        this.setState({ tos });
      } catch (error) {
        this.setState({ loadingError: error.message });
      }
      this.setState({ isLoading: false });
    })();
  }

  public render() {
    return <div>{this.props.children(this.state)}</div>;
  }
}

export default TermsOfServiceProvider;
