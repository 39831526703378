import { Alert, Layout, Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Link } from "react-router-dom";
import { spinnerDelay } from "../../../../constants/constants";
import "../../../../styles/sign-in-page.less";
import { ISignInData } from "../../../../types/interfaces/ISignInData";
import LegalFooter from "../../common/LegalFooter/LegalFooter";
import SignInForm from "./SignInForm/SignInForm";

const { Footer, Content } = Layout;

const SignInPage = ({
  isInitializing,
  initializationError,
  onSubmit,
}: {
  isInitializing: boolean;
  initializationError: string | null;
  onSubmit: (values: ISignInData) => Promise<void>;
}) => (
  <main className="SignInPage sign-in-page">
    <Layout>
      <Content>
        <div className="title-container">
          <div className="title">Sign in</div>
          {initializationError && (
            <Alert
              className="initialization-error-banner"
              message={initializationError}
              type="error"
            />
          )}
        </div>
        <Spin
          spinning={isInitializing || !_.isEmpty(initializationError)}
          indicator={initializationError ? <div /> : undefined}
          tip={initializationError ? "" : "Initializing..."}
          delay={spinnerDelay}
        >
          <SignInForm
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={onSubmit}
          />
        </Spin>
        <p className="tip">
          Need an account? <Link to="/sign_up">Sign up</Link>
        </p>
        <p className="tip">
          <Link className="forgot-password-link" to="/forgot_password">
            Forgot Password?
          </Link>
        </p>
      </Content>
      <Footer style={{ padding: 0 }}>
        <LegalFooter />
      </Footer>
    </Layout>
  </main>
);

export default SignInPage;
