import { History } from "history";
import * as React from "react";
import { SubmissionError } from "redux-form";
import { ISignUpData } from "../../../../../../types/interfaces/ISignUpData";
import { REACT_APP_IS_TOS_ALWAYS_HIDDEN } from "../../../../../../utils/config";
import TermsOfServiceModal from "../../../../common/TermsOfServiceModal/TermsOfServiceModal";
import SignUpPage from "./SignUpStep";

export interface IStateProps {
  history: History;
}

export interface IDispatchProps {
  onInit: () => Promise<void>;
  onSubmit: (data: ISignUpData) => Promise<void>;
}

interface IProps extends IStateProps, IDispatchProps {}

interface IState {
  isInitializing: boolean;
  initializationError: string | null;
  isProcessing: boolean;
  modalDeferred: any | null;
}

export default class SignUpPageContainer extends React.Component<IProps, IState> {
  public state: IState = {
    isInitializing: true,
    initializationError: null,
    isProcessing: false,
    modalDeferred: null,
  };

  public componentDidMount() {
    this.initialize();
  }

  public render() {
    const { history } = this.props;
    const { modalDeferred } = this.state;
    return (
      <>
        <SignUpPage
          isInitializing={this.state.isInitializing}
          initializationError={this.state.initializationError}
          isSubmitting={this.state.isProcessing}
          onSubmit={async (values: ISignUpData) => {
            await this.showTermsOfService();
            this.setState({ isProcessing: true });
            try {
              await this.props.onSubmit(values);
              this.setState({ isProcessing: false });
              history.push(`/sign_up/verify?user=${encodeURIComponent(values.email)}`);
            } catch (error) {
              this.setState({ isProcessing: false });
              throw new SubmissionError({
                email: error.code === "UsernameExistsException" ? error.message : undefined,
                _error: `Failed to sign up. ${error.message}`,
              });
            }
          }}
        />
        <TermsOfServiceModal
          isVisible={!REACT_APP_IS_TOS_ALWAYS_HIDDEN && modalDeferred !== null}
          onAccept={async () => {
            modalDeferred.resolve();
          }}
        />
      </>
    );
  }

  private initialize = async () => {
    this.setState({ isInitializing: true });
    try {
      await this.props.onInit();
    } catch (error) {
      this.setState({ initializationError: error.message });
    }
    this.setState({ isInitializing: false });
  };

  private showTermsOfService = () => {
    if (this.state.modalDeferred !== null) {
      return Promise.resolve(null);
    }
    const deferred = {} as any;
    const ret = new Promise(resolve => {
      deferred.resolve = () => {
        this.setState({ modalDeferred: null }, resolve);
      };
    });
    this.setState({ modalDeferred: deferred });
    return ret;
  };
}
