import Amplify from "@aws-amplify/core";
import * as React from "react";
import { ApolloProvider } from "react-apollo";
import { Redirect, Route, Switch } from "react-router";
import { HashRouter } from "react-router-dom";
import apolloClient from "../../utils/apolloClient";
import { amplifyConfig } from "../../utils/config";
import "./App.less";
import AppHeader from "./common/AppHeader/ConnectedAppHeader";
import CookiePrivacyPopup from "./common/CookiePrivacyPopup/CookiePrivacyPopup";
import PrivateRoute from "./common/PrivateRoute/ConnectedPrivateRoute";
import ApprovalPendingPage from "./pages/ApprovalPendingPage/ApprovalPendingPageContainer";
import CookiePolicyPage from "./pages/CookiePolicyPage/CookiePolicyPage";
import DatasetAddPage from "./pages/DatasetAddPage/ConnectedDatasetAddPage";
import DatasetDetailsPage from "./pages/DatasetDetailsPage/ConnectedDatasetDetailsPage";
import DatasetEditPage from "./pages/DatasetEditPage/ConnectedDatasetEditPage";
import DatasetsListPage from "./pages/DatasetsListPage/ConnectedDatasetsListPage";
import EmployerInfoSubmitPage from "./pages/EmployerInfoSubmitPage/ConnectedEmployerInfoSubmitPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import SignInPage from "./pages/SignInPage/ConnectedSignInPage";
import SignUpPage from "./pages/SignUpPage/SignUpPage";
import TermsOfServicePage from "./pages/TermsOfServicePage/TermsOfServicePage";
import UnderMaintenancePage from "./pages/UnderMaintenancePage/UnderMaintenancePageContainer";

Amplify.configure(amplifyConfig);

interface IProps {
  onInit: () => Promise<void>;
}

interface IState {
  isInitializing: boolean;
}

class App extends React.Component<IProps, IState> {
  public state: IState = {
    isInitializing: true,
  };

  public componentDidMount() {
    this.initialize();
  }

  public initialize = async () => {
    this.setState({ isInitializing: true });
    await this.props.onInit();
    this.setState({ isInitializing: false });
  };

  public render() {
    return (
      <ApolloProvider client={apolloClient}>
        <div className="App">
          <HashRouter>
            <div>
              <AppHeader />
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/datasets" />} />
                <Route exact path="/sign_in" component={SignInPage} />
                <Route exact path="/sign_up/employer_info" component={EmployerInfoSubmitPage} />
                <Route path="/sign_up" component={SignUpPage} />
                <Route path="/forgot_password" component={ForgotPasswordPage} />
                <Route exact path="/approval_pending" component={ApprovalPendingPage} />
                <Route exact path="/under_maintenance" component={UnderMaintenancePage} />
                <PrivateRoute exact path="/datasets" component={DatasetsListPage} />
                <PrivateRoute exact path="/dataset_submit" component={DatasetAddPage} />
                <PrivateRoute exact path="/dataset_details" component={DatasetDetailsPage} />
                <PrivateRoute exact path="/dataset_edit" component={DatasetEditPage} />
                <Route exact path="/privacy_policy" component={PrivacyPolicyPage} />
                <Route exact path="/cookie_policy" component={CookiePolicyPage} />
                <Route exact path="/terms_of_service" component={TermsOfServicePage} />
                <Route path="/404" component={NotFoundPage} />
                <Redirect to="/404" />
              </Switch>
              <CookiePrivacyPopup />
            </div>
          </HashRouter>
        </div>
      </ApolloProvider>
    );
  }
}

export default App;
