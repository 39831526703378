import { Alert, Button, Col, Form, Row } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Field, reduxForm } from "redux-form";
import validatePassword from "../../../../../../../utils/validatePassword";
import FormInput from "../../../../../common/form/FormInput/FormInput";
import FormPassword from "../../../../../common/form/FormPassword/FormPassword";

interface IProps {
  handleSubmit: any;
  error: any;
  submitting: boolean;
  invalid: boolean;
}

class ResetPasswordForm extends React.Component<IProps, {}> {
  public render() {
    const { handleSubmit, error, submitting, invalid } = this.props;
    return (
      <div className="ResetPasswordForm">
        <Form onSubmit={handleSubmit}>
          <Row className="form-item-row">
            <Col lg={24} md={24} sm={24}>
              <Field
                label={"Verification code"}
                name="code"
                component={FormInput}
                required={true}
                placeholder=""
              />
            </Col>
          </Row>
          <Row className="form-item-row">
            <Col lg={24} md={24} sm={24}>
              <Field
                label={"New password"}
                name="newPassword"
                component={FormPassword}
                type={"password"}
                required={true}
                placeholder=""
              />
            </Col>
          </Row>
          <Row className="form-item-row">
            <Col lg={24} md={24} sm={24}>
              <Field
                label={"Enter new password again"}
                name="newPassword2"
                component={FormInput}
                type={"password"}
                required={true}
                placeholder=""
              />
            </Col>
          </Row>
          {error && <Alert className="submission-error-banner" message={error} type="error" />}
          <Button
            className="primary-button"
            htmlType="submit"
            type="primary"
            disabled={submitting || invalid}
          >
            Change password
          </Button>
        </Form>
      </div>
    );
  }
}

const validate = values => {
  const errors = {} as any;
  if (_.isEmpty(values.code)) {
    errors.code = "Code cannot be empty";
  }
  if (_.isEmpty(values.newPassword)) {
    errors.newPassword = "New password cannot be empty";
  }
  if (!_.isEmpty(validatePassword(values.password))) {
    errors.password = "Password is not valid";
  }
  if (values.newPassword !== values.newPassword2) {
    errors.newPassword2 = "Passwords don't match";
  }
  return errors;
};

export default reduxForm({
  form: "ResetPasswordForm",
  validate,
})(ResetPasswordForm);
