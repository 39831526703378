import { connect } from "react-redux";
import actionCreators from "../../../../../actions/actionCreators";
import { IState as IStoreState, selectors } from "../../../../../reducers/root";
import { IDatasetsLoadingParams } from "../../../../../types/interfaces/IDatasetsLoadingParams";
import DatasetsProvider, { IDispatchProps, IStateProps } from "./DatasetsProvider";

export interface IOwnProps {
  queryParams: IDatasetsLoadingParams;
}

const mapStateToProps = (state: IStoreState, props: IOwnProps) => {
  return {
    datasets: selectors.getDatasets(state),
    datasetsTargetVersion: selectors.getDatasetsTargetVersion(state),
    datasetsLoadingVersion: selectors.getDatasetsLoadingVersion(state),
    datasetsCurrentVersion: selectors.getDatasetsCurrentVersion(state),
    isDatasetsLoading: selectors.getIsDatasetsLoading(state),
    datasetsLoadingError: selectors.getDatasetsLoadingError(state),
    queryParams: props.queryParams,
  };
};

const mapDispatchToProps = dispatch => ({
  onRequestLoadDatasets: async (params: IDatasetsLoadingParams) => {
    try {
      await dispatch(actionCreators.fetchDatasetsWithParams(params));
    } catch (error) {
      // no-op, error handled through redux and mapStateToProps ↑
    }
  },
});

const ConnectedDatasetsProvider = connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(
  mapStateToProps,
  mapDispatchToProps,
)(DatasetsProvider);

export default ConnectedDatasetsProvider;
