import { Dispatch } from "redux";
import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import sessionStore from "../../utils/sessionStore";
import actionTypes from "../actionTypes";

const acceptTermsOfServiceThunk = (version: number) => async (dispatch: Dispatch) => {
  const userId = await sessionStore.getUserId();
  if (userId === null) {
    throw new Error("Cannot accept terms of service for user, missing user ID");
  }
  dispatch({ type: actionTypes.ACCEPT_TERMS_OF_SERVICE_REQUEST });
  try {
    const { userId: updated } = await graphqlApi.acceptTermsOfService(userId, version);
    dispatch({
      type: actionTypes.ACCEPT_TERMS_OF_SERVICE_SUCCESS,
      userId: updated,
    });
    return updated;
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.ACCEPT_TERMS_OF_SERVICE_FAILURE,
      error: error.message,
    });
    throw error;
  }
};

export default acceptTermsOfServiceThunk;
