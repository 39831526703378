import Auth from "@aws-amplify/auth";
import apolloClient from "../../utils/apolloClient";
import sessionStore from "../../utils/sessionStore";
import actionTypes from "../actionTypes";

const signOutThunk = () => async dispatch => {
  dispatch({ type: actionTypes.SIGN_OUT_REQUEST });
  try {
    await Auth.signOut();
    sessionStore.clearSession();
    apolloClient.resetStore();
    dispatch({
      type: actionTypes.SIGN_OUT_SUCCESS,
    });
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.SIGN_OUT_FAILURE,
      error: error.message,
    });
    throw error;
  }
};

export default signOutThunk;
