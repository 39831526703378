import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import actionCreators from "../../../../actions/actionCreators";
import { IState as IStoreState, selectors } from "../../../../reducers/root";
import { getQueryStringFromSearch } from "../../../../utils/queryStringUtils";
import DatasetsListPageContainer, {
  IDispatchProps,
  IStateProps,
} from "./DatasetsListPageContainer";

interface IOwnProps extends RouteComponentProps<any> {}

const ConnectedDatasetsListPage = connect<IStateProps, IDispatchProps, IOwnProps, IStoreState>(
  (state: IStoreState, ownProps: IOwnProps) => ({
    history: ownProps.history,
    user: selectors.getUser(state),
    queryString: getQueryStringFromSearch(ownProps.location.search),
  }),
  (dispatch: any) => ({
    onDeleteDatasets: async (datasetIds: string[]) => {
      await dispatch(actionCreators.deleteDatasets(datasetIds));
    },
    onSetIsEmployerEditModalVisible: isVisible => {
      dispatch(
        isVisible ? actionCreators.showEmployerEditModal() : actionCreators.hideEmployerEditModal(),
      );
    },
  }),
)(DatasetsListPageContainer);

export default withRouter(ConnectedDatasetsListPage);
