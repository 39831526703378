import * as _ from "lodash";
import * as React from "react";
import { SubmissionError } from "redux-form";
import "../../../../styles/edit-modal.less";
import ProviderAddModal from "./ProviderAddModal";

export interface IStateProps {
  isVisible: boolean;
}

export interface IDispatchProps {
  onCancel: () => void;
  onSave: (values: any) => Promise<void>;
  onClickCreateButton: () => Promise<void>;
  onInit: () => void;
}

interface IProps extends IStateProps, IDispatchProps {}

interface IState {
  isInitializing: boolean;
  initializationError: string | null;
  formData: any;
  isSaving: boolean;
}

const getInitialState = (): IState => {
  return {
    formData: {},
    isSaving: false,
    isInitializing: false,
    initializationError: null,
  };
};

export default class ProviderAddModalContainer extends React.Component<IProps, IState> {
  public state: IState = getInitialState();

  public componentDidMount(): void {
    this.initialize();
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    if (!this.props.isVisible && prevProps.isVisible) {
      this.initialize();
    }
  }

  public render() {
    return (
      <ProviderAddModal
        isVisible={this.props.isVisible}
        onCancel={this.props.onCancel}
        onSave={async values => {
          this.setState({ isSaving: true });
          try {
            await this.props.onSave({
              ...values,
            });
            this.setState({ isSaving: false });
          } catch (error) {
            this.setState({ isSaving: false });
            throw new SubmissionError({
              _error: `Failed to create provider. ${error.message}`,
            });
          }
        }}
        onClickCreateButton={this.props.onClickCreateButton}
        isInitializing={this.state.isInitializing}
        initializationError={this.state.initializationError}
        isSaving={this.state.isSaving}
      />
    );
  }

  private initialize = () => {
    this.setState({ isInitializing: true });
    try {
      this.props.onInit();
    } catch (error) {
      this.setState({ initializationError: error.message });
    }
    this.setState({ isInitializing: false });
  };
}
