import { Layout } from "antd";
import * as React from "react";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import "../../../../styles/sign-in-page.less";
import LegalFooter from "../../common/LegalFooter/LegalFooter";
import SignUpStep from "./steps/SignUpStep/ConnectedSignUpStep";
import VerifyStep from "./steps/VerifyStep/ConnectedVerifyStep";

const { Footer, Content } = Layout;

const SignUpPage = ({ match }) => {
  return (
    <main className="SignUpPage sign-in-page">
      <Layout>
        <Content>
          <Route exact path={match.url} component={SignUpStep} />
          <Route exact path={match.url + "/verify"} component={VerifyStep} />
          <p className="tip">
            Already have an account? <Link to="/sign_in">Sign in</Link>
          </p>
        </Content>
        <Footer style={{ padding: 0 }}>
          <LegalFooter />
        </Footer>
      </Layout>
    </main>
  );
};

export default SignUpPage;
