import { Spin } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Redirect, Route } from "react-router";

import { spinnerDelay } from "../../../../constants/constants";
import SignUpStatus from "../../../../types/enums/SignUpStatus";
import { REACT_APP_IS_TOS_ALWAYS_HIDDEN } from "../../../../utils/config";
import { isDataPortalServiceError, isNetworkError } from "../../../../utils/errorUtils";
import sessionStore from "../../../../utils/sessionStore";
import TermsOfServiceModal from "../TermsOfServiceModal/TermsOfServiceModal";

interface IState {
  isInitializing: boolean;
  shouldRedirectToSignIn: boolean;
  shouldRedirectToFinishSignUp: boolean;
  shouldRedirectToPending: boolean;
  shouldRedirectToRejected: boolean;
  shouldRedirectToUnderMaintenance: boolean;
  isTermsOfServiceModalVisible: boolean;
}

class PrivateRoute extends React.Component<{}, IState> {
  public state: IState = {
    isInitializing: true,
    shouldRedirectToSignIn: false,
    shouldRedirectToFinishSignUp: false,
    shouldRedirectToPending: false,
    shouldRedirectToRejected: false,
    shouldRedirectToUnderMaintenance: false,
    isTermsOfServiceModalVisible: false,
  };

  public componentDidMount() {
    this.initialize();
  }

  public initialize = async () => {
    this.setState({ isInitializing: true });

    if (!(await sessionStore.hasSession())) {
      this.setState({ shouldRedirectToSignIn: true });
    } else {
      try {
        const userStatus = await sessionStore.getUserStatus();
        const signUpStatus = _.get(userStatus, "signUpStatus", undefined);
        switch (signUpStatus) {
          case SignUpStatus.incomplete: {
            this.setState({ shouldRedirectToFinishSignUp: true });
          }
          case SignUpStatus.pending: {
            this.setState({ shouldRedirectToPending: true });
          }
          case SignUpStatus.rejected: {
            this.setState({ shouldRedirectToRejected: true });
          }
          case SignUpStatus.approved: {
            this.setState({
              isTermsOfServiceModalVisible: !_.get(userStatus, "isTermsOfServiceAccepted", false),
            });
          }
        }
      } catch (error) {
        if (isNetworkError(error) || isDataPortalServiceError(error)) {
          this.setState({ shouldRedirectToUnderMaintenance: true });
        }
      }
    }
    this.setState({ isInitializing: false });
  };

  public render() {
    const { component: Component, onAccept, ...rest } = this.props as any;
    const {
      isInitializing,
      shouldRedirectToSignIn,
      shouldRedirectToFinishSignUp,
      shouldRedirectToPending,
      shouldRedirectToRejected,
      shouldRedirectToUnderMaintenance,
      isTermsOfServiceModalVisible,
    } = this.state;
    return (
      <Route
        {...rest}
        render={props => {
          if (isInitializing) {
            return (
              <Spin delay={spinnerDelay} spinning={isInitializing}>
                <div> </div>
              </Spin>
            );
          }

          if (shouldRedirectToSignIn) {
            return (
              <Redirect
                to={{
                  pathname: "/sign_in",
                  state: { from: props.location },
                }}
              />
            );
          }

          if (shouldRedirectToUnderMaintenance) {
            return (
              <Redirect
                to={{
                  pathname: "/under_maintenance",
                  state: { from: props.location },
                }}
              />
            );
          }

          if (shouldRedirectToFinishSignUp) {
            return (
              <Redirect
                to={{
                  pathname: "/sign_up/employer_info",
                  state: { from: props.location },
                }}
              />
            );
          }

          if (shouldRedirectToPending) {
            return (
              <Redirect
                to={{
                  pathname: "/approval_pending",
                  state: { from: props.location },
                }}
              />
            );
          }

          if (shouldRedirectToRejected) {
            return (
              <Redirect
                to={{
                  pathname: "/approval_pending",
                  state: { from: props.location },
                }}
              />
            );
          }
          return (
            <div className="PrivateRoute">
              <Component {...props} />
              <TermsOfServiceModal
                isVisible={!REACT_APP_IS_TOS_ALWAYS_HIDDEN && isTermsOfServiceModalVisible}
                onAccept={async tosVersion => {
                  await onAccept(tosVersion);
                  this.setState({ isTermsOfServiceModalVisible: false });
                }}
              />
            </div>
          );
        }}
      />
    );
  }
}

export default PrivateRoute;
