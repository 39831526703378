import SanitizedStatus from "../enums/SanitizedStatus";

export enum FilterableColumn {
  DECISION_SANITIZED_STATUS = "decision.sanitizedStatus",
}

export const filterableColumns: FilterableColumn[] = [FilterableColumn.DECISION_SANITIZED_STATUS];

export type IFilters = Partial<Record<FilterableColumn, SanitizedStatus[]>>;

export interface IDatasetsLoadingParams {
  filters: IFilters;
}

export const defaultParams: IDatasetsLoadingParams = {
  filters: {},
};
