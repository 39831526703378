import { History } from "history";
import * as _ from "lodash";
import { parse } from "qs";

export const delay = ms =>
  new Promise(resolve => {
    setTimeout(resolve, ms);
  });

export const getQueryString = (history: History, key: string): string | null => {
  const encoded = _.get(parse(_.trimStart(history.location.search, "?")), key, null);
  return encoded ? decodeURIComponent(encoded) : null;
};
