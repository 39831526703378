import acceptTermsOfService from "./mutation/acceptTermsOfService";
import createDataset from "./mutation/createDataset";
import createEmployerCandidate from "./mutation/createEmployerCandidate";
import createProvider from "./mutation/createProvider";
import deleteDatasets from "./mutation/deleteDatasets";
import deleteProviderAndDatasets from "./mutation/deleteProviderAndDatasets";
import deleteProviders from "./mutation/deleteProviders";
import updateDataset from "./mutation/updateDataset";
import updateEmployer from "./mutation/updateEmployer";
import updateProvider from "./mutation/updateProvider";
import updateUser from "./mutation/updateUser";
import getDataset from "./query/getDataset";
import getDatasets from "./query/getDatasets";
import getDatasetsStats from "./query/getDatasetsStats";
import getDatasetsWithParams from "./query/getDatasetsWithParams";
import getEmployer from "./query/getEmployer";
import getProvider from "./query/getProvider";
import getProviders from "./query/getProviders";
import getTaxonomy from "./query/getTaxonomy";
import getUser from "./query/getUser";
import getUserStatus from "./query/getUserStatus";

const graphqlApi = {
  acceptTermsOfService,
  createDataset,
  createEmployerCandidate,
  createProvider,
  deleteDatasets,
  deleteProviderAndDatasets,
  deleteProviders,
  getDataset,
  getDatasets,
  getDatasetsWithParams,
  getDatasetsStats,
  getEmployer,
  getUserStatus,
  getProvider,
  getProviders,
  getTaxonomy,
  getUser,
  updateDataset,
  updateEmployer,
  updateProvider,
  updateUser,
};

export default graphqlApi;
