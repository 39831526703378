import { Slider } from "antd";
import * as _ from "lodash";
import * as React from "react";
import "./OrdinalSlider.less";

const OrdinalSlider = ({ marksArray, value, onChange, paddingLeft, paddingRight, ...rest }) => {
  const marks = {};
  marksArray.forEach(({ id, mark }, i) => {
    marks[i] = mark;
  });

  const min = -1 * (marksArray.length - 1) * paddingLeft;
  const max = marksArray.length - 1 + (marksArray.length - 1) * paddingRight;
  const hasValue = value !== "";
  return (
    <div className="OrdinalSlider">
      <div className={hasValue ? "OrdinalSlider-has-value" : "OrdinalSlider-no-value"}>
        <Slider
          marks={marks}
          value={hasValue ? _.findIndex(marksArray, { id: value }) : undefined}
          min={min}
          max={max}
          tipFormatter={index => {
            if (!_.isInteger(index) || index < 0 || index >= marksArray.length) {
              return null;
            }
            return marksArray[index as number].mark;
          }}
          onChange={index => {
            if (!_.isInteger(index) || index < 0 || index >= marksArray.length) {
              return;
            }
            onChange(marksArray[index as number].id);
          }}
          {...rest}
        />
      </div>
    </div>
  );
};

export default OrdinalSlider;
