import { IDatasetInput } from "../../types/interfaces/IDatasetInput";
import graphqlApi from "../../utils/graphqlApi/graphqlApi";
import actionTypes from "../actionTypes";

const updateDatasetThunk = (datasetId: string, datasetInput: IDatasetInput) => async dispatch => {
  dispatch({ type: actionTypes.UPDATE_DATASET_REQUEST });
  try {
    const dataset = await graphqlApi.updateDataset(datasetId, datasetInput);
    dispatch({
      type: actionTypes.UPDATE_DATASET_SUCCESS,
      dataset,
    });
    return dataset;
  } catch (error) {
    console.error(error); // tslint:disable-line no-console
    dispatch({
      type: actionTypes.UPDATE_DATASET_FAILURE,
      error: error.message,
    });
    throw error;
  }
};

export default updateDatasetThunk;
